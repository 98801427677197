import { InAppNotification } from '../../model/in-app-notification';
import { UnsafeAction } from '../../model/unsafe-action';
import * as iassign from 'immutable-assign';
import { NotificationActionTypes } from './notification-actions';

export interface NotificationState {
  notificationToDisplay: InAppNotification;
  notifications: InAppNotification[];
}

export const INITIAL_NOTIFICATION_STATE: NotificationState = {
  notificationToDisplay: undefined,
  notifications: []
};

export function notificationStateReducer(
  state: NotificationState = INITIAL_NOTIFICATION_STATE,
  { type, payload }: UnsafeAction
): NotificationState {
  switch (type) {
    case NotificationActionTypes.LOAD_NOTIFICATIONS_IN_LOCAL_STORAGE_COMPLETE:
      return iassign(state, (s) => {
        s.notifications = payload;
        return s;
      });
    case NotificationActionTypes.DISPLAY_NOTIFICATION:
      return iassign(state, (s) => {
        s.notificationToDisplay = payload;
        return s;
      });
    case NotificationActionTypes.DISMISS_NOTIFICATION:
      return iassign(state, (s) => {
        s.notificationToDisplay = undefined;
        return s;
      });
    default:
      return state;
  }
}
