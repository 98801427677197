import { ProblemSetSetting } from '../../problem-set/model/problem-set-setting';
import * as iassign from 'immutable-assign';
import { UnsafeAction } from '../../model/unsafe-action';
import { ProblemSetSettingActionTypes } from './problem-set-setting-actions';

export interface ProblemSetSettingState {
  problemSetSetting: ProblemSetSetting;
  problemSetSettings: ProblemSetSetting[];
}

export const INITIAL_PROBLEM_SET_SETTING_STATE: ProblemSetSettingState = {
  problemSetSetting: undefined,
  problemSetSettings: []
};

export function problemSetSettingsStateReducer(
  state: ProblemSetSettingState = INITIAL_PROBLEM_SET_SETTING_STATE,
  { type, payload }: UnsafeAction
): ProblemSetSettingState {
  switch (type) {
    case ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTING:
      return iassign(state, (s) => {
        s.problemSetSetting = undefined;
        return s;
      });
    case ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTING_COMPLETE:
      return iassign(state, (s) => {
        s.problemSetSetting = payload;
        return s;
      });
    case ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTINGS_COMPLETE:
      return iassign(state, (s) => {
        s.problemSetSettings = payload;
        return s;
      });
    default:
      return state;
  }
}
