import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { UserStorageService } from '../core/user-storage.service';
import { ChangeUiLanguagePreference } from '../core/user/user-preference-actions';
import { UnsafeAction } from '../model/unsafe-action';
import { AppState } from '../states/states-reducers';
import { changeUILanguage, i18nActionTypes } from './i18n-actions';
import {
    convertBrowserTagToIanaCode,
    mapIanaCodeToUILanguage
} from './i18n-utility';
import { I18nService } from './i18n.service';
import { UILanguage } from './ui-language';

@Injectable()
export class I18nEffects {
  constructor(
    private translate: TranslateService,
    private actions$: Actions,
    private store: Store<AppState>,
    private i18n: I18nService,
    private storageService: UserStorageService
  ) {}

  @Effect()
  changeUILanguage = this.actions$.pipe(
    ofType(i18nActionTypes.CHANGE_UI_LANGUAGE),
    map((action: UnsafeAction) => {
      const uiLanguage: UILanguage = action.payload;
      this.storageService.putIanaCode(uiLanguage.ianaCode);
      this.i18n.use(uiLanguage.ianaCode);
      return ChangeUiLanguagePreference(uiLanguage);
    })
  );

  @Effect({ dispatch: false })
  getInitialUICode = this.actions$.pipe(
    ofType(i18nActionTypes.GET_INITIAL_UI_LANGUAGE),
    map((_) => {
      this.storageService.getIanaCode().then((cookieTag) => {
        let iana: string = null;
        if (cookieTag) {
          iana = convertBrowserTagToIanaCode(cookieTag);
        } else {
          const browserTag = this.translate.getBrowserCultureLang();
          if (browserTag) {
            iana = convertBrowserTagToIanaCode(browserTag);
          }
        }
        if (iana) {
          this.store.dispatch(changeUILanguage(mapIanaCodeToUILanguage(iana)));
        }
      });
    })
  );
}
