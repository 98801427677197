import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { Subscription } from './subscription';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  constructor(
    private httpService: HttpClientService,
    private config: Configuration
  ) {}

  getPersonalSubscriptions(): Observable<Subscription[]> {
    const url = this.config.apiRootUrl + '/subscription/personalSubscriptions';
    return this.httpService.authGet<Subscription[]>(url);
  }

  getSchoolSubscriptions(schoolId: string): Observable<Subscription[]> {
    const url = `${this.config.apiRootUrl}/subscription/schoolSubscriptions/${schoolId}`;
    return this.httpService.authGet<Subscription[]>(url);
  }
}
