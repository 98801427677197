import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { EdbSchool } from './edb-school';

@Injectable({ providedIn: 'root' })
export class EdbSchoolService {
  urlRoot: string;
  constructor(config: Configuration, private http: HttpClientService) {
    this.urlRoot = config.erpRootUrl + '/edbSchools';
  }

  getEdbSchool({ edbUrl }: any): Observable<EdbSchool> {
    const base64String = btoa(edbUrl);
    const url = `${this.urlRoot}/${base64String}`;
    return this.http.authGet<EdbSchool>(url).pipe(
      map((school) => {
        school.date = new Date(school.date);
        return school;
      })
    );
  }
}
