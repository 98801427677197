import { Component, Input } from '@angular/core';
import { Contact } from '../erp/contact';
import { JobTitle } from '../erp/job-title';
import { ResponsibilityArea } from '../erp/responsibility-area';

@Component({
  selector: 'app-contact-display',
  templateUrl: './contact-display.component.html'
})
export class ContactDisplayComponent {
  @Input() contact: Contact;

  get jobTitle(): string {
    return JobTitle[this.contact.jobTitle];
  }

  get responsibilityArea(): string {
    return ResponsibilityArea[this.contact.responsibilityArea];
  }
}
