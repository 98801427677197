import { Component, Input } from '@angular/core';
import { ErpSchool } from '../erp/erp-school';
import { createSchoolCode } from '../erp/erp-utility';

@Component({
  selector: 'app-erp-school-display',
  templateUrl: 'erp-school-display.component.html'
})
export class ErpSchoolDisplayComponent {
  @Input() erpSchool: ErpSchool;

  get schoolCode(): string {
    return createSchoolCode(this.erpSchool);
  }
}
