<div class="col-12">
  <table width="100%">
    <tr *ngFor="let field of fields">
      <td width="1%">{{ field.fieldname }}</td>
      <td>
        <textarea [autoResize]="true"
          pInputTextarea
          [(ngModel)]="field.fieldvalue"
          (blur)="onFieldChange()"
          [autoResize]="true"
        ></textarea>
      </td>
    </tr>
  </table>
</div>
