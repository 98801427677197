<div class="col-12">
  <h2 class="page-subtitle">EDB School</h2>
</div>

<div class="col-12">
  <app-fields-display
    [object]="edbSchool"
    (fieldClick)="onFieldClick($event)"
  ></app-fields-display>
</div>
