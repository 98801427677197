import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../configuration';
import { Mentee } from '../../model/mentee';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class MenteeService {
  constructor(
    private config: Configuration,
    private httpService: HttpClientService
  ) {}

  getMenteesOfCurrentUser(): Observable<Mentee[]> {
    const url = this.config.apiRootUrl + '/school/mentees';
    return this.httpService.authGet<Mentee[]>(url);
  }
}
