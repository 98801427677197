import { Injectable } from '@angular/core';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { Observable } from 'rxjs';
import { Membership } from './membership';

@Injectable({ providedIn: 'root' })
export class MembershipService {
  constructor(
    private httpService: HttpClientService,
    private config: Configuration
  ) {}

  getMembership(): Observable<Membership> {
    const url = this.config.apiRootUrl + '/subscription/memberships/self';
    return this.httpService.authGet<Membership>(url);
  }
}
