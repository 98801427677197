<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-left">
        <p>{{ message.summary }}</p>
        <p>{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
<router-outlet></router-outlet>
