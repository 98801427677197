import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore
} from 'oidc-client';
import { environment } from '../environments/environment';

export const userStorage = new WebStorageStateStore({
  store: window.localStorage
});
export const domain = window.location.protocol + '//' + window.location.host;

export const userManagerSettings: UserManagerSettings = {
  client_id: 'spa-code',
  scope: 'openid profile api',
  response_type: 'code',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  silentRequestTimeout: 30 * 1000,
  accessTokenExpiringNotificationTime: 60,
  checkSessionInterval: 10 * 1000,
  redirect_uri: `${domain}/signin`,
  post_logout_redirect_uri: `${domain}/signout`,
  silent_redirect_uri: `${domain}/signinSilent.html`,
  authority: environment.stsRootUrl,
  userStore: userStorage,
  stateStore: userStorage,
  clockSkew: 300
};

export function userManagerFactory(): UserManager {
  return new UserManager(userManagerSettings);
}
