export interface IStudentParticipation {
  groupName : string;
  userCount: number;
  days:  number;
  participationRate: number;
  totalScore: number;
  scoreReachingRegularSchoolDaysInAYear: number;
}

export class StudentParticipation implements IStudentParticipation {
  constructor(value: IStudentParticipation){
    this.groupName = value.groupName;
    this.userCount = value.userCount;
    this.days = value.days;
    this.participationRate = value.participationRate;
    this.totalScore = value.totalScore;
    this.scoreReachingRegularSchoolDaysInAYear = value.scoreReachingRegularSchoolDaysInAYear;
  }

  groupName : string;
  userCount: number;
  days:  number;
  participationRate: number;
  totalScore: number;
  scoreReachingRegularSchoolDaysInAYear: number;
  get averageScore() : number {
    return this.userCount > 0 ? this.totalScore / this.userCount  : 0;
  }
}