import * as iassign from 'immutable-assign';
import { UnsafeAction } from '../../model/unsafe-action';
import { getUserAgentInfo } from '../../utility/window-utility';
import { UiActionTypes } from './ui-actions';

export interface UiState {
  showTopBar: boolean;
  userAgentInfo: any;
  showSpinner: boolean;
  pushSubscription: PushSubscription;
  hasRequestSubscriptionError: boolean;
  colorTheme: string;
  showLocalStorageWarning: boolean;
  spinnerIdentifier: string;
}

export const INITIAL_UI_STATE: UiState = {
  showTopBar: true,
  userAgentInfo: getUserAgentInfo(),
  showSpinner: false,
  spinnerIdentifier: undefined,
  pushSubscription: undefined,
  hasRequestSubscriptionError: false,
  colorTheme: 'light',
  showLocalStorageWarning: false
};

export function uiStateReducer(
  state: UiState = INITIAL_UI_STATE,
  { type, payload }: UnsafeAction
): UiState {
  switch (type) {
    case UiActionTypes.SHOW_LOCALSTORAGE_WARNING:
      return iassign(state, (s) => {
        s.showLocalStorageWarning = true;
        return s;
      });
    case UiActionTypes.HIDE_LOCALSTORAGE_WARNING:
      return iassign(state, (s) => {
        s.showLocalStorageWarning = false;
        return s;
      });
    case UiActionTypes.FLAG_REQUEST_SUBSCRIPTION_ERROR:
      return iassign(state, (s) => {
        s.hasRequestSubscriptionError = true;
        return s;
      });
    case UiActionTypes.UPDATE_PUSH_SUBSCRIPTION:
      return iassign(state, (s) => {
        s.pushSubscription = payload;
        return s;
      });
    case UiActionTypes.SHOW_SPINNER:
      return iassign(state, (s) => {
        s.spinnerIdentifier = payload;
        s.showSpinner = true;
        return s;
      });
    case UiActionTypes.HIDE_SPINNER:
      return iassign(state, (s) => {
        if (s.spinnerIdentifier === payload) {
          s.showSpinner = false;
          s.spinnerIdentifier = undefined;
        }
        return s;
      });
    case UiActionTypes.SHOW_TOPBAR:
      return iassign(state, (s) => {
        s.showTopBar = true;
        return s;
      });
    case UiActionTypes.HIDE_TOPBAR:
      return iassign(state, (s) => {
        s.showTopBar = false;
        return s;
      });
    default:
      return state;
  }
}
