import { NgModule } from '@angular/core';
// primeng
import { AccordionModule } from 'primeng/accordion';
// import { TreeModule } from 'primeng/tree/tree';
// import { TreeTableModule } from 'primeng/treetable/treetable';
import { AutoCompleteModule } from 'primeng/autocomplete';
// import { BreadcrumbModule } from 'primeng/breadcrumb/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
// import { CaptchaModule } from 'primeng/captcha/captcha';
import { CardModule } from 'primeng/card';
// import { CarouselModule } from 'primeng/carousel/carousel';
//import { ChartModule } from 'primeng/chart/chart';
import { CheckboxModule } from 'primeng/checkbox';
// import { ChipsModule } from 'primeng/chips/chips';
// import { CodeHighlighterModule } from 'primeng/codehighlighter/codehighlighter';
// import { ColorPickerModule } from 'primeng/colorpicker/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
// import { SharedModule } from 'primeng/common/shared';
// import { ContextMenuModule } from 'primeng/contextmenu/contextmenu';
// import { DataGridModule } from 'primeng/datagrid/datagrid';
// import { DataListModule } from 'primeng/datalist/datalist';
// import { DataScrollerModule } from 'primeng/datascroller/datascroller';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
// import { DragDropModule } from 'primeng/dragdrop/dragdrop';
// import { DynamicDialogModule } from 'primeng/dynamicdialog';
// import { EditorModule } from 'primeng/editor/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
// import { InplaceModule } from 'primeng/inplace/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
// import { InputSwitchModule } from 'primeng/inputswitch/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
// import { ListboxModule } from 'primeng/listbox/listbox';
// import { MegaMenuModule } from 'primeng/megamenu/megamenu';
// import { MenuModule } from 'primeng/menu/menu';
// import { MenubarModule } from 'primeng/menubar/menubar';
// import { MessagesModule } from 'primeng/messages/messages';
// import { MultiSelectModule } from 'primeng/multiselect/multiselect';
// import { OrderListModule } from 'primeng/orderlist/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
// import { PaginatorModule } from 'primeng/paginator/paginator';
import { PanelModule } from 'primeng/panel';
// import { PanelMenuModule } from 'primeng/panelmenu/panelmenu';
// import { PasswordModule } from 'primeng/password/password';
// import { PickListModule } from 'primeng/picklist/picklist';
// import { ProgressBarModule } from 'primeng/progressbar/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
// import { RatingModule } from 'primeng/rating/rating';
// import { ScheduleModule } from 'primeng/schedule/schedule';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
// import { SlideMenuModule } from 'primeng/slidemenu/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
// import { SplitButtonModule } from 'primeng/splitbutton/splitbutton';
// import { StepsModule } from 'primeng/steps/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
// import { GMapModule } from 'primeng/gmap/gmap';
import { ToastModule } from 'primeng/toast';
// import { TerminalModule } from 'primeng/terminal/terminal';
// import { TieredMenuModule } from 'primeng/tieredmenu/tieredmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
// import { ToolbarModule } from 'primeng/toolbar/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

// import { AvatarModule } from "primeng/avatar";
// import { AvatarGroupModule } from "primeng/avatargroup";
// import { BadgeModule } from "primeng/badge";
// import { BreadcrumbModule } from "primeng/breadcrumb";
// import { CarouselModule } from "primeng/carousel";
// import { CascadeSelectModule } from "primeng/cascadeselect";
// import { ChartModule } from "primeng/chart";
// import { ChipModule } from "primeng/chip";
// import { ChipsModule } from "primeng/chips";
// import { CodeHighlighterModule } from "primeng/codehighlighter";
// import { ConfirmPopupModule } from "primeng/confirmpopup";
// import { ColorPickerModule } from "primeng/colorpicker";
// import { ContextMenuModule } from "primeng/contextmenu";
// import { DataViewModule } from "primeng/dataview";
// import { DividerModule } from "primeng/divider";
// import { FullCalendarModule } from "primeng/fullcalendar";
// import { InplaceModule } from "primeng/inplace";
// import { InputNumberModule } from "primeng/inputnumber";
// import { InputSwitchModule } from "primeng/inputswitch";
// import { KnobModule } from "primeng/knob";
// import { ListboxModule } from "primeng/listbox";
// import { MegaMenuModule } from "primeng/megamenu";
// import { MenuModule } from "primeng/menu";
// import { MenubarModule } from "primeng/menubar";
// import { MessagesModule } from "primeng/messages";
// import { MessageModule } from "primeng/message";
// import { MultiSelectModule } from "primeng/multiselect";
// import { OrderListModule } from "primeng/orderlist";
// import { OrganizationChartModule } from "primeng/organizationchart";
// import { PaginatorModule } from "primeng/paginator";
// import { PanelMenuModule } from "primeng/panelmenu";
// import { PasswordModule } from "primeng/password";
// import { PickListModule } from "primeng/picklist";
// import { ProgressBarModule } from "primeng/progressbar";
// import { RatingModule } from "primeng/rating";
// import { RippleModule } from "primeng/ripple";
// import { ScrollTopModule } from "primeng/scrolltop";
// import { SidebarModule } from "primeng/sidebar";
// import { SkeletonModule } from "primeng/skeleton";
// import { SlideMenuModule } from "primeng/slidemenu";
// import { SplitButtonModule } from "primeng/splitbutton";
// import { SplitterModule } from "primeng/splitter";
// import { StepsModule } from "primeng/steps";
// import { TagModule } from "primeng/tag";
// import { TerminalModule } from "primeng/terminal";
// import { TieredMenuModule } from "primeng/tieredmenu";
// import { TimelineModule } from "primeng/timeline";
// import { ToolbarModule } from "primeng/toolbar";
// import { TreeModule } from "primeng/tree";
// import { TreeTableModule } from "primeng/treetable";
// import { VirtualScrollerModule } from "primeng/virtualscroller";

@NgModule({
  imports: [
    //primeng
    AccordionModule,
    AutoCompleteModule,
    // BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    //CaptchaModule,
    CardModule,
    //CarouselModule,
    //ChartModule,
    CheckboxModule,
    // ChipsModule,
    // CodeHighlighterModule,
    ConfirmDialogModule,
    // SharedModule,
    // ContextMenuModule,
    // DataGridModule,
    // DataListModule,
    // DataScrollerModule,
    DialogModule,
    // DragDropModule,
    DropdownModule,
    //DynamicDialogModule,
    // EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    // GMapModule,
    ToastModule,
    InputMaskModule,
    InputNumberModule,
    // InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    // ListboxModule,
    // MegaMenuModule,
    // MenuModule,
    // MenubarModule,
    // MessagesModule,
    // MultiSelectModule,
    // OrderListModule,
    OverlayPanelModule,
    // PaginatorModule,
    PanelModule,
    // PanelMenuModule,
    // PasswordModule,
    // PickListModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    // RatingModule,
    // ScheduleModule,
    ScrollPanelModule,
    SelectButtonModule,
    // SlideMenuModule,
    SliderModule,
    SpinnerModule,
    // SplitButtonModule,
    // StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    // TerminalModule,
    // TieredMenuModule,
    ToggleButtonModule,
    // ToolbarModule,
    TooltipModule,
    // TreeModule,
    // TreeTableModule,
    TriStateCheckboxModule

    // AvatarModule,
    // AvatarGroupModule,
    // BadgeModule,
    // BreadcrumbModule,
    // CarouselModule,
    // CascadeSelectModule,
    // ChartModule,
    // ChipModule,
    // ChipsModule,
    // CodeHighlighterModule,
    // ConfirmPopupModule,
    // ColorPickerModule,
    // ContextMenuModule,
    // DataViewModule,
    // DividerModule,
    // FullCalendarModule,
    // InplaceModule,
    // InputNumberModule,
    // InputSwitchModule,
    // KnobModule,
    // ListboxModule,
    // MegaMenuModule,
    // MenuModule,
    // MenubarModule,
    // MessageModule,
    // MessagesModule,
    // MultiSelectModule,
    // OrderListModule,
    // OrganizationChartModule,
    // PaginatorModule,
    // PanelMenuModule,
    // PasswordModule,
    // PickListModule,
    // ProgressBarModule,
    // RatingModule,
    // RippleModule,
    // ScrollTopModule,
    // SidebarModule,
    // SkeletonModule,
    // SlideMenuModule,
    // SplitButtonModule,
    // SplitterModule,
    // StepsModule,
    // TagModule,
    // TerminalModule,
    // TimelineModule,
    // TieredMenuModule,
    // ToolbarModule,
    // TreeModule,
    // TreeTableModule,
    // VirtualScrollerModule,
  ],
  exports: [
    // primeng
    AccordionModule,
    AutoCompleteModule,
    // BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    // CaptchaModule,
    CardModule,
    // CarouselModule,
    // ChartModule,
    CheckboxModule,
    // ChipsModule,
    // CodeHighlighterModule,
    ConfirmDialogModule,
    // SharedModule,
    // ContextMenuModule,
    // DataGridModule,
    // DataListModule,
    // DataScrollerModule,
    DialogModule,
    // DragDropModule,
    DropdownModule,
    //DynamicDialogModule,
    // EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    // GMapModule,
    ToastModule,
    InputMaskModule,
    // InputSwitchModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    LightboxModule,
    // ListboxModule,
    // MegaMenuModule,
    // MenuModule,
    // MenubarModule,
    // MessagesModule,
    // MultiSelectModule,
    // OrderListModule,
    OverlayPanelModule,
    // PaginatorModule,
    PanelModule,
    // PanelMenuModule,
    // PasswordModule,
    // PickListModule,
    // ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    // RatingModule,
    // ScheduleModule,
    ScrollPanelModule,
    SelectButtonModule,
    // SlideMenuModule,
    SliderModule,
    SpinnerModule,
    // SplitButtonModule,
    // StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    // TerminalModule,
    // TieredMenuModule,
    ToggleButtonModule,
    // ToolbarModule,
    TooltipModule,
    // TreeModule,
    // TreeTableModule,
    TriStateCheckboxModule

    // AvatarModule,
    // AvatarGroupModule,
    // BadgeModule,
    // BreadcrumbModule,
    // CarouselModule,
    // CascadeSelectModule,
    // ChartModule,
    // ChipModule,
    // ChipsModule,
    // CodeHighlighterModule,
    // ConfirmPopupModule,
    // ColorPickerModule,
    // ContextMenuModule,
    // DataViewModule,
    // DividerModule,
    // FullCalendarModule,
    // InplaceModule,
    // InputNumberModule,
    // InputSwitchModule,
    // KnobModule,
    // ListboxModule,
    // MegaMenuModule,
    // MenuModule,
    // MenubarModule,
    // MessageModule,
    // MessagesModule,
    // MultiSelectModule,
    // OrderListModule,
    // OrganizationChartModule,
    // PaginatorModule,
    // PanelMenuModule,
    // PasswordModule,
    // PickListModule,
    // ProgressBarModule,
    // RatingModule,
    // RippleModule,
    // ScrollTopModule,
    // SidebarModule,
    // SkeletonModule,
    // SlideMenuModule,
    // SplitButtonModule,
    // SplitterModule,
    // StepsModule,
    // TagModule,
    // TerminalModule,
    // TimelineModule,
    // TieredMenuModule,
    // ToolbarModule,
    // TreeModule,
    // TreeTableModule,
    // VirtualScrollerModule,
  ]
})
export class PrimeNgModule {}
