import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';
import { accountMenu } from '../account-ui/account-menu';
import { AppComponent } from '../app.component';
import { contactMenu } from '../contact/contact.menu';
import { UserAuthenticationService } from '../core/user-authentication.service';
import { initSignout } from '../core/user/user-actions';
import {
  isImageAdminSelector,
  isSysAdminSelector
} from '../core/user/user-state-selectors';
import { faqMenu } from '../faq-ui/faq.menu';
import { homeMenu } from '../home-ui/home-menu';
import { onBoardingMMenu as onBoardingMenu } from '../home-ui/onboarding.menu';
import { imageMenu } from '../image-ui/image-menu';
import { syllabusMenu } from '../problem-set-ui/syllabus/syllabus-menu';
import { schoolFaqMenu } from '../school-faq-ui/school-faq-menu';
import { menteeMenu } from '../school-ui/mentee-menu';
import { schoolAdminMenu } from '../school-ui/school-admin-menu';
import { signUpMenu } from '../sign-up-ui/sign-up-menu';
import { AppState } from '../states/states-reducers';
import { supportMenu } from '../support-ui/support-menu';
import { systemMenu } from '../system-ui/system-menu';
import { visionMenu } from '../vision-ui/vision.menu';
import { wordFrequencyMenu } from '../word-frequency-ui/word-frequency-menu';

const loginMenu: MenuItem = {
  label: 'account.log-in',
  icon: 'pi pi-power-off',
  routerLink: ['/home/login']
};

@Component({
  selector: 'app-menu',
  template: `
    <ul class="layout-menu">
      <li
        app-menuitem
        *ngFor="let item of model; let i = index"
        [item]="item"
        [index]="i"
        [root]="true"
      ></li>
    </ul>
  `
})
export class AppMenuComponent implements OnInit, OnDestroy {
  model: any[] = [];
  private subs = new SubSink();
  @Input() reset: boolean;

  constructor(
    public app: AppComponent,
    private store: Store<AppState>,
    private userAuthenticationService: UserAuthenticationService
  ) {}

  ngOnInit() {
    this.subs.sink = this.store.subscribe((state) => this.displayMenu(state));
  }

  displayMenu(state: AppState) {
    const items: MenuItem[] = this.buildMenuItems(state);
    if (!this.userAuthenticationService.user) {
      this.model = [
        {
          items: [
            loginMenu,
            homeMenu,
            ...items.map(this.extractTitle),
            signUpMenu,
            supportMenu,
            visionMenu,
            faqMenu,
            schoolFaqMenu,
            wordFrequencyMenu,
            onBoardingMenu,
            contactMenu
          ]
        }
      ];
    } else {
      this.model = [
        {
          items: [
            this.getUsernameMenu(),
            homeMenu,
            ...items,
            accountMenu,
            supportMenu,
            visionMenu,
            faqMenu,
            schoolFaqMenu,
            wordFrequencyMenu,
            onBoardingMenu,
            //revisionMenu,
            contactMenu,
          ]
        }
      ];
    }
  }

  buildMenuItems(state: AppState): MenuItem[] {
    const isSysAdmin = isSysAdminSelector(state);
    const isImageAdmin = isImageAdminSelector(state);
    const items: MenuItem[] = [];

    if (isSysAdmin || state.user.syllabusAdminRegistrations.length > 0) {
      items.push(syllabusMenu);
    }

    if (
      isSysAdmin ||
      state.user.schoolAdminRegistrationsOfCurrentUser.length > 0
    ) {
      items.push(schoolAdminMenu);
    }

    if (isSysAdmin || state.mentee.mentees.length > 0) {
      items.push(menteeMenu);
    }

    if (isSysAdmin || isImageAdmin) {
      items.push(imageMenu);
    }

    if (isSysAdmin) {
      items.push(systemMenu);
    }

    return items;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  extractTitle(menu: MenuItem): MenuItem {
    return {
      label: menu.label,
      icon: menu.icon,
      routerLink: menu.routerLink
    };
  }

  getUsernameMenu(): MenuItem {
    return {
      label: this.userAuthenticationService.userName,
      icon: 'pi pi-power-off',
      command: (_) => {
        this.store.dispatch(initSignout());
      }
    };
  }
}
