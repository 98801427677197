import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { tap } from 'rxjs/operators';
import { NavigationService } from './navigation.service';

@Injectable()
export class NavigationEffects {
  constructor(
    private actions: Actions,
    private navigation: NavigationService
  ) {}

  @Effect({ dispatch: false })
  navigated = this.actions.pipe(
    ofType(ROUTER_NAVIGATED),
    tap((action: any) =>
      this.navigation.onNavigated(action.payload.routerState.url)
    )
  );
}
