import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { MembershipPurchaseOption } from './membership-purchase-option';

@Injectable({ providedIn: 'root' })
export class MembershipPurchaseService {
  urlRoot: string;
  constructor(private http: HttpClientService, config: Configuration) {
    this.urlRoot = config.apiRootUrl + '/payment/membershipPurchaseOptions';
  }

  getMembershipPurchaseOptions(): Observable<MembershipPurchaseOption[]> {
    return this.http
      .authGet<MembershipPurchaseOption[]>(this.urlRoot)
      .pipe(catchError((_) => of([])));
  }

  getMembershipPurchaseOption(
    id: string
  ): Observable<MembershipPurchaseOption> {
    return this.http
      .authGet<MembershipPurchaseOption>(this.urlRoot + '/' + id)
      .pipe(catchError((_) => of(null)));
  }
}
