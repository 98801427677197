import { ExerciseRecord } from '../model/exercise-record';
import { TestRecord } from '../model/test-record';
import { UnsafeAction } from '../model/unsafe-action';
import { type } from '../utility/type';
import { ExerciseReportSubscription } from './exercise-report-subscription';
import { PhraseAttempted } from './phase-attempted';
import { VocabularyReportsParameter } from './vocabulary-reports-parameter';

export const ReportingActionTypes = {
  CREATE_EXERCISE_RECORD_REPORT_SUBSCRIPTION: type(
    '[Reporting] Create Exercise Report Subscription'
  ),
  CREATE_EXERCISE_RECORD_REPORT_SUBSCRIPTION_COMPLETE: type(
    '[Reporting] Create Exercise Report Subscription Complete'
  ),

  GET_VOCABULARY_ATTEMPTED_BY_USER: type(
    '[Reporting] Get Vocabulary Attempted by User'
  ),
  GET_VOCABULARY_ATTEMPTED_BY_USER_COMPLETE: type(
    '[Reporting] Get Vocabulary Attempted by User Complete'
  ),

  CLEAR_EXERCISE_RECORDS: type('[Reporting] Clear Exercise Records'),
  GET_EXERCISE_RECORDS: type('[Reporting] Get Exercise Records'),
  GET_EXERCISE_RECORDS_BY_EMAIL: type(
    '[Reporting] Get Exercise Records By Email'
  ),
  GET_EXERCISE_RECORDS_BY_SUBJECT: type(
    '[Reporting] Get Exercise Records By Subject'
  ),
  GET_EXERCISE_RECORDS_COMPLETE: type(
    '[Reporting] Get Exercise Records Complete'
  ),

  GET_USERS_EXERCISE_RECORDS: type('[Reporting] Get Users Exercise Records'),
  GET_USERS_EXERCISE_RECORDS_COMPLETE: type(
    '[Reporting] Get Users Exercise Records Complete'
  ),

  DELETE_EXERCISE_REPORT_SUBSCRIPTION: type(
    '[Reporting] Delete Exercise Report Subscription'
  ),
  DELETE_EXERCISE_REPORT_SUBSCRIPTION_COMPLETE: type(
    '[Reporting] Delete Exercise Report Subscription Complete'
  ),

  DELETE_WORD_FOR_USER: type('[Reporting] Delete Word for User'),
  DELETE_WORD_FOR_USER_COMPLETE: type(
    '[Reporting] Delete Word For User Complete'
  ),

  GET_EXERCISE_REPORT_SUBSCRIPTIONS_OF_CURRENT_USER: type(
    '[Reporting] Get Exercise Report Subscriptions of Current User'
  ),

  GET_EXERCISE_REPORT_SUBSCRIPTIONS_OF_SCHOOL: type(
    '[Reporting] Get Exercise Report Subscription of School'
  ),

  GET_EXERCISE_RECORD_REPORT_SUBSCRIPTIONS_COMPLETE: type(
    '[Reporting] Get Exercise Report Subscription Complete'
  ),

  CLEAR_TEST_RECORDS: type('[Reporting] Clear Test Records'),
  GET_TEST_RECORDS: type('[Reporting] Get Test Records'),
  GET_TEST_RECORDS_COMPLETE: type('[Reporting] Get Test Records Complete'),

  GET_USERS_TEST_RECORDS: type('[Reporting] Get Users Test Records'),
  GET_USERS_TEST_RECORDS_COMPLETE: type(
    '[Reporting] Get Users Test Records Complete'
  ),

  INSERT_EXERCISE_RECORD: type('[Reporting] Insert Exercise Record'),
  INSERT_EXERCISE_RECORD_COMPLETE: type(
    '[Reporting] Insert Exercise Record Complete'
  ),

  EMAIL_VOCABULARY_REPORTS: type('[Reporting] Email Vocabulary Reports')
};

export function emailVocabularyReports(
  payload: VocabularyReportsParameter
): UnsafeAction {
  return {
    type: ReportingActionTypes.EMAIL_VOCABULARY_REPORTS,
    payload
  };
}

export function deleteWordByUser(
  subject: string,
  schoolId: string,
  syllabusId: string,
  phrase: string
) {
  return {
    type: ReportingActionTypes.DELETE_WORD_FOR_USER,
    payload: { subject, schoolId, syllabusId, phrase }
  };
}

export function deleteWordByUserComplete(phrase: string) {
  return {
    type: ReportingActionTypes.DELETE_WORD_FOR_USER_COMPLETE,
    payload: { phrase }
  };
}

export function insertExerciseRecord(record: ExerciseRecord): UnsafeAction {
  return {
    type: ReportingActionTypes.INSERT_EXERCISE_RECORD,
    payload: record
  };
}

export function insertExerciseRecordComplete(
  record: ExerciseRecord
): UnsafeAction {
  return {
    type: ReportingActionTypes.INSERT_EXERCISE_RECORD_COMPLETE,
    payload: record
  };
}

export function clearTestRecords(): UnsafeAction {
  return {
    type: ReportingActionTypes.CLEAR_TEST_RECORDS
  };
}

export function getTestRecords(syllabusId: string): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_TEST_RECORDS,
    payload: { syllabusId }
  };
}

export function getTestRecordsComplete(records: TestRecord[]): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_TEST_RECORDS_COMPLETE,
    payload: records
  };
}

export function getExerciseReportSubscriptionsOfCurrentUser(): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_REPORT_SUBSCRIPTIONS_OF_CURRENT_USER
  };
}

export function getExerciseReportSubscriptionOfSchool(
  schoolId: string
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_REPORT_SUBSCRIPTIONS_OF_SCHOOL,
    payload: schoolId
  };
}

export function getExerciseReportSubscriptionsComplete(
  subscriptions: ExerciseReportSubscription[]
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_RECORD_REPORT_SUBSCRIPTIONS_COMPLETE,
    payload: subscriptions
  };
}

export function deleteExerciseReportSubscription(
  id: string,
  schoolId?: string
): UnsafeAction {
  return {
    type: ReportingActionTypes.DELETE_EXERCISE_REPORT_SUBSCRIPTION,
    payload: { id, schoolId }
  };
}

export function deleteExerciseReportSubscriptionComplete(
  id: string
): UnsafeAction {
  return {
    type: ReportingActionTypes.DELETE_EXERCISE_REPORT_SUBSCRIPTION_COMPLETE,
    payload: id
  };
}

export function createExerciseReportSubscription(
  subscription: ExerciseReportSubscription
): UnsafeAction {
  return {
    type: ReportingActionTypes.CREATE_EXERCISE_RECORD_REPORT_SUBSCRIPTION,
    payload: subscription
  };
}

export function createExerciseReportSubscriptionComplete(
  subscription: ExerciseReportSubscription
): UnsafeAction {
  return {
    type: ReportingActionTypes.CREATE_EXERCISE_RECORD_REPORT_SUBSCRIPTION_COMPLETE,
    payload: subscription
  };
}

export function getUserVocabulary(
  subject: string,
  syllabusId: string,
  start: Date,
  end: Date,
  option: string
) {
  return {
    type: ReportingActionTypes.GET_VOCABULARY_ATTEMPTED_BY_USER,
    payload: { subject, syllabusId, start, end, option }
  };
}

export function getVocabularyAttemptedByUserComplete(
  records: PhraseAttempted[]
) {
  return {
    type: ReportingActionTypes.GET_VOCABULARY_ATTEMPTED_BY_USER_COMPLETE,
    payload: records
  };
}

export function getExerciseRecords(syllabusId: string): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_RECORDS,
    payload: syllabusId
  };
}

export function getExerciseRecordsBySubject(
  subject: string,
  syllabusId: string
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_RECORDS_BY_SUBJECT,
    payload: { subject, syllabusId }
  };
}

export function getExerciseRecordsByEmail(
  email: string,
  syllabusId: string
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_RECORDS_BY_EMAIL,
    payload: { email, syllabusId }
  };
}

export function getExerciseRecordsComplete(
  records: ExerciseRecord[]
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_EXERCISE_RECORDS_COMPLETE,
    payload: records
  };
}

export function getUsersExerciseRecords(
  syllabusId: string,
  startTime: Date,
  endTime: Date,
  subjects: string[]
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_USERS_EXERCISE_RECORDS,
    payload: { syllabusId, startTime, endTime, subjects }
  };
}

export function getUsersExerciseRecordsComplete(records: any[]): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_USERS_EXERCISE_RECORDS_COMPLETE,
    payload: records
  };
}

export function clearExerciseRecords(): UnsafeAction {
  return { type: ReportingActionTypes.CLEAR_EXERCISE_RECORDS };
}

export function getUsersTestRecords(
  syllabusId: string,
  startTime: Date,
  endTime: Date,
  subjects: string[]
): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_USERS_TEST_RECORDS,
    payload: { syllabusId, startTime, endTime, subjects }
  };
}

export function getUsersTestRecordsComplete(records: any[]): UnsafeAction {
  return {
    type: ReportingActionTypes.GET_USERS_TEST_RECORDS_COMPLETE,
    payload: records
  };
}
