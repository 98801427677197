import * as iassign from 'immutable-assign';
import { Pronunciation } from 'src/app/model/pronunciation';
import { Language } from '../../model/language';
import {
  defaultDictionaryLevelsSelected,
  defaultVocabularyPronunciationsSelected
} from '../../model/language-pronunciations-map';
import { UnsafeAction } from '../../model/unsafe-action';
import { UserPreference } from '../../model/user-preference';
import { UserPreferenceActionTypes } from './user-preference-actions';

export interface UserPreferenceState {
  userPreference: UserPreference;
}

export const INITIAL_USER_PREFERENCE_STATE: UserPreferenceState = {
  userPreference: {
    uiLanguageCode: undefined,
    enableQuestionThrottling: true,
    showNativeDefinitionsFirst: false,
    vocabularyPronunciations: defaultVocabularyPronunciationsSelected,
    dictionaryLevels: defaultDictionaryLevelsSelected,
    vocabularyLanguage: Language.English,
    alwaysPronounceVocabularyQuestion: false,
    colorTheme: 'light',
    landingPreference: '',
    disableThumbnails: false,
    narrationPreference: Pronunciation.AmericanEnglish
  }
};

export function userPreferenceStateReducer(
  state: UserPreferenceState = INITIAL_USER_PREFERENCE_STATE,
  { type, payload }: UnsafeAction
): UserPreferenceState {

  switch (type) {
    case UserPreferenceActionTypes.CHANGE_THEME_COLOR:
      return iassign(state, (s) => {
        if (payload) {
          s.userPreference = iassign(s.userPreference, (p) => {
            p.colorTheme = payload;
            return p;
          });
        }
        return s;
      });
    case UserPreferenceActionTypes.CHANGE_LANDING_PREFERENCE:
      return iassign(state, (s) => {
        if (payload) {
          if(payload === s.userPreference.landingPreference){
            s.userPreference = iassign(s.userPreference, (p) => {
              p.landingPreference = '';
              return p;
            });
          } else{
            s.userPreference = iassign(s.userPreference, (p) => {
              p.landingPreference = payload;
              return p;
            });
          }
        }
        return s;
      });
    case UserPreferenceActionTypes.GET_USERPREFERENCE_COMPLETE:
      return iassign(state, (s) => {
        if (payload) {
          s.userPreference = payload;
        }
        return s;
      });
    case UserPreferenceActionTypes.CHANGE_UI_LANGUAGE_PREFERENCE:
      return iassign(state, (s) => {
        s.userPreference = iassign(s.userPreference, (p) => {
          p.uiLanguageCode = payload.ianaCode;
          return p;
        });
        return s;
      });
    case UserPreferenceActionTypes.CHANGE_DICTIONARYLEVEL_PREFERENCE:
      return iassign(state, (s) => {
        s.userPreference = iassign(s.userPreference, (p) => {
          p.dictionaryLevels = iassign(p.dictionaryLevels, (levels) => {
            levels[s.userPreference.vocabularyLanguage] = payload;
            return levels;
          });
          return p;
        });
        return s;
      });
    case UserPreferenceActionTypes.TOGGLE_SHOW_DEFINITIONS_ORDER:
      return iassign(state, (s) => {
        s.userPreference = iassign(s.userPreference, (p) => {
          p.showNativeDefinitionsFirst = !p.showNativeDefinitionsFirst;
          return p;
        });
        return s;
      });
    case UserPreferenceActionTypes.CHANGE_VOCABULARY_PRONUNCIATION_PREFERENCE:
      return iassign(state, (s) => {
        s.userPreference = iassign(s.userPreference, (p) => {
          p.vocabularyPronunciations = iassign(
            p.vocabularyPronunciations,
            (ps) => {
              ps[payload.language] = payload.pronunciation;
              return ps;
            }
          );
          return p;
        });
        return s;
      });
    case UserPreferenceActionTypes.CHANGE_VOCABULARY_LANGUAGE: {
      return iassign(state, (s) => {
        s.userPreference = iassign(s.userPreference, (p) => {
          p.vocabularyLanguage = payload;
          return p;
        });
        return s;
      });
    }
    case UserPreferenceActionTypes.TOGGLE_DISABLE_THUMBNAILS: {
      return iassign(state, (s) => {
        s.userPreference = iassign(s.userPreference, (p) => {
          p.disableThumbnails = !p.disableThumbnails;
          return p;
        });
        return s;
      });
    }

    default:
      return state;
  }
}
