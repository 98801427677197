import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toPairs } from 'lodash-es';
import { HistoryItem } from '../erp/history-item';

@Component({
  selector: 'app-contact-history-edit',
  templateUrl: './contact-history-edit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContactHistoryEditComponent)
    }
  ]
})
export class ContactHistoryEditComponent implements ControlValueAccessor {
  items: HistoryItem[] = [];

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: HistoryItem[]) => {};

  onTouched = () => {};

  writeValue(values: HistoryItem[]) {
    this.items = values;
    this.onChange(values);
  }

  onRemarkChange() {
    this.onChange(this.items);
  }

  getitems(value: { [key: string]: string }): any[] {
    return toPairs(value).map((p) => {
      return { date: new Date(p[0]), remark: p[1] };
    });
  }

  onAddButtonClick() {
    const newItem = { date: new Date(), remark: '' };
    this.items = [...this.items, newItem];
    this.onChange(this.items);
  }
}
