import { createSelector } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { languagePronunciationsMap } from '../../model/language-pronunciations-map';
import { Level } from '../../model/level';
import { AppState } from '../../states/states-reducers';

export const userPreferenceStateSelector = (state: AppState) => state.userPreference;

export const userPreferenceSelector = createSelector(
  userPreferenceStateSelector,
  (state) => state.userPreference
);

export const alwaysPronounceVocabularyQuestionSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => userPreference.alwaysPronounceVocabularyQuestion
);

export const vocabularyLanguageSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => userPreference.vocabularyLanguage
);

export const availablePronunciationsSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    const lang = userPreference.vocabularyLanguage;
    return languagePronunciationsMap[lang];
  }
);

export const vocabularyPronunciationsSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => userPreference.vocabularyPronunciations
);

export const colorThemeSelector = createSelector(
  userPreferenceSelector,
  userPreference => userPreference.colorTheme
);

export const vocabularyPronunciationSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    const vocabularyLanguage = userPreference.vocabularyLanguage;
    const vocabularyPronunciationsSelected =
      userPreference.vocabularyPronunciations;
    return vocabularyPronunciationsSelected[vocabularyLanguage];
  }
);

export const dictionaryLevelSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    const vocabularyLanguage = userPreference.vocabularyLanguage;
    const dictionaryLevelsSelected = userPreference.dictionaryLevels;
    const level = dictionaryLevelsSelected[vocabularyLanguage];
    if (isNil(level)) {
      return Level.Advanced;
    }
    return level;
  }
);

export const enableQuestionThrottingSelector = createSelector(
  userPreferenceSelector,
  (userPrference) => userPrference.enableQuestionThrottling
);

export const languageCodeSelectedSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    if (userPreference) {
      return userPreference.uiLanguageCode;
    }
    return undefined;
  }
);

export const showNativeDefinitionsFirstSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    if (userPreference) {
      return userPreference.showNativeDefinitionsFirst;
    } else {
      return false;
    }
  }
);

export const landingPreferenceSelector = createSelector(
  userPreferenceSelector,
  (userPreference) => {
    if(userPreference){
      return userPreference.landingPreference;
    } else{
      return '/home';
    }
  }
);
