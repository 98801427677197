import { Level } from '../../model/level';
import { UICulture } from '../../model/ui-culture';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const ExerciseActionTypes = {
  START_EXERCISE: type('[Exercise] Start Exercise'),
  UPDATE_EXERCISE_LEVELS: type('[Exercise] Update Exercise Levels')
};

export function startExercise(
  syllabusId: string,
  skipQuestionsRequireCalculator: boolean,
  isTest: boolean,
  enableQuestionThrottling: boolean,
  hasUnlimitedAccess: boolean,
  uiCulture: UICulture,
  dictionaryLevel: Level
): UnsafeAction {
  return {
    type: ExerciseActionTypes.START_EXERCISE,
    payload: {
      syllabusId,
      skipQuestionsRequireCalculator,
      isTest,
      enableQuestionThrottling,
      hasUnlimitedAccess,
      uiCulture,
      dictionaryLevel
    }
  };
}

export function updateExerciseLevels(): UnsafeAction {
  return {
    type: ExerciseActionTypes.UPDATE_EXERCISE_LEVELS
  };
}
