import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GestureDirective } from './gesture.directive';
import { ShowIfLoggedInDirective } from './show-if-logged-in.directive';
import { ShowIfLoggedOutDirective } from './show-if-logged-out.directive';
import { ShowIfSystemAdmintDirective } from './show-if-system-admin.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ShowIfLoggedInDirective,
    ShowIfLoggedOutDirective,
    ShowIfSystemAdmintDirective,
    GestureDirective
  ],
  exports: [
    ShowIfLoggedInDirective,
    ShowIfLoggedOutDirective,
    ShowIfSystemAdmintDirective,
    GestureDirective
  ]
})
export class DisplayModule {}
