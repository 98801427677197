import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { isNumber } from 'lodash-es';
import { Observable, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

const incrementinMs = 20 * 1000;
@Injectable({ providedIn: 'root' })
export class DelayPreloadingStrategy implements PreloadingStrategy {
  delayInMs = 30 * 1000;

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const loadRoute = (delay: number) =>
      delay ? timer(delay).pipe(mergeMap((_) => load())) : load();
    if (route.data && isNumber(route.data['delay'])) {
      return loadRoute(route.data['delay']);
    } else {
      this.delayInMs += incrementinMs;
      return loadRoute(this.delayInMs);
    }
  }
}
