import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { find, sortBy, toPairs } from 'lodash-es';
import { field } from './field';

@Component({
  selector: 'app-fields-edit',
  templateUrl: './fields-edit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FieldsEditComponent)
    }
  ]
})
export class FieldsEditComponent implements ControlValueAccessor {
  fields: field[];

  registerOnChange(fn: (_: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this.setValue(value);
    this.onChange(value);
  }

  onFieldChange() {
    this.onChange(this.getValue());
  }

  setValue(value: any) {
    this.fields = this.getFields(value);
  }

  setField(value: field) {
    //TOD check this code
    const field = find(this.fields, (f) => f.fieldname === value.fieldname);
    if (field) {
      field.fieldvalue = JSON.stringify(value.fieldvalue);
      this.onFieldChange();
    }
  }

  getValue(): any {
    const value: any = {};
    this.fields.forEach((f) => (value[f.fieldname] = JSON.parse(f.fieldvalue)));
    return value;
  }

  getFields(object: any): field[] {
    if (object) {
      const pairs = toPairs(object);
      const fields = pairs.map((p) => {
        const fieldname = p[0];
        const fieldvalue = JSON.stringify(p[1]);
        return {
          fieldname,
          fieldvalue
        };
      });
      return sortBy(fields, (f) => f.fieldname);
    }
    return [];
  }
}
