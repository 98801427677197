import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { InternetConnectionService } from '../core/internet-connection.service';
import { changeThemeColor } from '../core/user/user-preference-actions';
import { DynamicDialogConfigBuilder } from '../dynamic-dialog-config-builder';
import { LanguageSelectionDialogComponent } from '../home-ui/language-selection-dialog.component';
import { SetupDialogComponent } from '../home-ui/setup-dialog.component';
import { changeUILanguage } from '../i18n/i18n-actions';
import { supportedUILanguages } from '../i18n/i18n-utility';
import { routerUrlSelector } from '../states/router-state-selector';
import { AppState } from '../states/states-reducers';
import { AppMainComponent } from './app.main.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate(
          '.12s cubic-bezier(0, 0, 0.2, 1)',
          style({ opacity: 1, transform: '*' })
        )
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))])
    ])
  ]
})
export class AppTopBarComponent implements OnDestroy, OnInit {
  private subs = new SubSink();
  supportedUILanguages = supportedUILanguages;
  isOnHome$: Observable<boolean>;
  _languageSelectionDialogComponentRef: DynamicDialogRef;
  _setupDialogComponentRef: DynamicDialogRef;

  constructor(
    public appMain: AppMainComponent,
    private store: Store<AppState>,
    public connection: InternetConnectionService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.isOnHome$ = this.store.select(routerUrlSelector).pipe(
      map((url) => {
        if (url) {
          return url === '/home' || url === '/home;onboarding=true';
        } else {
          return false;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onGlobeClicked() {
    if (this._languageSelectionDialogComponentRef) {
      this._languageSelectionDialogComponentRef.close();
      this._languageSelectionDialogComponentRef = null;
    } else {
      const builder = new DynamicDialogConfigBuilder();
      builder.position = 'fixed';
      builder.top = '5rem';
      builder.right = '2rem';
      builder.showHeader = false;
      this._languageSelectionDialogComponentRef = this.dialogService.open(
        LanguageSelectionDialogComponent,
        builder.buildFixed()
      );

      this.subs.sink =
        this._languageSelectionDialogComponentRef.onClose.subscribe((value) => {
          if (value) {
            this.store.dispatch(changeUILanguage(value));
          }
        });
    }
  }

  onSetupClicked() {
    if (this._setupDialogComponentRef) {
      this._setupDialogComponentRef.close();
      this._setupDialogComponentRef = null;
    } else {
      const builder = new DynamicDialogConfigBuilder();
      builder.position = 'fixed';
      builder.top = '5rem';
      builder.right = '2rem';
      builder.showHeader = false;
      this._setupDialogComponentRef = this.dialogService.open(
        SetupDialogComponent,
        builder.buildFixed()
      );

      this.subs.sink =
        this._setupDialogComponentRef.onClose.subscribe((value) => {
          if (value) {
            this.store.dispatch(changeThemeColor(value));
          }
        });
    }
  }
}
