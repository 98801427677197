import { UnsafeAction } from '../../model/unsafe-action';
import { QuestionDTO } from '../../model/questionDTO';
import { type } from '../../utility/type';

export const QuestionActionTypes = {
  GET_QUESTIONS_BY_SYLLABUS_ID: type('[Question] Get Questions By Syllabus Id'),
  GET_QUESTIONS_BY_SYLLABUS_LINE_ID: type(
    '[Question] Get Questions By syllabus line id'
  ),
  GET_QUESTIONS_COMPLETE: type('[Question] Get Questions Complete'),
  UPSERT_QUESTION: type('[Question] Upsert Question'),
  UPSERT_QUESTION_COMPLETE: type('[Question] Upsert Question Complete'),
  UPSERT_QUESTIONS: type('[Question] Upsert Questions'),
  UPSERT_QUESTIONS_COMPLETE: type('[Question] Upsert Questions Complete'),
  DELETE_QUESTION: type('[Question] Delete Question'),
  DELETE_QUESTION_COMPLETE: type('[Question] Delete Question Complete'),
  DETACH_QUESTION: type('[Question] Detach Question'),
  DETACH_QUESTION_COMPLETE: type('[Question] Detach Question Complete'),

  SAVE_QUESTIONS_CATEGORY_CHANGE: type(
    '[Question] Save Questions Category Change'
  ),
  SAVE_QUESTIONS_CATEGORY_CHANGE_COMPLETE: type(
    '[Qeustion] Save Questions Category Change Completed'
  )
};

export function saveQuestionsCategoryChange(
  syllabusLineId: string,
  questionIds: string[]
): UnsafeAction {
  return {
    type: QuestionActionTypes.SAVE_QUESTIONS_CATEGORY_CHANGE,
    payload: { syllabusLineId, questionIds }
  };
}

export function saveQuestionsCategoryChangeCompleted(
  updatedQuestions: QuestionDTO[]
): UnsafeAction {
  return {
    type: QuestionActionTypes.SAVE_QUESTIONS_CATEGORY_CHANGE_COMPLETE,
    payload: { updatedQuestions }
  };
}

export function getQuestionsBySyllabusId(syllabusId: string): UnsafeAction {
  return {
    type: QuestionActionTypes.GET_QUESTIONS_BY_SYLLABUS_ID,
    payload: syllabusId
  };
}

export function getQuestionsComplete(questions: QuestionDTO[]): UnsafeAction {
  return {
    type: QuestionActionTypes.GET_QUESTIONS_COMPLETE,
    payload: questions
  };
}

export function upsertQuestion(question: QuestionDTO): UnsafeAction {
  return { type: QuestionActionTypes.UPSERT_QUESTION, payload: question };
}

export function upsertQuestionComplete(question: QuestionDTO): UnsafeAction {
  return {
    type: QuestionActionTypes.UPSERT_QUESTION_COMPLETE,
    payload: question
  };
}

export function upsertQuestions(questions: QuestionDTO[]) {
  return { type: QuestionActionTypes.UPSERT_QUESTIONS, payload: questions };
}

export function upsertQuestionsComplete(questions: QuestionDTO[]) {
  return {
    type: QuestionActionTypes.UPSERT_QUESTIONS_COMPLETE,
    payload: questions
  };
}

export function deleteQuestion(question: QuestionDTO) {
  return { type: QuestionActionTypes.DELETE_QUESTION, payload: question };
}

export function deleteQuestionComplete(question: QuestionDTO) {
  return {
    type: QuestionActionTypes.DELETE_QUESTION_COMPLETE,
    payload: question
  };
}

export function detachQuestion(question: QuestionDTO, syllabusLineId: string) {
  return {
    type: QuestionActionTypes.DETACH_QUESTION,
    payload: { question, syllabusLineId }
  };
}

export function detachQuestionComplete(question: QuestionDTO) {
  return {
    type: QuestionActionTypes.DETACH_QUESTION_COMPLETE,
    payload: question
  };
}

export function getQuestionsOfSyllabusLineId(syllabusLineId: string) {
  return {
    type: QuestionActionTypes.GET_QUESTIONS_BY_SYLLABUS_LINE_ID,
    payload: syllabusLineId
  };
}
