import { createSelector } from '@ngrx/store';
import { appStateSelector } from './state-selectors';

const routerSelector = createSelector(
  appStateSelector,
  (state) => state.router
);

const routerStateSelector = createSelector(
  routerSelector,
  (state) => state.state
);

export const routerUrlSelector = createSelector(
  routerStateSelector,
  (state) => {
    if (state) {
      return state.url;
    } else {
      return undefined;
    }
  }
);
