<div class="flex-column p-3">
  <ng-container *ngFor="let supportedUILanguage of supportedUILanguages">
    <div style="cursor: pointer;"
      class="col-12 text-left"
      (click)="onLanguageSelected(supportedUILanguage)"
    >
      {{ supportedUILanguage.text }}
    </div>
  </ng-container>
</div>
