<ng-container *ngIf="erpSchool">
  <div class="grid">
    <div class="col-6">
      <button
        class="p-button-info"
        pButton
        type="button"
        label="Save School"
        (click)="onSavedButtonClick()"
      ></button>
    </div>
    <div class="col-6">
      <button
        class="p-button-info"
        pButton
        type="button"
        label="Reset School"
        (click)="onResetBButtonClick()"
      ></button>
    </div>

    <div class="col-12">
      <p-panel [header]="schoolName" [toggleable]="true" [collapsed]="false">
        <app-fields-edit [(ngModel)]="erpSchool"></app-fields-edit>
      </p-panel>
    </div>

    <app-contacts-edit
      [(ngModel)]="erpSchool.contacts"
      (ngModelChange)="onContactsChange($event)"
    ></app-contacts-edit>

    <div class="col-6">
      <app-schoolland-school-display
        [schoolLandSchool]="schoolLandSchool$ | async"
        (fieldClick)="onFieldClick($event)"
      ></app-schoolland-school-display>
    </div>

    <div class="col-6">
      <app-edb-school-display
        [edbSchool]="edbSchool$ | async"
        (fieldClick)="onFieldClick($event)"
      ></app-edb-school-display>
    </div>
  </div>
</ng-container>
