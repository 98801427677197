//Don' change the order
export enum Language {
  English = 0,
  Chinese = 1,
  Japanese = 2,
  Korean = 3,
  Spanish = 4,
  French = 5,
  German = 6,
  Afrikaans = 7,
  Portuguese = 8,
  Unspecified = 9,
  Russian = 10,
  Malay = 11,
  Indonesian = 12,
  Turkish = 13,
  Arabic = 14,
  Catalan = 15,
  Italian = 16,
  Polish = 17,
  Urdu = 18
}
