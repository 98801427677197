
<div class="grid ">
  <div class="col-6">
    <div class="col-12">
      {{ schoolCode }}
    </div>

    <div class="col-12">
      {{ erpSchool.schoolNameInChinese }}
    </div>

    <div class="col-12">
      {{ erpSchool.schoolNameInEnglish }}
    </div>

    <div class="col-12">Telephone : {{ erpSchool.telephoneNumber }}</div>

    <div class="col-12">Fax : {{ erpSchool.faxNumber }}</div>

    <div class="col-12">
      Head of School : {{ erpSchool.headOfSchoolInChinese }}
      {{ erpSchool.headOfSchoolInEnglish }}
    </div>

    <div class="col-12">
      Supervisor : {{ erpSchool.supervisorNameInChinese }}
      {{ erpSchool.supervisorNameInEnglish }}
    </div>

    <div class="col-12">
      {{ erpSchool.districtInChinese }} : {{ erpSchool.schoolAddressInChinese }}
    </div>

    <div class="col-12">
      {{ erpSchool.districtInEnglish }} : {{ erpSchool.schoolAddressInEnglish }}
    </div>

    <div class="col-12">
      <a href="mailto:{{ erpSchool.schoolEmailAddress }}">{{
        erpSchool.schoolEmailAddress
      }}</a>
    </div>

    <div class="col-12">
      <a href="{{ erpSchool.schoolWebSite }}" target="_blank">{{
        erpSchool.schoolWebSite
      }}</a>
    </div>


    <div class="col-12">
      <a href="{{ erpSchool.schoolCalendar }}" target="_blank">{{
        erpSchool.schoolCalendar
      }}</a>
    </div>

    <div class="col-12">
      <a href="{{ erpSchool.googleMapLink }}" target="_blank">Google Map</a>
    </div>

    <div class="col-12">
      <a [href]="erpSchool.schoolLandUrl" target="_blank">School Land</a>
    </div>

    <div class="col-12">
      <a href="{{ erpSchool.edbUrl }}" target="_blank">EDB</a>
    </div>

    <div class="col-12" *ngFor="let ref of erpSchool.reference">
      <a href="{{ ref }}" target="_blank">{{ ref }}</a>
    </div>

    <!-- <div class="col-12" *ngFor="let contact of erpSchool.contacts">
        {{contact | json}}
    </div> -->
  </div>

  <div class="col-6">
    <app-contacts-display [contacts]="erpSchool.contacts"></app-contacts-display>
  </div>
</div>
