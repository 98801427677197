import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { UICulture } from '../../model/ui-culture';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const VocabExerciseActionTypes = {
  START_VOCABULARY_EXERCISE: type('[Vocabulary Exercise] Start Vocab Exercise'),
  UPDATE_VOCABLEVELS: type('[Vocabulary Exercise] Update VocabLevels')
};

export function startVocabExercise(
  language: Language,
  enableQuestionThrottling: boolean,
  uiCulture: UICulture,
  dictionaryLevel: Level,
  alwaysPronounceVocabularyQuestion: boolean
): UnsafeAction {
  return {
    type: VocabExerciseActionTypes.START_VOCABULARY_EXERCISE,
    payload: {
      language,
      enableQuestionThrottling,
      uiCulture,
      dictionaryLevel,
      alwaysPronounceVocabularyQuestion
    }
  };
}

export function updateVocabLevels(): UnsafeAction {
  return {
    type: VocabExerciseActionTypes.UPDATE_VOCABLEVELS
  };
}
