import { addDays, addYears, differenceInMinutes, endOfDay } from 'date-fns';
import { AssessmentPeriod } from '../model/assessment-period';
import {
  CustomReportAttributes
} from '../model/custom-report-attributes';

export function getStartDateOfCurrentAssessmentPeriod(
  customReportAttribute: CustomReportAttributes
): Date {
  try {
    if (
      customReportAttribute &&
      customReportAttribute.assessmentPeriods &&
      customReportAttribute.assessmentPeriods.length > 0
    ) {
      const currentAssessmentPeriod = getAssessmentParametersOfToday(
        customReportAttribute.assessmentPeriods
      );
      return currentAssessmentPeriod.start;
    } else {
      return getInitialStartDateByHalfYear();
    }
  } catch (error) {
    return getInitialStartDateByHalfYear();
  }
}

export function getInitialStartDateByHalfYear(): Date {
  const now = new Date();
  if (now.getMonth() > 5) {
    return new Date(now.getFullYear(), 6, 1);
  } else {
    return new Date(now.getFullYear(), 0, 1);
  }
}

export function getAssessmentParametersOfToday(
  assessmentPeriods: AssessmentPeriod[]
) {
  return getAssessmentParameters(new Date(), assessmentPeriods);
}

export function getAssessmentParameters(
  date: Date,
  assessmentPeriods: AssessmentPeriod[]
): {
  start: Date;
  end: Date;
  maxScore: number;
  days: number;
} {
  let result : {start: Date, end: Date, maxScore: number} = undefined;
  if (assessmentPeriods) {
    switch (assessmentPeriods.length) {
      case 0:
        result = getDefaultAssessmentParameters(date);
        break;
      case 1:
        result = getAssessmentParametersWithOneAssessmentPeriodOnly(
          date,
          assessmentPeriods[0]
        );
        break;
      default:
        result = getAssessmentParametersWithMultipleAssessmentPeriods(
          date,
          assessmentPeriods
        );
    }
  } else {
    result = getDefaultAssessmentParameters(date);
  }

  const minutes = differenceInMinutes(result.end, result.start);
  const days = Math.round(minutes / 1440);
  return {start : result.start, end : result.end, maxScore : result.maxScore, days};
}

export function getDefaultAssessmentParameters(date: Date): {
  start: Date;
  end: Date;
  maxScore: number;
} {
  const currentYear = date.getFullYear();
  let start: Date;
  let end: Date;
  if (date.getMonth() > 5) {
    start = new Date(currentYear, 6, 1);
    end = endOfDay(new Date(currentYear, 11, 31));
  } else {
    start = new Date(currentYear, 0, 1);
    end = endOfDay(new Date(currentYear, 5, 30));
  }
  return { start, end, maxScore: 366 };
}

export function getAssessmentParametersWithMultipleAssessmentPeriods(
  date: Date,
  assessmentPeriods: AssessmentPeriod[]
): {
  start: Date;
  end: Date;
  maxScore: number;
} {
  const year = date.getFullYear();
  const assessmentStartDates = getAssessmentStartDates(year, assessmentPeriods);
  for (let index = 0; index < assessmentStartDates.length; index++) {
    const start = assessmentStartDates[index];
    const next = assessmentStartDates[index + 1];
    if (date >= start.date && date < next.date) {
      return {
        start: start.date,
        end: endOfDay(addDays(next.date, -1)),
        maxScore: start.assessmentPeriod.maxScore
      };
    }
  }
  return undefined;
}

export function getAssessmentParametersWithOneAssessmentPeriodOnly(
  date: Date,
  assessmentPeriod: AssessmentPeriod
): {
  start: Date;
  end: Date;
  maxScore: number;
} {
  let currentYear = date.getFullYear();
  const cutOffDate = new Date(
    currentYear,
    assessmentPeriod.monthDay.month,
    assessmentPeriod.monthDay.day
  );
  if (date >= cutOffDate) {
    return {
      start: cutOffDate,
      end: endOfDay(addYears(addDays(cutOffDate, -1), 1)),
      maxScore: assessmentPeriod.maxScore
    };
  } else {
    return {
      start: addYears(cutOffDate, -1),
      end: endOfDay(addDays(cutOffDate, -1)),
      maxScore: assessmentPeriod.maxScore
    };
  }
}

export function getAssessmentStartDates(
  currentYear: number,
  assessmentPeriods: AssessmentPeriod[]
): { date: Date; assessmentPeriod: AssessmentPeriod }[] {
  if (!assessmentPeriods || assessmentPeriods.length === 0) {
    return [];
  }
  var results: { date: Date; assessmentPeriod: AssessmentPeriod }[] = [];
  let lastSequenceNumber = getSequenceNumber(assessmentPeriods[0].monthDay);
  let year = currentYear - 1;

  do {
    for (let index = 0; index < assessmentPeriods.length; index++) {
      const assessmentPeriod = assessmentPeriods[index];
      const sequenceNumber = getSequenceNumber(assessmentPeriod.monthDay);
      if (sequenceNumber < lastSequenceNumber) {
        year++;
      }

      const date = new Date(
        year,
        assessmentPeriod.monthDay.month,
        assessmentPeriod.monthDay.day
      );
      results.push({ date, assessmentPeriod });
      lastSequenceNumber = sequenceNumber;
    }
  } while (year <= currentYear + 1);
  return results;
}

export function getSequenceNumber(monthDay: { month: number; day: number }): number {
  return monthDay.month * 100 + monthDay.day;
}
