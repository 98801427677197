import * as iassign from 'immutable-assign';
import { find, findIndex } from 'lodash-es';
import { QuestionType } from '../../model/question-type';
import { QuestionDTO } from '../../model/questionDTO';
import { UnsafeAction } from '../../model/unsafe-action';
import { QuestionActionTypes } from './question-actions';

export interface QuestionState {
  questions: QuestionDTO[];
  questionTypeSelected: QuestionType;
}

export const INITIAL_QUESTION_STATE: QuestionState = {
  questions: [],
  questionTypeSelected: QuestionType.ShortResponse
};

export function questionStateReducer(
  state: QuestionState = INITIAL_QUESTION_STATE,
  { type, payload }: UnsafeAction
): QuestionState {
  switch (type) {
    case QuestionActionTypes.SAVE_QUESTIONS_CATEGORY_CHANGE_COMPLETE:
      return iassign(state, (s) => {
        s.questions = getRecategorizedQuestions(
          s.questions,
          payload.updatedQuestions
        );
        return s;
      });
    case QuestionActionTypes.GET_QUESTIONS_BY_SYLLABUS_ID:
      return iassign(state, (s) => {
        s.questions = [];
        return s;
      });
    case QuestionActionTypes.GET_QUESTIONS_COMPLETE:
      return iassign(state, (s) => {
        s.questions = payload;
        return s;
      });
    case QuestionActionTypes.GET_QUESTIONS_BY_SYLLABUS_LINE_ID:
      return iassign(state, (s) => {
        s.questions = [];
        return s;
      });
    case QuestionActionTypes.UPSERT_QUESTION_COMPLETE:
      const updatedQuestion: QuestionDTO = payload;
      const oldQuestionIndex = findIndex(
        state.questions,
        (q) => q.id === updatedQuestion.id
      );
      return iassign(state, (s) => {
        if (oldQuestionIndex > -1) {
          s.questions = iassign(s.questions, (qs) => {
            qs[oldQuestionIndex] = updatedQuestion;
            return qs;
          });
        } else {
          s.questions = [...s.questions, payload];
        }
        return s;
      });
    case QuestionActionTypes.DELETE_QUESTION_COMPLETE:
      return removeQuestionFromQuestionState(state, payload);
    case QuestionActionTypes.DETACH_QUESTION_COMPLETE:
      return removeQuestionFromQuestionState(state, payload);
    default:
      return state;
  }
}

function getRecategorizedQuestions(
  questions: QuestionDTO[],
  updatedQuestions: QuestionDTO[]
): QuestionDTO[] {
  const recategorizedQuestions: QuestionDTO[] = [];

  for (let index = 0; index < questions.length; index++) {
    const question = questions[index];
    const updatedQuestion = find<QuestionDTO>(
      updatedQuestions,
      (updatedQuestion) => {
        return (
          updatedQuestion.id === question.id &&
          updatedQuestion.originalSyllabusLineId === question.syllabusLineId
        );
      }
    );
    if (updatedQuestion) {
      recategorizedQuestions.push(updatedQuestion);
    } else {
      recategorizedQuestions.push(question);
    }
  }
  return recategorizedQuestions;
}

function removeQuestionFromQuestionState(
  state: QuestionState,
  question: QuestionDTO
): QuestionState {
  const questions = state.questions.filter((q) => q.id !== question.id);
  return iassign(state, (s) => {
    s.questions = questions;
    return s;
  });
}
