import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { InAppNotification } from '../../model/in-app-notification';
import { PushNotification } from '../../model/push-notification';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private httpService: HttpClientService,
    private config: Configuration
  ) {}

  addPushSubscriber(sub: PushSubscription): Observable<any> {
    const url = this.config.apiRootUrl + '/notification/webPushSubscriptions';
    return this.httpService.authPost(url, sub);
  }

  pushNotification(notification: PushNotification): Observable<boolean> {
    const url = this.config.apiRootUrl + '/notification/notifications';
    return this.httpService.authPost(url, notification).pipe(
      mapTo(true),
      catchError((_) => of(false))
    );
  }

  getInAppNotifications(): Observable<InAppNotification[]> {
    const url = this.config.apiRootUrl + '/notification/inAppNotifications';
    return this.httpService.authGet<InAppNotification[]>(url);
  }

  unsubscribeNotifications(notificationIds: string[]): Observable<any> {
    const url = this.config.apiRootUrl + '/notification/inAppNotifications';
    return this.httpService.authPatch(url, notificationIds);
  }
}
