import { addDays, addMilliseconds, differenceInDays, format } from 'date-fns';
import { find } from 'lodash-es';
import { SelectItem } from 'primeng/api';
const ticksIn1970Years = 621355968000000000;

export function getMidDate(start: Date, end: Date) {
  var diffInDays = differenceInDays(end, start);
  return addDays(start, diffInDays / 2);
}
export function getLocale() {
  if (navigator.languages) {
    return navigator.languages[0];
  } else {
    return navigator.language;
  }
}

export function toShortDateTimeHTMLString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yy-MM-dd HH&#58;mm');
  } catch (error) {
    return '';
  }
}

export function toDateString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    return '';
  }
}

export function toDateTimeOffsetString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yyy-MM-dd hh:mm:ss XXX');
  } catch (error) {
    return '';
  }
}

export function to8DigitDateNumber(date: Date): number {
  if (!date) {
    return undefined;
  }
  try {
    return +format(date, 'yyyyMMdd');
  } catch (error) {
    return undefined;
  }
}

export function toDateFrom8DigitalNumber(dateNumber : number) : Date{
  const startString = dateNumber.toString();
  const year = +startString.substring(0, 4);
  const monthIndex = +startString.substring(4,6) - 1;
  const day = +startString.substring(6,8);
  return new Date(year, monthIndex, day);
}

export function get8DigitSerials(start: number, end : number) : number[]{
  const results : number[] = [];
  let day = toDateFrom8DigitalNumber(start);
  let serial : number;
  do {
    serial = to8DigitDateNumber(day);
    results.push(serial);
    day = addDays(day, 1);
  } while (serial < end);
  return results;
}

export function toLongDateString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    return '';
  }
}

export function toShortDateString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yy-MM-dd');
  } catch (error) {
    return '';
  }
}

export function toLongDateTimeString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yyyy-MM-dd-HHmmss');
  } catch (error) {
    return '';
  }
}

export function toDateTimeString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'yyyy-MM-dd-HHmm');
  } catch (error) {
    return '';
  }
}

export function toMiniDateTimeHTMLString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'MM&#8209;dd&#58;HHmm');
  } catch (error) {
    return '';
  }
}

export function toMiniDateHTMLString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'MM&#8209;dd');
  } catch (error) {
    return '';
  }
}

export function toMiniDateString(date: Date): string {
  if (!date) {
    return undefined;
  }
  try {
    return format(date, 'dd/MM');
  } catch (error) {
    return '';
  }
}

export function toTimeMinSec(timeInMs: number): { min: number; sec: number } {
  const secTaken = timeInMs / 1000;
  const min = Math.trunc(secTaken / 60);
  const sec = Math.trunc(secTaken % 60);
  return { min, sec };
}

export function ticktoDate(tick: string): Date {
  return new Date((+tick - ticksIn1970Years) / 10000);
}

export function getStartOfDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getStartOfToday(): Date {
  return getStartOfDate(new Date());
}

export function getEndOfDate(date: Date): Date {
  const dateOnly = getStartOfDate(date);
  const oneDayMinusOneMs = 24 * 3600 * 1000 - 1;
  return addMilliseconds(dateOnly, oneDayMinusOneMs);
}

export function toTimeSpanString(timeInMs: number): string {
  const secNum = timeInMs / 1000;
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum - hours * 3600) / 60);
  const seconds = Math.round(secNum - hours * 3600 - minutes * 60);
  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')
  );
}

export function toTimeInMs(timeSpanString: string): number {
  if (!timeSpanString) {
    return 0;
  }
  const parts = timeSpanString.split(':');
  const hour = parseFloat(parts[0]);
  const min = parseFloat(parts[1]);
  const sec = parseFloat(parts[2]);
  return ((hour * 60 + min) * 60 + sec) * 1000;
}

export const dayInMs = 24 * 3600 * 1000;
export const yearInMs = 365.25 * dayInMs;
export const monthInMs = yearInMs / 12;

export const timeSpanSelectItems: SelectItem[] = [
  { label: '1 month', value: toTimeSpanString(monthInMs) },
  { label: '1 year', value: toTimeSpanString(yearInMs) },
  { label: '5 years', value: toTimeSpanString(yearInMs * 5) },
  { label: '50 years', value: toTimeSpanString(yearInMs * 50) }
];

export function getTimeSpanDescription(timeSpanString: string): string {
  const item = find(timeSpanSelectItems, (i) => i.value === timeSpanString);
  if (item) {
    return item.label;
  } else {
    return '';
  }
}

export const DEFAULT_UUID = '00000000-0000-0000-0000-000000000000';

export function isDefaultUUID(uuid: string): boolean {
  return uuid === DEFAULT_UUID;
}
