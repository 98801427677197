import { Language } from './language';

export enum UICulture {
  BritishEnglish,
  AmericanEnglish,
  SimplifiedChinese,
  TraditionalChinese,
  Japanese,
  Korean,
  Spanish,
  French,
  German,
  Afrikaans,
  Portuguese,
  Malay,
  Indonesian,
  Russian,
  Turkish,
  Arabic,
  Catalan,
  Italian,
  Polish,
  Urdu,
  Unspecified
}

export function getDefaultUICulture(lang: Language): UICulture {
  switch (lang) {
    case Language.Afrikaans:
      return UICulture.Afrikaans;
    case Language.Chinese:
      return UICulture.TraditionalChinese;
    case Language.English:
      return UICulture.AmericanEnglish;
    case Language.French:
      return UICulture.French;
    case Language.German:
      return UICulture.German;
    case Language.Indonesian:
      return UICulture.Indonesian;
    case Language.Japanese:
      return UICulture.Japanese;
    case Language.Korean:
      return UICulture.Korean;
    case Language.Malay:
      return UICulture.Malay;
    case Language.Portuguese:
      return UICulture.Portuguese;
    case Language.Russian:
      return UICulture.Russian;
    case Language.Spanish:
      return UICulture.Spanish;
    case Language.Urdu:
      return UICulture.Urdu;
    case Language.Unspecified:
      return UICulture.Unspecified;
    default:
      return UICulture.AmericanEnglish;
  }
}

export function getDefaultLanguage(uiCulture: UICulture): Language {
  switch (uiCulture) {
    case UICulture.Afrikaans:
      return Language.Afrikaans;
    case UICulture.AmericanEnglish:
      return Language.English;
    case UICulture.BritishEnglish:
      return Language.English;
    case UICulture.French:
      return Language.French;
    case UICulture.German:
      return Language.German;
    case UICulture.Indonesian:
      return Language.Indonesian;
    case UICulture.Japanese:
      return Language.Japanese;
    case UICulture.Korean:
      return Language.Korean;
    case UICulture.Malay:
      return Language.Malay;
    case UICulture.Portuguese:
      return Language.Portuguese;
    case UICulture.Spanish:
      return Language.Spanish;
    case UICulture.Russian:
      return Language.Russian;
    case UICulture.SimplifiedChinese:
      return Language.Chinese;
    case UICulture.TraditionalChinese:
      return Language.Chinese;
    case UICulture.Urdu:
      return Language.Urdu;
    case UICulture.Unspecified:
      return Language.Unspecified;
    default:
      return Language.English;
  }
}
