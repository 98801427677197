import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { SchoolLandSchool } from './schoolland-school';

@Injectable({ providedIn: 'root' })
export class SchoolLandSchoolService {
  urlRoot: string;
  constructor(config: Configuration, private http: HttpClientService) {
    this.urlRoot = config.erpRootUrl + '/schoolLandSchools';
  }

  getSchoolLandSchool({
    schoolLandSchoolUrl
  }: any): Observable<SchoolLandSchool> {
    const base64String = btoa(schoolLandSchoolUrl);
    const url = `${this.urlRoot}/${base64String}`;
    return this.http.authGet(url);
  }
}
