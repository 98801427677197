import * as iassign from 'immutable-assign';
import { cloneDeep, findIndex, sortBy } from 'lodash-es';
import { MentorMentee } from '../model/mentor-mentee';
import { School } from '../model/school';
import { SchoolAdminRegistration } from '../model/school-admin-registration';
import { SchoolMembershipRenewal } from '../model/school-membership-renewal';
import { SchoolUser } from '../model/school-user';
import { UnsafeAction } from '../model/unsafe-action';
import { BatchDataExportGroup } from './batch-data-export-group';
import { BatchDataReportSubscription } from './batch-data-report-subscription';
import { InternalExaminationMarksRecord } from './examination-marks-record';
import { SchoolActionTypes } from './school-actions';

export interface SchoolState {
  schoolsWithSchoolAdminRights: School[];
  schoolAdminRegistrations: SchoolAdminRegistration[];
  schoolUsers: SchoolUser[];
  schoolSubscriptionRenewals: SchoolMembershipRenewal[];
  mentorMenteeList: MentorMentee[];
  schoolIdSelected: string;
  batchDataExportGroups: BatchDataExportGroup[];
  batchDataReportSubscriptions: BatchDataReportSubscription[];
  examinationMarksRecords: InternalExaminationMarksRecord[];
}

export const INITIAL_SCHOOL_STATE: SchoolState = {
  schoolsWithSchoolAdminRights: [],
  schoolAdminRegistrations: [],
  schoolUsers: [],
  schoolSubscriptionRenewals: [],
  mentorMenteeList: [],
  schoolIdSelected: undefined,
  batchDataExportGroups: [],
  batchDataReportSubscriptions: [],
  examinationMarksRecords: []
};

export function schoolStateReducer(
  state: SchoolState = INITIAL_SCHOOL_STATE,
  { type, payload }: UnsafeAction
): SchoolState {
  switch (type) {
    case SchoolActionTypes.GET_EXAMINATION_MARKS_RECORD_COMPLETE:
      return iassign(state, (s) => {

        const index = findIndex(s.examinationMarksRecords, (r) => {
          return (
            r.dateOfExam.getTime() ===
            payload.record.dateOfExam.getTime()
          );
        });
        const records = [...s.examinationMarksRecords];
        records[index] = payload.record;
        s.examinationMarksRecords = records;
        return s;
      });
    case SchoolActionTypes.DELETE_EXAMINATION_MARKS_RECORD_COMPLETE:
      return iassign(state, (s) => {
        s.examinationMarksRecords = s.examinationMarksRecords.filter(
          (r) => r.dateOfExam !== payload.dateOfExam
        );
        return s;
      });
    case SchoolActionTypes.GET_EXAMINATION_MARKS_RECORDS_COMPLETE:
      return iassign(state, (s) => {
        s.examinationMarksRecords = payload.records;
        return s;
      });
    case SchoolActionTypes.DELETE_BATCH_DATA_REPORT_SUBSCRIPTION_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataReportSubscriptions = s.batchDataReportSubscriptions.filter(
          (sub) => sub.id !== payload
        );
        return s;
      });
    case SchoolActionTypes.DELETE_ALL_BATCH_DATA_REPORT_SUBSCRIPTIONS_BY_SCHOOLID_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataReportSubscriptions = s.batchDataReportSubscriptions.filter(
          (sub) => sub.schoolId !== payload
        );
        return s;
      });
    case SchoolActionTypes.CREATE_BATCH_DATA_REPORT_SUBSCRIPTION_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataReportSubscriptions = [
          ...s.batchDataReportSubscriptions,
          payload
        ];
        return s;
      });
    case SchoolActionTypes.DELETE_ALL_BATCH_DATA_EXPORT_GROUPS_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataExportGroups = s.batchDataExportGroups.filter(
          (g) => g.schoolId !== payload.schoolId
        );
        return s;
      });
    case SchoolActionTypes.GET_BATCH_DATA_REPORT_SUBSCRIPTIONS:
      return iassign(state, (s) => {
        s.batchDataReportSubscriptions = [];
        return s;
      });
    case SchoolActionTypes.GET_BATCH_DATA_REPORT_SUBSCRIPTIONS_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataReportSubscriptions = payload;
        return s;
      });
    case SchoolActionTypes.DELETE_BATCH_DATA_EXPORT_GROUP_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataExportGroups = s.batchDataExportGroups.filter(
          (g) => g.id !== payload
        );
        return s;
      });
    case SchoolActionTypes.CREATE_BATCH_DATA_EXPORT_GROUP_COMPLETE:
      return iassign(state, (s) => {
        s.batchDataExportGroups = sortBy(
          [...s.batchDataExportGroups, payload],
          (g) => g.name
        );
        return s;
      });
    case SchoolActionTypes.GET_BATCH_DATA_EXPORT_GROUPS:
      return iassign(state, (s) => {
        s.batchDataExportGroups = [];
        return s;
      });
    case SchoolActionTypes.GET_BATCH_DATA_EXPORT_GROUPS_COMPLETE:
      const groups = <BatchDataExportGroup[]>payload.groups;
      return iassign(state, (s) => {
        s.batchDataExportGroups = sortBy(groups, (g) => g.name);
        return s;
      });
    case SchoolActionTypes.DELETE_SCHOOL_ADMIN_REGISTRATION_COMPLETE:
      return iassign(state, (s) => {
        s.schoolAdminRegistrations = iassign(
          s.schoolAdminRegistrations,
          (registrations) => {
            return registrations.filter(
              (r) => r.registrationCode !== payload.registrationCode
            );
          }
        );
        return s;
      });
    case SchoolActionTypes.CHANGE_SCHOOL_ID_SELECTED:
      return iassign(state, (s) => {
        s.schoolIdSelected = payload;
        return s;
      });
    case SchoolActionTypes.UPDATE_SCHOOL_ADMIN_REGISTRATION_COMPLETE:
      if (payload) {
        return iassign(state, (s) => {
          const registration: SchoolAdminRegistration = payload;
          const index = findIndex(
            state.schoolAdminRegistrations,
            (r) => r.registrationCode === payload.registrationCode
          );
          const registrations = iassign(
            state.schoolAdminRegistrations,
            (regs) => {
              regs[index] = registration;
              return regs;
            }
          );
          s.schoolAdminRegistrations = registrations;
          return s;
        });
      } else {
        return cloneDeep(state);
      }
    case SchoolActionTypes.GET_SCHOOL_RENEWALS:
      return iassign(state, (s) => {
        s.schoolSubscriptionRenewals = [];
        return s;
      });
    case SchoolActionTypes.GET_SCHOOL_RENEWALS_COMPLETE:
      return iassign(state, (s) => {
        s.schoolSubscriptionRenewals = payload;
        return s;
      });
    case SchoolActionTypes.GET_SCHOOLS_WITH_SCHOOL_ADMIN_RIGHTS:
      return iassign(state, (s) => {
        s.schoolsWithSchoolAdminRights = [];
        return s;
      });
    case SchoolActionTypes.GET_SCHOOLS_WITH_SCHOOL_ADMIN_RIGHTS_COMPLETE:
      return iassign(state, (s) => {
        s.schoolsWithSchoolAdminRights = payload;
        return s;
      });
    case SchoolActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS:
      return iassign(state, (s) => {
        s.schoolAdminRegistrations = [];
        return s;
      });
    case SchoolActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS_COMPLETE:
      return iassign(state, (s) => {
        s.schoolAdminRegistrations = payload;
        return s;
      });
    case SchoolActionTypes.CREATE_SCHOOL_ADMIN_REGISTRATION_COMPLETE:
      return iassign(state, (s) => {
        if (payload) {
          s.schoolAdminRegistrations = [...s.schoolAdminRegistrations, payload];
        }
        return s;
      });
    case SchoolActionTypes.GET_SCHOOL_USERS:
      return iassign(state, (s) => {
        s.schoolUsers = [];
        return s;
      });
    case SchoolActionTypes.GET_SCHOOL_USERS_COMPLETE:
      return iassign(state, (s) => {
        s.schoolUsers = payload;
        return s;
      });

    case SchoolActionTypes.GET_MENTOR_MENTEES_COMPLETE:
      return iassign(state, (s) => {
        s.mentorMenteeList = payload;
        return s;
      });
    case SchoolActionTypes.LINKUP_SCHOOL_ADMIN_REGISTRATION_COMPLETE:
      return iassign(state, (s) => {
        s.schoolAdminRegistrations = updateSchoolAdminRegistration(
          s.schoolAdminRegistrations,
          payload
        );
        return s;
      });
    case SchoolActionTypes.LINK_UP_SCHOOL_USER_REGISTRATION_COMPLETE:
      return iassign(state, (s) => {
        s.schoolUsers = updateSchoolSchoolUserRegistration(
          s.schoolUsers,
          payload
        );
        return s;
      });
    default:
      return state;
  }
}

function updateSchoolSchoolUserRegistration(
  schoolUsers: SchoolUser[],
  {
    registrationCode,
    subject,
    emailAddress
  }: {
    registrationCode: string;
    subject: string;
    emailAddress: string;
  }
): SchoolUser[] {
  return schoolUsers.map((user) => {
    if (user.registrationCode === registrationCode) {
      return iassign(user, (newUser) => {
        newUser.subject = subject;
        newUser.emailAddress = emailAddress;
        return newUser;
      });
    } else {
      return user;
    }
  });
}

function updateSchoolAdminRegistration(
  schoolAdminRegistrations: SchoolAdminRegistration[],
  {
    registrationCode,
    subject,
    emailAddress
  }: {
    registrationCode: string;
    subject: string;
    emailAddress: string;
  }
): SchoolAdminRegistration[] {
  return schoolAdminRegistrations.map((registration) => {
    if (registration.registrationCode === registrationCode) {
      return iassign(registration, (newRegistration) => {
        newRegistration.subject = subject;
        newRegistration.emailAddress = emailAddress;
        return newRegistration;
      });
    } else {
      return registration;
    }
  });
}
