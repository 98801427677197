import { Component, Input } from '@angular/core';
import { orderBy } from 'lodash-es';
import { Contact } from '../erp/contact';

@Component({
  selector: 'app-contacts-display',
  templateUrl: './contacts-display.component.html'
})
export class ContactsDisplayComponent {
  @Input() contacts: Contact[];

  get orderedContacts(): Contact[] {
    return orderBy(
      this.contacts,
      ['isExpired', 'responsibilityArea', 'jobTitle'],
      ['desc', 'asc', 'asc']
    );
  }
}
