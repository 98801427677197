import { DictEntry } from '../../model/dict-entry';
import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { UICulture } from '../../model/ui-culture';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const DictionaryActionTypes = {
  GET_DICT_ENTRIES: type('[Dictionary] Get Dict Entries'),
  GET_DICT_ENTRIES_COMPLETE: type('[Dictionary] Get Dict Entries Complete'),
  CLEAR_ENTRIES: type('[Dictionary] Clear thumbnails'),
  DISPLAY_EXPLANATION_TOASTR: type('[Dictionary] Display Explanation Toastr')
};

export function getDictEntries(
  phrase: string,
  uiCulture: UICulture,
  language: Language,
  dictionaryLevel: Level,
  newSearch: boolean,
  disableCache: boolean
): UnsafeAction {
  return {
    type: DictionaryActionTypes.GET_DICT_ENTRIES,
    payload: { phrase, uiCulture, language, dictionaryLevel, newSearch , disableCache}
  };
}

export function getDictEntriesComplete(dictEntries: DictEntry[]): UnsafeAction {
  return {
    type: DictionaryActionTypes.GET_DICT_ENTRIES_COMPLETE,
    payload: dictEntries
  };
}

export function clearDictEntries(): UnsafeAction {
  return { type: DictionaryActionTypes.CLEAR_ENTRIES };
}

export function displayExplanationToastr(
  phrase: string,
  uiCulture: UICulture,
  language: Language
): UnsafeAction {
  return {
    type: DictionaryActionTypes.DISPLAY_EXPLANATION_TOASTR,
    payload: { phrase, uiCulture, language }
  };
}
