import { Component } from '@angular/core';
import { actionStyle } from '../utility/styles';

@Component({
  selector: 'app-support-button',
  template: `
    <app-material-button
      [routerLink]="['/support']"
      icon="fas fa-address-card"
      [buttonStyle]="actionStyle"
      text="{{ 'text.support' | translate }}"
    >
    </app-material-button>
  `
})
export class SupportButtonComponent {
  actionStyle = actionStyle;
}
