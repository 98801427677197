import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomReportAttributes } from 'src/app/model/custom-report-attributes';
import { Configuration } from '../../configuration';
import { SchoolAdminRegistration } from '../../model/school-admin-registration';
import { SchoolUser } from '../../model/school-user';
import { SyllabusAdminRegistration } from '../../model/syllabus-admin-registration';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class UserRightService {
  constructor(private config: Configuration, private http: HttpClientService) {}

  getSchoolUsersOfCurrentUser(): Observable<SchoolUser[]> {
    const url = this.config.apiRootUrl + '/school/schoolUsers';
    return this.http.authGet(url);
  }

  getSchoolAdminRegistrationsOfCurrentUser(): Observable<
    SchoolAdminRegistration[]
  > {
    const url = this.config.apiRootUrl + '/school/schoolAdminRegistrations';
    return this.http.authGet<SchoolAdminRegistration[]>(url);
  }

  getSyllabusAdminRegistrationsOfCurrentUser(): Observable<
    SyllabusAdminRegistration[]
  > {
    const url =
      this.config.apiRootUrl + '/problemSet/syllabusAdminRegistrations';
    return this.http.authGet<SyllabusAdminRegistration[]>(url);
  }

  getCustomReportAttributesOfCurrentUser(): Observable<CustomReportAttributes> {
    const url = `${this.config.apiRootUrl}/school/customReportAttributesCollection`;
    return this.http.authGet<CustomReportAttributes>(url);
  }
}
