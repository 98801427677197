import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ErpState, findErpSchool } from './erp-state-reducer';

export const erpStateSelector = createFeatureSelector<ErpState>('erp');

export const erpSchoolsSelector = createSelector(
  erpStateSelector,
  (erpState) => erpState.erpSchools
);

export const edbSchoolSelector = createSelector(
  erpStateSelector,
  (erpState) => erpState.edbSchool
);

export const schoolLandSchoolSelector = createSelector(
  erpStateSelector,
  (erpState) => erpState.schoolLandSchool
);

export const erpSchoolSelector = createSelector(
  erpStateSelector,
  (erpState) => {
    return findErpSchool(erpState);
  }
);

export const districtSelectedSelector = createSelector(
  erpStateSelector,
  (erpState) => erpState.districtSelected
);

export const schoolLeveltSelectedSelector = createSelector(
  erpStateSelector,
  (erpState) => erpState.schoolLevelSelected
);
