import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
    getMembershipPurchaseOptionsComplete,
    MembershipPurchaseActionTypes
} from './membership-purchase-actions';
import { MembershipPurchaseService } from './membership-purchase.service';

@Injectable()
export class MembershipPurchaseEffects {
  constructor(
    private actions$: Actions,
    private membershipPurchase: MembershipPurchaseService
  ) {}
  @Effect()
  getMembershipPurchaseOptions = this.actions$.pipe(
    ofType(MembershipPurchaseActionTypes.GET_MEMBERSHIP_PURCHASE_OPTIONS),
    exhaustMap((_) => {
      return this.membershipPurchase
        .getMembershipPurchaseOptions()
        .pipe(catchError((_) => of([])));
    }),
    map((options) => getMembershipPurchaseOptionsComplete(options))
  );
}
