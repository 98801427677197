import { Injectable } from '@angular/core';
import { Pronunciation } from '../../model/pronunciation';
import { isNilOrWhiteSpace } from '../../utility/string-utility';
import { AudioHandlingService } from './audio-handling.service';




@Injectable({ providedIn: 'root' })
export class PronunciationService {
  constructor(private audioHandlingService: AudioHandlingService) {}

  playPronunciationId(pronunciationId: string) {
    this.audioHandlingService.playPronunciationId(pronunciationId);
  }

  play(phrase: string, pronunciation: Pronunciation) {
    if (isNilOrWhiteSpace(phrase)) {
      return;
    } else {
      this.audioHandlingService.playPhrase(phrase, pronunciation);
    }
  }

  beep() {
    this.audioHandlingService.playBeep();
  }
}
