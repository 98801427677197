import { UnsafeAction } from '../model/unsafe-action';
import { type } from '../utility/type';
import { UILanguage } from './ui-language';

export const i18nActionTypes = {
  GET_INITIAL_UI_LANGUAGE: type('[i18n] Get Intitial UI Language'),
  CHANGE_UI_LANGUAGE: type('[i18n] Change UI Language')
};

export function getInitialUILanguage(): UnsafeAction {
  return { type: i18nActionTypes.GET_INITIAL_UI_LANGUAGE };
}

export function changeUILanguage(uiLanguage: UILanguage): UnsafeAction {
  return {
    type: i18nActionTypes.CHANGE_UI_LANGUAGE,
    payload: uiLanguage
  };
}
