import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { exhaustMap, filter, map, throttleTime } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { UnsafeAction } from '../../model/unsafe-action';
import { AppState } from '../../states/states-reducers';
import { MessageDisplayService } from '../ui/message-display.service';
import {
  displayErrorMessage,
  hideSpinner,
  showSpinner
} from '../ui/ui-actions';
import { ExerciseActionTypes } from './exercise-actions';
import { ExerciseItemsService } from './exercise-items.service';

@Injectable()
export class ExerciseEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private messageService: MessageDisplayService,
    private exerciseItemsService: ExerciseItemsService,
    private config: Configuration
  ) {}

  @Effect({ dispatch: false })
  startExercise = this.actions$.pipe(
    ofType(ExerciseActionTypes.START_EXERCISE),
    exhaustMap((action: UnsafeAction) => {
      this.store.dispatch(showSpinner());
      return this.exerciseItemsService.getExerciseItems(action.payload);
    }),
    map((exerciseItems) => {
      this.store.dispatch(hideSpinner());
      if (exerciseItems) {
        this.router.navigate(['problem-set/menu/exercise']);
      } else {
        this.exerciseItemsService.resetExerciseState();
        this.store.dispatch(displayErrorMessage('Load Exercise failed'));
      }
    })
  );

  @Effect({ dispatch: false })
  updateExerciseLevels = this.actions$.pipe(
    ofType(ExerciseActionTypes.UPDATE_EXERCISE_LEVELS),
    throttleTime(this.config.exerciseUpdateThrottleTimeInMs),
    exhaustMap((_: UnsafeAction) => {
      this.store.dispatch(showSpinner());
      this.router.navigate(['problem-set/menu/exercise-completed'], {
        queryParams: { syllabusId: this.exerciseItemsService.syllabusId }
      });
      return this.exerciseItemsService.updateExerciseLevels();
    }),
    filter((succeeded) => !isNil(succeeded)),
    map((succeeded) => {
      this.store.dispatch(hideSpinner());
      if (!succeeded) {
        this.messageService.displayNetworkDisconnectedMessage();
      }
    })
  );
}
