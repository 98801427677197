import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Confirmation, ConfirmationService, SelectItem } from 'primeng/api';
import { Observable, combineLatest } from 'rxjs';
import { SubSink } from 'subsink';
import { TranslationService } from '../core/translation/translation.service';
import {
  displayErrorMessage,
  displayWarningMessage
} from '../core/ui/ui-actions';
import { AppState } from '../states/states-reducers';
import { isNilOrWhiteSpace, isValidEmail } from '../utility/string-utility';
import { actionStyle } from '../utility/styles';
import { PrincipalForumService } from './principal-forum.service';

@Component({
  templateUrl: 'principal-forum-registration.component.html',
  styles: [
    `
      th,
      td {
        padding-right: 15px;
      }
    `
  ]
})
export class PrincipalForumRegistrationComponent implements OnInit, OnDestroy {

  actionStyle = actionStyle;

  name: string;
  emailAddress: string;
  repeatEmailAddress: string;
  schoolName: string;
  schoolAddress: string;
  principalText$: Observable<string>;
  supervisorText$: Observable<string>;
  principalRepresentativeText$: Observable<string>;
  positionSelected: number = 0;
  originalPosition: string;
  whatsappOrMobileNumber: string;

  positions: SelectItem[] = [];
  sub = new SubSink();

  constructor(
    translateService: TranslateService,
    private principalForumService: PrincipalForumService,
    private confirmationService: ConfirmationService,
    private store: Store<AppState>,
    private translation: TranslationService,
    private router: Router
  ) {
    this.principalText$ = translateService.stream('principal-forum.principal');
    this.supervisorText$ = translateService.stream(
      'principal-forum.supervisor'
    );

    this.principalRepresentativeText$ = translateService.stream(
      'principal-forum.principal-representative'
    );
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.sink = combineLatest([
      this.principalText$,
      this.supervisorText$,
      this.principalRepresentativeText$
    ]).subscribe((values) => {
      const positions = [];

      for (let index = 0; index < values.length; index++) {
        const element = values[index];
        positions.push({ value: index, label: element });
      }
      this.positions = positions;
    });
  }


  playInterview() {
    window.open('https://www.solidmemory.com/Video/LionsCollegeInterview2023.mp4?ngsw-bypass=true', '_blank');
  }

  onSubmitButtonClick() {
    if (this.InputIsValid) {
      const registration = {
        name: this.name,
        positionSelected: this.positionSelected,
        originalPosition: this.originalPosition,
        whatsappOrMobileNumber: this.whatsappOrMobileNumber,
        emailAddress: this.emailAddress,
        schoolName: this.schoolName
      };

      const confirmation: Confirmation = {
        header: this.translation.getTranslationInstant(
          'principal-forum.please-check-the-information-is-correct'
        ),
        message: `${this.schoolName}  ${
          this.positions[this.positionSelected].label
        } ${this.positionSelected === 2 ? this.originalPosition : ''}  ${
          this.name
        }   ${this.whatsappOrMobileNumber}  ${this.emailAddress}`,
        accept: () => {
          this.principalForumService
            .createRegistration(registration)
            .toPromise()
            .then((_) =>
              this.router.navigateByUrl('/principalForumRegistrationCompleted')
            )
            .catch((_) =>
              this.store.dispatch(
                displayErrorMessage(
                  this.translation.getTranslationInstant(
                    'principal-forum.registration-failed'
                  )
                )
              )
            );
        }
      };
      this.confirmationService.confirm(confirmation);
    }
  }

  get InputIsValid(): boolean {
    if (isNilOrWhiteSpace(this.name)) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant('error.name-required')
        )
      );
      return false;
    }

    if (isNilOrWhiteSpace(this.whatsappOrMobileNumber)) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant(
            'error.contact-number-required'
          )
        )
      );
      return false;
    }

    if (isNilOrWhiteSpace(this.emailAddress)) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant('error.email-address-required')
        )
      );
      return false;
    }

    if (!isValidEmail(this.emailAddress)) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant(
            'error.email-address-is-invalid'
          )
        )
      );
      return false;
    }

    if (isNilOrWhiteSpace(this.repeatEmailAddress)) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant(
            'error.repeat-email-address-required'
          )
        )
      );
      return false;
    }

    if (this.repeatEmailAddress !== this.emailAddress) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant(
            'error.repeat-email-address-not-the-same'
          )
        )
      );
      return false;
    }

    if (isNilOrWhiteSpace(this.schoolName)) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant('error.school-name-required')
        )
      );
      return false;
    }

    if (
      this.positionSelected === 2 &&
      isNilOrWhiteSpace(this.originalPosition)
    ) {
      this.store.dispatch(
        displayWarningMessage(
          this.translation.getTranslationInstant('error.position-required')
        )
      );
      return false;
    }

    return true;
  }
}
