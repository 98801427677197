<div class="layout-topbar shadow-4">
  <div class="layout-topbar-left">
    <span (click)="appMain.onMenuButtonClick($event)">
      <a class="layout-topbar-logo">
        <img
          id="app-logo"
          src="https://www.solidmemory.com/assets/images/logo2x.png"
          style="height: 2.25rem"
        />
      </a>

      <a
        class="layout-menu-button"
        style="
          position: absolute;
          left: 0.8em;
          border-radius: 0%;
          background-color: transparent;
        "
      >
        <i *appShowIfLoggedIn class="fas fa-user fa-2x"></i>
        <i *appShowIfLoggedOut class="fas fa-bars fa-2x"></i>
      </a>
    </span>

    <a class="layout-topbar-mobile-button">
      <a>
        <span *ngIf="(connection.isOnline$ | async) === false; then noWifi">
        </span>
        <span *ngIf="isOnHome$ | async; then cog;"></span>
        <span *ngIf="isOnHome$ | async; then globe; else goBack"></span>
      </a>
    </a>
  </div>

  <div class="layout-topbar-right">
    <div class="layout-topbar-actions-left"></div>

    <div class="layout-topbar-actions-right">
      <ul class="layout-topbar-items">
        <li class="layout-topbar-item">
          <a class="layout-topbar-action">
            <span *ngIf="(connection.isOnline$ | async) === false; then noWifi">
            </span>
          </a>
        </li>
        <li class="layout-topbar-item">
          <a class="layout-topbar-action">
            <span *ngIf="isOnHome$ | async; then cog;"></span>
          </a>
        </li>
        <li class="layout-topbar-item">
          <a class="layout-topbar-action">
            <span *ngIf="isOnHome$ | async; then globe; else goBack"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ng-template #globe>
    <span class="fa-stack" (click)="onGlobeClicked()">
      <i class="fas fa-globe-americas fa-stack-2x"></i>
    </span>
  </ng-template>

  <ng-template #cog>
    <span class="fa-stack" (click)="onSetupClicked()">
      <i class="fa fa-cog fa-stack-2x"></i>
    </span>
  </ng-template>

  <ng-template #goBack>
    <span appGoBack class="fa-stack">
      <i class="fas fa-arrow-left fa-stack-2x"></i>
    </span>
  </ng-template>

  <ng-template #noWifi>
    <span class="fa-stack">
      <i class="fas fa-wifi fa-stack-2x"></i>
      <i class="fas fa-slash fa-stack-2x"></i>
    </span>
  </ng-template>
</div>
