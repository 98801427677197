import { MenuItem } from 'primeng/api';

export const contactMenu: MenuItem = {
  label: 'contact.about-us',
  icon: 'far fa-address-card',
  routerLink: ['/contact'],
  items: [
    {
      label: 'contact.about-us',
      icon: 'far fa-address-card',
      routerLink: ['/contact']
    },
    {
      label: 'agreement.terms-of-service',
      icon: 'pi pi-bookmark',
      routerLink: ['/agreement/termsOfService']
    }
  ]
};
