import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mapTo, switchMap, tap,
  throttleTime
} from 'rxjs/operators';
import { SchoolService } from 'src/app/school/school.service';
import { SchoolUser } from '../../model/school-user';
import { UnsafeAction } from '../../model/unsafe-action';
import { AppState } from '../../states/states-reducers';
import { getPersonalSubscriptions } from '../../subscription/subscription-actions';
import { InternetConnectionService } from '../internet-connection.service';
import { TranslationService } from '../translation/translation.service';
import { MessageDisplayService } from '../ui/message-display.service';
import { displayErrorMessage, NullAction } from '../ui/ui-actions';
import { UserAuthenticationService } from '../user-authentication.service';
import { getMenteesOfCurrentUser } from './mentee-actions';
import {
  clearUserState,
  getCustomReportAttributesOfCurrentUserComplete,
  getSchoolAdminRegistrationsOfCurrentUser,
  getSchoolAdminRegistrationsOfCurrentUserComplete, getSchoolPreferencesComplete, getSchoolUsersOfCurrentUserComplete,
  getSyllabusAdminRegistrationsOfCurrentUser,
  getSyllabusAdminRegistrationsOfCurrentUserComplete,
  getUserInfoComplete,
  signout,
  UserActionTypes
} from './user-actions';
import { UserInfoService } from './user-info.service';
import { getUserPreference } from './user-preference-actions';
import { UserRightService } from './user-right.service';

@Injectable()
export class UserEffects {
  logOutConfirmation: string;
  logOutConfirmationMessage: string;

  constructor(
    private translation: TranslationService,
    private actions$: Actions,
    private store: Store<AppState>,
    private authService: UserAuthenticationService,
    private confirmationService: ConfirmationService,
    private userService: UserRightService,
    private router: Router,
    private message: MessageDisplayService,
    private connection: InternetConnectionService,
    private userInfoService: UserInfoService,
    private schoolService: SchoolService
  ) {}




  @Effect()
  getCustomReportAttributesOfCurrentUser = this.actions$.pipe(
    ofType(UserActionTypes.GET_CUSTOM_REPORT_ATTRIBUTES_OF_CURRENT_USER),
    exhaustMap((_: UnsafeAction) =>
      this.userService
        .getCustomReportAttributesOfCurrentUser()
        .pipe(catchError((_) => of(null)))
    ),
    map((dates) => getCustomReportAttributesOfCurrentUserComplete(dates))
  );

  @Effect()
  getSchoolUsersOfCurrentUser = this.actions$.pipe(
    ofType(UserActionTypes.GET_SCHOOL_USERS_OF_CURRENT_USER),
    exhaustMap((_: UnsafeAction) =>
      this.userService
        .getSchoolUsersOfCurrentUser()
        .pipe(catchError((_) => of(null)))
    ),
    map((users: SchoolUser[]) => {
      if (users) {
        return getSchoolUsersOfCurrentUserComplete(users);
      } else {
        return displayErrorMessage('Fail to get School Users Of Subject');
      }
    })
  );

    @Effect()
    getSchoolUsersOfCurrentUserComplete = this.actions$.pipe(
      ofType(UserActionTypes.GET_SCHOOL_USERS_OF_CURRENT_USER_COMPLETE),
      map((action : UnsafeAction) =>{
        const schoolUsers : SchoolUser[] = action.payload;
        return schoolUsers;}),
      map(schoolUsers => schoolUsers.map(u => this.schoolService.getSchoolPreference(u.schoolId))),
      map(values => combineLatest(values)),
      switchMap(values => values),
      catchError(_ => of([])),
      map(values => getSchoolPreferencesComplete(values))
    );


  @Effect({ dispatch: false })
  initSignout: any = this.actions$.pipe(
    ofType(UserActionTypes.INIT_SIGNOUT),
    tap((_) => {
      this.confirmLogout();
    })
  );

  @Effect({ dispatch: false })
  signin: any = this.actions$.pipe(
    ofType(UserActionTypes.SIGNIN),
    map((action: UnsafeAction) => this.authService.signin(action.payload))
  );

  @Effect()
  signout: any = this.actions$.pipe(
    ofType(UserActionTypes.SIGNOUT),
    exhaustMap((_: UnsafeAction) => this.authService.signout()),
    mapTo(clearUserState())
  );

  @Effect({ dispatch: false })
  clearUserState: any = this.actions$.pipe(
    ofType(UserActionTypes.CLEAR_USER_STATE),
    tap((_: UnsafeAction) => {
      this.authService.removeUser();
      this.authService.clearStaleState();
    })
  );

  @Effect()
  userStatusUpdated: any = this.actions$.pipe(
    ofType(UserActionTypes.UPDATE_USER_STATUS),
    throttleTime(1000),
    map((action: UnsafeAction) => {
      const user = action.payload;
      if (user) {
        this.authService.clearStaleState().then((_) => {
          this.store.dispatch(getUserPreference());
          this.store.dispatch(getPersonalSubscriptions());
          this.store.dispatch(getSchoolAdminRegistrationsOfCurrentUser());
          this.store.dispatch(getSyllabusAdminRegistrationsOfCurrentUser());
          this.store.dispatch(getMenteesOfCurrentUser());
        });
      }
      return NullAction;
    })
  );

  @Effect()
  getSchoolAdminsStatus = this.actions$.pipe(
    ofType(UserActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS_OF_CURRENT_USER),
    exhaustMap((_: UnsafeAction) =>
      this.userService
        .getSchoolAdminRegistrationsOfCurrentUser()
        .pipe(catchError((_) => of(null)))
    ),
    map((schoolIds) => {
      if (schoolIds) {
        return getSchoolAdminRegistrationsOfCurrentUserComplete(schoolIds);
      } else {
        return displayErrorMessage('Fail to get School Admins Status');
      }
    })
  );

  @Effect()
  getSyllabusAdminsStatus = this.actions$.pipe(
    ofType(UserActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS_OF_CURRENT_USER),
    exhaustMap((_: UnsafeAction) =>
      this.userService
        .getSyllabusAdminRegistrationsOfCurrentUser()
        .pipe(catchError((_) => of(null)))
    ),
    map((registrations) => {
      if (registrations) {
        return getSyllabusAdminRegistrationsOfCurrentUserComplete(
          registrations
        );
      } else {
        return displayErrorMessage('Fail to get Syllabus Admins Status');
      }
    })
  );

  @Effect()
  getUserInfoBySubject = this.actions$.pipe(
    ofType(UserActionTypes.GET_USER_INFO_BY_SUBJECT),
    exhaustMap((action: UnsafeAction) =>
      this.userInfoService
        .getUserInfoBySubject(action.payload as string)
        .pipe(catchError((_) => of(null)))
    ),
    map((userInfo) => {
      if (userInfo) {
        return getUserInfoComplete(userInfo);
      } else {
        return displayErrorMessage('Fail to get User DTO By Subject');
      }
    })
  );

  @Effect()
  getUserInfoByEmail = this.actions$.pipe(
    ofType(UserActionTypes.GET_USER_INFO_BY_EMAIL),
    exhaustMap((action: UnsafeAction) =>
      this.userInfoService
        .getUserInfoByEmail(action.payload as string)
        .pipe(catchError((_) => of(null)))
    ),
    map((userInfo) => {
      if (userInfo) {
        return getUserInfoComplete(userInfo);
      } else {
        return displayErrorMessage('Fail to get User DTO By Email');
      }
    })
  );

  @Effect({ dispatch: false })
  promptForUICode = this.actions$.pipe(
    ofType(UserActionTypes.PROMPT_FOR_UI_CULTURE),
    tap((_) => this.router.navigate(['/home/ui-culture-selection']))
  );

  confirmLogout() {
    const logOutConfirmation = this.translation.getTranslationInstant(
      'account.log-out-confirmation'
    );
    const logOutConfirmationMessage = this.translation.getTranslationInstant(
      'account.log-out-confirmation-message'
    );
    this.confirmationService.confirm({
      header: logOutConfirmation,
      message: logOutConfirmationMessage,
      accept: () => {
        if (this.connection.isOnline$) {
          this.store.dispatch(signout());
        } else {
          this.message.displayNetworkDisconnectedMessage();
        }
      }
    });
  }
}
