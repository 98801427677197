<div class="content">
  <p-panel>
    <ng-template pTemplate="header">
      <span class="text-primary font-semibold text-xl"
        >{{'vocabulary.exercise-reminder' | translate}}</span
      ></ng-template
    >
    <div *ngIf="showHardLimitReminderMessage">
      {{ hardLimitReminderMessage }}
    </div>
    <div *ngIf="showSoftLimitReminderMessage">
      {{ softLimitReminderMessage }}
    </div>
    <div>
      {{ 'vocabulary.do-exercise-now' | translate }}
    </div>
    <ng-template pTemplate="footer">
      <div class="grid col-12">
        <div class="col-4"></div>
        <div class="col-4">
          <button
            type="button"
            pButton
            icon="fas fa-times"
            label="{{ 'text.no' | translate }}"
            (click)="cancelDialog()"
          ></button>
        </div>
        <div class="col-4">
          <button
            type="button"
            pButton
            icon="fas fa-check"
            label="{{ 'text.yes' | translate }}"
            (click)="onDoExerciseButtonClick()"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-panel>
</div>
