import { Language } from 'src/app/model/language';
import { UICulture } from 'src/app/model/ui-culture';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const ThumbnailBandActionTypes = {
  GET_THUMBNAILS: type('[Thumbnail] Get Thumbnails'),
  GET_THUMBNAILS_COMPLETE: type('[Thumbnail] Get Thumbnails Complete'),
  GET_THUMBNAILS_FAIL: type('[Thumbnail] Get Thumbnails Fail'),
  CLEAR_THUMBNAILS: type('[Thumbnail] Clear Thumbnails'),
  BLAME_IMAGES: type('[Thumbnail] Blame Images'),
  BLAME_IMAGES_COMPLETE: type('[Thumbnail] Blame Images Complete'),
  RESET_IMAGES: type('[Thumbnail] Reset Images'),
  RESET_IMAGES_COMPLETE: type('[Thumbnail] Reset Images Complete'),
  ENDORSE_IMAGES: type('[Thumbnail] Endorse Images'),
  ENDORSE_IMAGES_COMPLETE: type('[Thumbnail] Endorse Images Complete')
};

export function getThumbnails(
  phrase: string,
  size: number,
  newSearch: boolean,
  disableCache: boolean,
  language: Language,
  uiCulture: UICulture
): UnsafeAction {
  return {
    type: ThumbnailBandActionTypes.GET_THUMBNAILS,
    payload: { phrase, size, newSearch, disableCache, language, uiCulture }
  };
}

export function getThumbnailsComplete(thumbnails: any[]): UnsafeAction {
  return {
    type: ThumbnailBandActionTypes.GET_THUMBNAILS_COMPLETE,
    payload: thumbnails
  };
}

export function clearThumbnails(): UnsafeAction {
  return { type: ThumbnailBandActionTypes.CLEAR_THUMBNAILS };
}

export function blameImages(ids: string[]) {
  return {
    type: ThumbnailBandActionTypes.BLAME_IMAGES,
    payload: ids
  };
}

export function blameImagesComplete(ids: string[]) {
  return {
    type: ThumbnailBandActionTypes.BLAME_IMAGES_COMPLETE,
    payload: ids
  };
}

export function resetImages(ids: string[]) {
  return {
    type: ThumbnailBandActionTypes.RESET_IMAGES,
    payload: ids
  };
}

export function resetImagesComplete(ids: string[]) {
  return {
    type: ThumbnailBandActionTypes.RESET_IMAGES_COMPLETE,
    payload: ids
  };
}

export function endorseImages(ids: string[]) {
  return {
    type: ThumbnailBandActionTypes.ENDORSE_IMAGES,
    payload: ids
  };
}

export function endorseImagesComplete(ids: string[]) {
  return {
    type: ThumbnailBandActionTypes.ENDORSE_IMAGES_COMPLETE,
    payload: ids
  };
}
