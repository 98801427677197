import { Language } from '../../model/language';
import { SyllabusAdminRegistration } from '../../model/syllabus-admin-registration';
import { UnsafeAction } from '../../model/unsafe-action';
import { Syllabus } from '../../problem-set/model/syllabus';
import { type } from '../../utility/type';

export const SyllabusActionTypes = {
  GET_SYLLABI_WITH_SYLLABUS_ADMIN_RIGHTS: type(
    '[Syllabus] Get Syllabi With Syllabus Admin Rights'
  ),
  GET_SYLLABI: type('[Syllabus] Get Syllabi'),
  GET_SYLLABI_BY_SYLLABUSIDS: type('[Syllabus] Get Syllabi With SyllabusIds'),
  GET_SYLLABI_COMPLETE: type('[Syllabus] Get Syllabi Complete'),
  CLEAR_SYLLABI: type('[Syllabus] Clear Syllabi'),
  CREATE_SYLLABUS: type('[Syllabus] Create Syllabus'),
  GET_DETAILED_SYLLABUS: type('[Syllabus] Get Detailed Syllabus'),
  GET_DETAILED_SYLLABUS_COMPLETE: type(
    '[Syllabus] Get Detailed Syllabus Complete'
  ),

  SAVE_DETAILED_SYLLABUS: type('[Syllabus] Save Syllabus'),
  SAVE_DETAILED_SYLLABUS_COMPLETE: type('[Syllabus] Save Syllabus Complete'),

  UPDATE_DETAILED_SYLLABUS_IN_STORE: type(
    '[Syllabus] Update Detailed Syllabus In Store'
  ),

  CREATE_SYLLABUS_ADMIN_REGISTRATION: type(
    '[Syllabus] Create Syllabus Admin Registration'
  ),
  CREATE_SYLLABUS_ADMIN_REGISTRATION_COMPLETE: type(
    '[Syllabus] Create Syllabus Admin Registration Complete'
  ),
  GET_SYLLABUS_ADMIN_REGISTRATIONS: type(
    '[Syllabus] Get Syllabus Admin Registrations Codes'
  ),
  GET_SYLLABUS_ADMIN_REGISTRATIONS_COMPLETE: type(
    '[Syllabus] Get Syllabus Admin Registrations Complete'
  ),
  EDIT_SYLLABUS: type('[Syllabus] Edit Syllabus'),

  CHANGE_SELECTED_SYLLABUS_LANGUAGE: type(
    '[Syllabus] Change Syllabus Language'
  ),

  CHANGE_SELECTED_SYLLABUS_LANGUAGE_BY_SYLLABUS_ID: type(
    '[Syllabus] Change Syllabus Language By Syllabus Id'
  )
};

export function changeSyllabusLanguage(language: Language) {
  return {
    type: SyllabusActionTypes.CHANGE_SELECTED_SYLLABUS_LANGUAGE,
    payload: +language
  };
}

export function changeSyllabusLanguageBySyllabusId(syllabusId: string) {
  return {
    type: SyllabusActionTypes.CHANGE_SELECTED_SYLLABUS_LANGUAGE_BY_SYLLABUS_ID,
    payload: syllabusId
  };
}

export function editSyllabus(syllabusId: string): UnsafeAction {
  return { type: SyllabusActionTypes.EDIT_SYLLABUS, payload: syllabusId };
}

export function getSyllabi(): UnsafeAction {
  return { type: SyllabusActionTypes.GET_SYLLABI };
}

export function getSyllabiWithSyllabusIds(syllabusIds: string[]): UnsafeAction {
  return {
    type: SyllabusActionTypes.GET_SYLLABI_BY_SYLLABUSIDS,
    payload: syllabusIds
  };
}

export function createSyllabusAdminRegistration(
  registration: SyllabusAdminRegistration
): UnsafeAction {
  return {
    type: SyllabusActionTypes.CREATE_SYLLABUS_ADMIN_REGISTRATION,
    payload: registration
  };
}

export function createSyllabusAdminRegistrationComplete(
  registration: SyllabusAdminRegistration
): UnsafeAction {
  return {
    type: SyllabusActionTypes.CREATE_SYLLABUS_ADMIN_REGISTRATION_COMPLETE,
    payload: registration
  };
}

export function getSyllabusAdminRegistrations(syllabusId: string) {
  return {
    type: SyllabusActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS,
    payload: syllabusId
  };
}

export function getSyllabusAdminRegistrationsComplete(
  codes: SyllabusAdminRegistration[]
) {
  return {
    type: SyllabusActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS_COMPLETE,
    payload: codes
  };
}

export function getSyllabiWithSyllabusAdminRight(
  schoolId: string
): UnsafeAction {
  return {
    type: SyllabusActionTypes.GET_SYLLABI_WITH_SYLLABUS_ADMIN_RIGHTS,
    payload: { schoolId }
  };
}

export function getSyllabiComplete(syllabi: Syllabus[]): UnsafeAction {
  return { type: SyllabusActionTypes.GET_SYLLABI_COMPLETE, payload: syllabi };
}

export function createSyllabus(syllabus: Syllabus): UnsafeAction {
  return { type: SyllabusActionTypes.CREATE_SYLLABUS, payload: syllabus };
}

export function getDetailedSyllabus(syllabusId: string): UnsafeAction {
  return {
    type: SyllabusActionTypes.GET_DETAILED_SYLLABUS,
    payload: syllabusId
  };
}

export function clearSyllabi(): UnsafeAction {
  return { type: SyllabusActionTypes.CLEAR_SYLLABI };
}

export function getDetailedSyllabusComplete(syllabus: Syllabus): UnsafeAction {
  return {
    type: SyllabusActionTypes.GET_DETAILED_SYLLABUS_COMPLETE,
    payload: syllabus
  };
}

export function updateDetailedSyllabusInStore(
  syllabus: Syllabus
): UnsafeAction {
  return {
    type: SyllabusActionTypes.UPDATE_DETAILED_SYLLABUS_IN_STORE,
    payload: syllabus
  };
}

export function saveDetailedSyllabus(): UnsafeAction {
  return { type: SyllabusActionTypes.SAVE_DETAILED_SYLLABUS };
}

export function saveDetailedSyllabusComplete(): UnsafeAction {
  return { type: SyllabusActionTypes.SAVE_DETAILED_SYLLABUS_COMPLETE };
}
