import { Level } from './level';
import { Pronunciation } from './pronunciation';

export const languagePronunciationsMap: {
  [language: number]: Pronunciation[];
} = {
  '0': [Pronunciation.BritishEnglish, Pronunciation.AmericanEnglish],
  '1': [Pronunciation.Putonghua, Pronunciation.Cantonese],
  '2': [Pronunciation.Japanese],
  '3': [Pronunciation.Korean],
  '4': [Pronunciation.EuropeanSpanish, Pronunciation.AmericanSpanish],
  '5': [Pronunciation.EuropeanFrench, Pronunciation.CanadianFrench],
  '6': [Pronunciation.German],
  '7': [Pronunciation.Afrikaans],
  '8': [Pronunciation.EuropeanPortuguese, Pronunciation.AmericanPortuguese],
  '9': [Pronunciation.BritishEnglish, Pronunciation.AmericanEnglish],
  '10': [Pronunciation.Russian],
  '11': [Pronunciation.Malay],
  '12': [Pronunciation.Indonesian],
  '13': [Pronunciation.Turkish],
  '14': [Pronunciation.Arabic],
  '15': [Pronunciation.Catalan],
  '16': [Pronunciation.Italian],
  '17': [Pronunciation.Polish],
  '18': [Pronunciation.Urdu]
};

export const defaultVocabularyPronunciationsSelected: {
  [language: number]: Pronunciation;
} = {
  '0': Pronunciation.BritishEnglish,
  '1': Pronunciation.Putonghua,
  '2': Pronunciation.Japanese,
  '3': Pronunciation.Korean,
  '4': Pronunciation.EuropeanSpanish,
  '5': Pronunciation.EuropeanFrench,
  '6': Pronunciation.German,
  '7': Pronunciation.Afrikaans,
  '8': Pronunciation.EuropeanPortuguese,
  '9': Pronunciation.AmericanEnglish,
  '10': Pronunciation.Russian,
  '11': Pronunciation.Malay,
  '12': Pronunciation.Indonesian,
  '13': Pronunciation.Turkish,
  '14': Pronunciation.Arabic,
  '15': Pronunciation.Catalan,
  '16': Pronunciation.Italian,
  '17': Pronunciation.Polish,
  '18': Pronunciation.Urdu
};

export const defaultDictionaryLevelsSelected: { [language: number]: Level } = {
  '0': Level.Advanced,
  '1': Level.Advanced,
  '2': Level.Advanced,
  '3': Level.Advanced,
  '4': Level.Advanced,
  '5': Level.Advanced,
  '6': Level.Advanced,
  '7': Level.Advanced,
  '8': Level.Advanced,
  '9': Level.Advanced,
  '10': Level.Advanced,
  '11': Level.Advanced,
  '12': Level.Advanced,
  '13': Level.Advanced,
  '14': Level.Advanced,
  '15': Level.Advanced,
  '16': Level.Advanced,
  '17': Level.Advanced,
  '18': Level.Advanced
};
