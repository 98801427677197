import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { supportedUILanguages } from '../i18n/i18n-utility';
import { UILanguage } from '../i18n/ui-language';

@Component({
  templateUrl: 'language-selection-dialog.component.html'
})
export class LanguageSelectionDialogComponent {
  supportedUILanguages = supportedUILanguages;
  constructor(private dialogRef: DynamicDialogRef) {}

  onLanguageSelected(uiLanguage: UILanguage) {
    this.dialogRef.close(uiLanguage);
  }
}
