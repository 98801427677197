import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/states/states-reducers';
import { colorThemeSelector } from '../user/user-preference-selectors';

@Injectable({ providedIn: 'root' })
export class UIService {
  topbarTheme: string = 'bluegrey';
  colorScheme: string = 'light';
  subscription : Subscription;

  constructor(store : Store<AppState>){
    this.subscription = store.select(colorThemeSelector).subscribe(this.changeColorTheme.bind(this));
  }

  changeColorTheme(colorScheme: string) {
    this.colorScheme = colorScheme;
    const themeLink: HTMLLinkElement = document.getElementById(
      'theme-css'
    ) as HTMLLinkElement;
    const themeHref = `assets/theme/${this.topbarTheme}/theme-${colorScheme}.css`;
    this.replaceLink(themeLink, themeHref);

    const layoutLink: HTMLLinkElement = document.getElementById(
      'layout-css'
    ) as HTMLLinkElement;
    const layoutHref = `assets/layout/css/layout-${colorScheme}.css`;
    this.replaceLink(layoutLink, layoutHref);
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  replaceLink(linkElement: HTMLLinkElement, href: string, callback?: Function) {
    if (this.isIE()) {
      linkElement.setAttribute('href', href);
      if (callback) {
        callback();
      }
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement: any = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', id);

        if (callback) {
          callback();
        }
      });
    }
  }
}
