import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SchoolLandSchool } from '../erp/schoolland-school';
import { field } from '../shared/field';

@Component({
  selector: 'app-schoolland-school-display',
  templateUrl: './schoolland-school-display.component.html'
})
export class SchoolLandSchoolDisplayComponent {
  @Input() schoolLandSchool: SchoolLandSchool;

  @Output() fieldClick = new EventEmitter<field>();

  onFieldClick(field: field) {
    this.fieldClick.emit(field);
  }
}
