import { createSelector } from '@ngrx/store';
import { AppState } from '../states/states-reducers';

const i18nStateSelector = (state: AppState) => state.i18n;

export const uiCultureSelector = createSelector(
  i18nStateSelector,
  (state) => state.uiLanguageSelected.uiCulture
);

export const ianaCodeSelector = createSelector(
  i18nStateSelector,
  (state) => state.uiLanguageSelected.ianaCode
);
