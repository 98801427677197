import { createSelector } from '@ngrx/store';
import { AppState } from '../states/states-reducers';

const notificationStateSelector = (state: AppState) => state.notification;

export const notificationsSelector = createSelector(
  notificationStateSelector,
  (state) => state.notifications
);

export const notificationToDisplaySelector = createSelector(
  notificationStateSelector,
  (state) => state.notificationToDisplay
);
