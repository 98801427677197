import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { User, UserManager } from 'oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ianaCodeSelector } from '../i18n/i18n-state-selectors';
import { AppState } from '../states/states-reducers';
import { LoggingService } from './log/logging.service';
import { clearUserState, updateUserStatus } from './user/user-actions';

@Injectable({ providedIn: 'root' })
export class UserAuthenticationService {
  private userSubject = new BehaviorSubject<User>(undefined);
  private subs = new SubSink();
  private ianaCode: string;

  user$: Observable<User>;

  constructor(
    private store: Store<AppState>,
    private logger: LoggingService,
    private userManager: UserManager
  ) {
    this.user$ = this.userSubject.asObservable();

    this.setupUserManagerEventsSubscription();

    this.setupSentryUserSubscription();

    this.setupIanaCodeSubscription();

    this.userManager.getUser().then((user) => {
      if (user && !user.expired) {
        this.updateUserStatus(user);
      } else {
        this.updateUserStatus(undefined);
      }
    });
  }

  updateUserStatus(user: User) {
    this.userSubject.next(user);
    this.store.dispatch(updateUserStatus(user));
  }

  get user(): User {
    return this.userSubject.value;
  }

  get sub(): string {
    if (this.user) {
      return this.user.profile.sub;
    } else {
      return undefined;
    }
  }

  get userName(): string {
    if (this.user) {
      return this.user.profile.preferred_username;
    } else {
      return undefined;
    }
  }

  signout(): Promise<any> {
    return this.signoutRedirect(this.user).finally(() => {
      this.store.dispatch(updateUserStatus(undefined));
      this.store.dispatch(clearUserState());
    });
  }

  signoutRedirectCallback(): Promise<any> {
    return this.userManager.signoutRedirectCallback().finally(() => {
      this.store.dispatch(updateUserStatus(undefined));
      this.store.dispatch(clearUserState());
    });
  }

  signin(args?: any): Promise<any> {
    args = { extraQueryParams: { culture: this.ianaCode } };
    return this.signinRedirect(args);
  }

  signinRedirectCallback(): Promise<User> {
    return this.userManager.signinRedirectCallback();
  }

  clearStaleState(): Promise<void> {
    return this.userManager.clearStaleState();
  }

  removeUser(): Promise<void> {
    return this.userManager.removeUser();
  }

  private signoutRedirect(user: User): Promise<any> {
    if (user) {
      return this.userManager.signoutRedirect({ id_token_hint: user.id_token });
    } else {
      return Promise.resolve();
    }
  }

  // private signoutPopup(): Promise<any> {
  //   return this.userManager.signoutPopup();
  // }

  // private signinPopup(): Promise<User> {
  //   return this.userManager.signinPopup();
  // }

  private signinRedirect(args?: any): Promise<any> {
    return this.userManager.signinRedirect(args);
  }

  private convertToSentryUser(user: User): Sentry.User {
    return {
      id: user.profile.sub
    };
  }

  private setupUserManagerEventsSubscription() {
    const events = this.userManager.events;
    events.addUserLoaded((user) => {
      this.logger.info('user loaded', user);
      this.updateUserStatus(user);
    });
    events.addUserUnloaded(() => {
      this.logger.info('user unloaded');
      this.updateUserStatus(undefined);
    });
    events.addAccessTokenExpiring((event: any) => {
      this.logger.info('access token expiring: ', event);
    });
    events.addAccessTokenExpired((event: any) => {
      this.logger.info('access token expired: ', event);
    });
    events.addSilentRenewError((event: any) => {
      this.logger.info('silent renew error:', event);
    });
  }

  private setupIanaCodeSubscription() {
    this.subs.sink = this.store
      .select(ianaCodeSelector)
      .subscribe((value) => (this.ianaCode = value));
  }

  private setupSentryUserSubscription() {
    this.subs.sink = this.user$.subscribe((user) => {
      if (user) {
        const sentryUser = this.convertToSentryUser(user);
        Sentry.setUser(sentryUser);
      } else {
        Sentry.setUser(null);
      }
    });
  }
}
