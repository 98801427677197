import * as iassign from 'immutable-assign';
import { UnsafeAction } from '../model/unsafe-action';
import { i18nActionTypes } from './i18n-actions';
import { supportedUILanguages } from './i18n-utility';
import { UILanguage } from './ui-language';

export interface I18NState {
  uiLanguageSelected: UILanguage;
}

export const INITIAL_I18N_STATE: I18NState = {
  uiLanguageSelected: supportedUILanguages[0]
};

export function i18nStateReducer(
  state: I18NState = INITIAL_I18N_STATE,
  { type, payload }: UnsafeAction
): I18NState {
  switch (type) {
    case i18nActionTypes.CHANGE_UI_LANGUAGE:
      return iassign(state, (s) => {
        s.uiLanguageSelected = payload;
        return s;
      });
    default:
      return state;
  }
}
