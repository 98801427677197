import { Component } from '@angular/core';

@Component({
  selector: 'app-faq-button',
  template: `
    <app-material-button
      buttonClass="p-button-success"
      [routerLink]="['/faq/faqs']"
      icon="fas fa-question-circle"
      text="{{ 'faq.faq' | translate }}"
    ></app-material-button>
  `
})
export class FaqButtonComponent {}
