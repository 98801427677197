import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as iassign from 'immutable-assign';
import { cloneDeep, cloneDeepWith, isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { Contact } from '../erp/contact';
import { EdbSchool } from '../erp/edb-school';
import {
  getEdbSchool,
  getSchoolLandSchool,
  upsertErpSchool
} from '../erp/erp-actions';
import { ErpSchool } from '../erp/erp-school';
import {
  edbSchoolSelector,
  schoolLandSchoolSelector
} from '../erp/erp-state-selectors';
import { SchoolLandSchool } from '../erp/schoolland-school';
import { FieldsEditComponent } from '../shared/fields-edit.component';
import { AppState } from '../states/states-reducers';

@Component({
  selector: 'app-erp-school-edit',
  templateUrl: 'erp-school-edit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ErpSchoolEditComponent)
    }
  ]
})
export class ErpSchoolEditComponent implements ControlValueAccessor, OnInit {
  erpSchool: ErpSchool;
  originalErpSchool: ErpSchool;
  schoolLandSchool$: Observable<SchoolLandSchool>;
  edbSchool$: Observable<EdbSchool>;
  @ViewChild(FieldsEditComponent, { static: false })
  fieldsEdit: FieldsEditComponent;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.schoolLandSchool$ = this.store.select(schoolLandSchoolSelector);
    this.edbSchool$ = this.store.select(edbSchoolSelector);
  }

  registerOnChange(fn: (_: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(value: any): void {
    if (value) {
      if (!this.originalErpSchool) {
        this.originalErpSchool = cloneDeep(value);
      }
      this.setErpSchool(value);
    }
  }

  setErpSchool(value: ErpSchool) {
    this.erpSchool = cloneDeepWith(value);
    if (value.schoolLandUrl) {
      this.store.dispatch(getSchoolLandSchool(value.schoolLandUrl));
    }
    if (value.edbUrl) {
      this.store.dispatch(getEdbSchool(value.edbUrl));
    }
    this.onChange(this.erpSchool);
  }

  onFieldClick(field: any) {
    this.fieldsEdit.setField(field);
  }

  onSavedButtonClick() {
    if (this.erpSchoolUpdated) {
      const updated = iassign(this.erpSchool, (school) => {
        school.lastUpdated = new Date();
        return school;
      });
      this.erpSchool = updated;
      this.onChange(updated);
      this.store.dispatch(upsertErpSchool(updated));
    }
  }

  onContactsChange(contacts: Contact[]) {
    this.erpSchool = iassign(this.erpSchool, (school) => {
      school.contacts = contacts;
      return school;
    });
    this.onChange(this.erpSchool);
  }

  onResetBButtonClick() {
    this.setErpSchool(cloneDeep(this.originalErpSchool));
  }

  get schoolName(): string {
    let schoolName = this.erpSchool.schoolNameInChinese;
    if (!schoolName) {
      schoolName = this.erpSchool.schoolNameInEnglish;
    } else if (this.erpSchool.schoolAddressInEnglish) {
      schoolName = schoolName + ' / ' + this.erpSchool.schoolNameInEnglish;
    }
    return schoolName;
  }

  get erpSchoolUpdated(): boolean {
    return !isEqual(this.erpSchool, this.originalErpSchool);
  }
}
