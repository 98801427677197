<div class="col-12 mt-5">
  <h4
    innerHTML="{{ 'home.for-safari-only' | translate }}"
  ></h4>
</div>

<div class="col-12 mb-5">
  <h5
    style="line-height: 2;"
    innerHTML="{{ 'home.add-to-home-screen-iOS-instruction' | translate }}"
  ></h5>
</div>

