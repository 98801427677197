import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep, isEqual } from 'lodash-es';
import { SelectItem } from 'primeng/api';
import { Contact } from '../erp/contact';
import { HistoryItem } from '../erp/history-item';
import { JobTitle } from '../erp/job-title';
import { ResponsibilityArea } from '../erp/responsibility-area';
import { getSelectItemsFromEnum } from '../utility/enum-utility';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContactEditComponent)
    }
  ]
})
export class ContactEditComponent implements ControlValueAccessor, OnInit {
  contact: Contact;
  originalContact: Contact;

  jobTitleSelectItems: SelectItem[];
  responsibilityAreaSelectItems: SelectItem[];

  registerOnChange(fn: (_: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(value: Contact): void {
    if (!this.originalContact) {
      this.originalContact = cloneDeep(value);
    }
    this.contact = value;
    this.onChange(value);
  }

  ngOnInit() {
    this.jobTitleSelectItems = getSelectItemsFromEnum(JobTitle);
    this.responsibilityAreaSelectItems =
      getSelectItemsFromEnum(ResponsibilityArea);
  }

  onContactChange() {
    if (this.contactUpdated) {
      this.contact.lastUpdated = new Date();
    }
    this.onChange(this.contact);
  }

  get contactUpdated(): boolean {
    return !isEqual(this.contact, this.originalContact);
  }

  onContactHistoryChange(contactHistory: HistoryItem[]) {
    this.contact.contactHistory = contactHistory;
    this.onChange(this.contact);
  }
}
