import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { displayErrorMessage } from '../ui/ui-actions';
import {
    getMenteesOfCurrentUserComplete,
    MenteeActionTypes
} from './mentee-actions';
import { MenteeService } from './mentee-service';

@Injectable()
export class MenteeEffects {
  constructor(
    private actions$: Actions,
    private menteeService: MenteeService
  ) {}

  @Effect()
  getMenteesOfCurrentUser = this.actions$.pipe(
    ofType(MenteeActionTypes.GET_MENTEES_OF_CURRENT_USER),
    exhaustMap((_) =>
      this.menteeService
        .getMenteesOfCurrentUser()
        .pipe(catchError((_) => of(null)))
    ),
    map((mentees) => {
      if (mentees) {
        return getMenteesOfCurrentUserComplete(mentees);
      } else {
        return displayErrorMessage('Fail to get Mentees');
      }
    })
  );
}
