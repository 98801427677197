import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { notificationToDisplaySelector } from '../account/notification-state-selector';
import { AppComponent } from '../app.component';
import { Configuration } from '../configuration';
import { dismissNotification } from '../core/notification/notification-actions';
import { hideLocalStorageWarning } from '../core/ui/ui-actions';
import {
  showLocalStorageSelector,
  showSpinnerSelector,
  showTopBarSelector
} from '../core/ui/ui-state-selectors';
import { UIService } from '../core/ui/ui.service';
import { AppState } from '../states/states-reducers';
import { MenuService } from './app.menu.service';
@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html'
})
export class AppMainComponent implements AfterViewInit, OnInit, OnDestroy {
  lasturl: string;
  private subs = new SubSink();

  topbarMenuActive: boolean;

  menuActive: boolean;

  staticMenuDesktopInactive: boolean;

  mobileMenuActive: boolean;

  menuClick: boolean;

  topbarRightClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: string;

  documentClickListener: () => void;

  configActive: boolean;

  configClick: boolean;

  rightMenuActive: boolean;

  menuHoverActive = false;

  currentInlineMenuKey: string;

  inlineMenuActive: any[] = [];

  inlineMenuClick: boolean;

  displayNotification: boolean;

  notificationToDisplay: any;

  showTopBar = true;

  showSpinner: boolean;

  displayLocalStorageWarning = false;

  constructor(
    public renderer: Renderer2,
    private menuService: MenuService,
    public app: AppComponent,
    private store: Store<AppState>,
    private config: Configuration,
    public ui: UIService
  ) {}

  get showEmergencyMaintenace(): boolean {
    return this.config.showEmergencyMaintenance;
  }

  confirmationDialogBreakpoints = {
    '1728px': '45vw',
    '1555px': '50vw',
    '1400px': '55vw',
    '1300px': '60vw',
    '1133px': '65vw',
    '1020px': '70vw',
    '918px': '75vw',
    '826px': '80vw',
    '743px': '89vw',
    '669px': '90vw',
    '603px': '95vw'
  };

  onNotificationHide() {
    this.store.dispatch(dismissNotification(this.notificationToDisplay));
  }

  ngOnInit() {
    this.menuActive = this.isStatic() && !this.isMobile();

    this.subs.sink = this.store
      .select(showSpinnerSelector)
      .subscribe((value) => {
        setTimeout(() => {
          this.showSpinner = value;
        });
      });

    this.subs.sink = this.store
      .select(showTopBarSelector)
      .pipe(
        map((value) => {
          if (environment.envName === 'development') {
            return value;
          } else{
            return value || this.isDesktop() || this.mobileMenuActive;
          }
        })
      )
      .subscribe((value) => {
        setTimeout(() => {
          this.showTopBar = value;
        });
      });

    this.subs.sink = this.store
      .select(notificationToDisplaySelector)
      .subscribe((value) => {
        if (value) {
          this.notificationToDisplay = value;
          this.displayNotification = true;
        } else {
          this.displayNotification = false;
        }
      });

      this.subs.sink = this.store
      .select(showLocalStorageSelector)
      .subscribe(value => this.displayLocalStorageWarning = value);
  }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', () => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
        this.menuService.reset();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      if (!this.menuClick) {
        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.menuHoverActive = false;
        this.unblockBodyScroll();
      }

      if (
        this.inlineMenuActive[this.currentInlineMenuKey] &&
        !this.inlineMenuClick
      ) {
        this.inlineMenuActive[this.currentInlineMenuKey] = false;
      }

      this.inlineMenuClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.topbarRightClick = false;
      this.menuClick = false;
    });
  }

  onMenuButtonClick(event: any) {
    this.menuActive = !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
      if (this.mobileMenuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onRightMenuButtonClick(event: MouseEvent) {
    this.rightMenuActive = !this.rightMenuActive;
    event.preventDefault();
  }

  onMenuClick(_: any) {
    this.menuClick = true;

    if (
      this.inlineMenuActive[this.currentInlineMenuKey] &&
      !this.inlineMenuClick
    ) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }
  }

  onTopbarItemClick(event: any, item: any) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }
    event.preventDefault();
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isStatic() {
    return this.app.menuMode === 'static';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }

  isSlim() {
    return this.app.menuMode === 'slim';
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
    this.subs.unsubscribe();
  }

  onLocalStorageWarningHide() {
    this.store.dispatch(hideLocalStorageWarning());
  }
}
