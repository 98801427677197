import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConnectionService } from './connection.service';
import { MessageDisplayService } from './ui/message-display.service';

const defaultInitialOnlineState = true;
@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {
  private isOnlineSubject = new BehaviorSubject(defaultInitialOnlineState);
  private onceConnected: boolean;
  private lastConnectionState: boolean;

  constructor(
    private service: ConnectionService,
    private message: MessageDisplayService
  ) {
    this.init();
  }

  get isOnline$() {
    return this.isOnlineSubject.asObservable();
  }

  get isOnline() {
    return this.isOnlineSubject.getValue();
  }

  private init() {
    this.service.monitor(false).subscribe((status) => {
      const isOnline = status.hasNetworkConnection && status.hasInternetAccess;
      this.isOnlineSubject.next(isOnline);
      if (this.lastConnectionState !== isOnline) {
        if (isOnline) {
          if (this.onceConnected) {
            this.displayNetworkConnectedMessage();
          }
          this.onceConnected = true;
        } else {
          this.displayNetworkDisconnectedMessage();
        }
      }
      this.lastConnectionState = isOnline;
    });
  }

  private displayNetworkConnectedMessage() {
    this.message.displayNetworkConnectedMessage();
  }

  private displayNetworkDisconnectedMessage() {
    this.message.displayNetworkDisconnectedMessage();
  }
}
