import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { AppState } from '../states/states-reducers';
import { Exception } from './exception/exception';
import { ExceptionType } from './exception/exception-type';
import { signout } from './user/user-actions';

@Injectable({ providedIn: 'root' })
export class HttpClientService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  authRequest<T>(
    method: string,
    url: string,
    options: {
      body?: any;
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      reportProgress?: boolean;
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<any> {
    return this.http
      .request<T>(method, url, { observe: 'response', ...options })
      .pipe(catchError((error) => this.handleError(error)));
  }

  authGet<T>(url: string, options?: {}): Observable<T> {
    return this.http
      .get<T>(url, options)
      .pipe(catchError((error) => this.handleError(error)));
  }

  authPut<T>(url: string, body: any, options?: {}): Observable<T> {
    return this.http
      .put<T>(url, body, options)
      .pipe(catchError((error) => this.handleError(error)));
  }

  authDelete<T>(url: string, options?: {}): Observable<T> {
    return this.http
      .delete<T>(url, options)
      .pipe(catchError((error) => this.handleError(error)));
  }

  authPost<T>(url: string, body: any, options?: {}): Observable<T> {
    return this.http
      .post<T>(url, body, options)
      .pipe(catchError((error) => this.handleError(error)));
  }

  authPatch<T>(url: string, body: any, options?: {}): Observable<T> {
    return this.http
      .patch<T>(url, body, options)
      .pipe(catchError((error) => this.handleError(error)));
  }

  authOptions<T>(url: string, options?:{}) : Observable<T>{
    return this.http
    .options<T>(url, options)
    .pipe(catchError((error) => this.handleError(error)));
  }

  handleError(error: any): Observable<any> {
    const exception = Exception.create(error);
    switch (exception.type) {
      case ExceptionType.Unauthorized:
        this.store.dispatch(signout());
        break;
      default:
        break;
    }
    throw error;
  }

  canGetUrl(url: string): Observable<boolean> {
    return this.http.get(url, { responseType: 'blob' as 'json' }).pipe(
      mapTo(true),
      catchError((_) => of(false))
    );
  }
}
