import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-material-button',
  templateUrl: './material-button.component.html'
})
export class MaterialButtonComponent {
  _customStyle: {};

  @Input() text: string;
  @Input() icon: string;
  @Input()
  set buttonStyle(style: {}) {
    this._customStyle = style;
  }

  get buttonStyle(): {} {
    let baseStyle = { height: '55px' };
    if (this.horizontal) {
      baseStyle = { height: '40px' };
    }
    return { ...baseStyle, ...this._customStyle };
  }

  @Input() buttonClass: string;
  @Input() horizontal: boolean;

  @Input() disabled: boolean;
}
