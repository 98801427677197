import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { find } from 'lodash-es';
import memo from 'memo-decorator';
import { first, map, Observable } from 'rxjs';
import { Language } from '../model/language';
import { getDefaultLanguage } from '../model/ui-culture';
import { supportedUILanguages } from './i18n-utility';

@Injectable({ providedIn: 'root' })
export class I18nService {
  constructor(
    private translateService: TranslateService,
    private loader: TranslateLoader
  ) {
    this.initialized();
  }

  initialized() {
    const availableTranslations = supportedUILanguages.map(
      (lang) => lang.ianaCode
    );
    this.translateService.addLangs(availableTranslations);
    availableTranslations.map((trans) => this.loader.getTranslation(trans));
    this.translateService.setDefaultLang(availableTranslations[0]);
  }

  @memo({
    resolver: (...args: any[]) => {
      const key = args[1] + '__' + args[0];
      return key;
    }
  })
  translate(key: string, lang: Language): Observable<string> {
    const lana = this.getIanaCode(lang);
    return this.translateService.getTranslation(lana).pipe(
      first(),
      map((values) => values[key])
    );
  }

  use(lanaCode: string) {
    this.translateService.use(lanaCode);
  }

  @memo()
  getIanaCode(language: Language): string {
    if (language === Language.Chinese) {
      if (
        this.translateService.currentLang &&
        this.translateService.currentLang.startsWith('zh')
      ) {
        return this.translateService.currentLang;
      } else {
        return 'zh-Hans';
      }
    }

    const uiLanguage = find(
      supportedUILanguages,
      (i) => getDefaultLanguage(i.uiCulture) === language
    );

    if (uiLanguage) {
      return uiLanguage.ianaCode;
    } else {
      return 'en';
    }
  }
}
