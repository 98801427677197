import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIModule } from 'primeng/blockui';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DisplayModule } from '../shared/display/display.module';
import { NavigationModule } from '../shared/navigation/navigation.module';
import { SharedModule } from '../shared/shared.module';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { MenuService } from './app.menu.service';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';


@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    MenuModule,
    ConfirmDialogModule,
    DialogModule,
    NavigationModule,
    DisplayModule,
    ProgressSpinnerModule,
    BlockUIModule,
    TranslateModule.forChild()
  ],
  declarations: [
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent
  ],
  exports: [AppMainComponent],
  providers: [MenuService]
})
export class ShellModule {}
