import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { actionStyle } from '../../utility/styles';

@Component({
  selector: 'app-service-upgrade-button',
  templateUrl: 'service-upgrade-button.component.html'
})
export class ServiceUpgradeButtonComponent {
  constructor(private router: Router) {}

  actionStyle = actionStyle;

  onClick() {
    this.router.navigate(['/purchase/home']);
  }
}
