import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  INITIAL_NOTIFICATION_STATE,
  NotificationState,
  notificationStateReducer
} from '../core/notification/notification-state-reducer';
import {
  INITIAL_PROBLEM_SET_SETTING_STATE,
  problemSetSettingsStateReducer,
  ProblemSetSettingState
} from '../core/syllabus/problem-set-setting-state-reducer';
import {
  INITIAL_MENTEE_STATE,
  MenteeState,
  menteeStateReducer
} from '../core/user/mentee-state-reducer';
import {
  INITIAL_USER_PREFERENCE_STATE,
  UserPreferenceState,
  userPreferenceStateReducer
} from '../core/user/user-preference-state-reducer';
import {
  INITIAL_VOCAB_CORE_STATE,
  VocabCoreState,
  vocabCoreStateReducer
} from '../core/vocabulary/vocab-core-state-reducer';
import {
  AccountState,
  accountStateReducer,
  INITIAL_ACCOUNT_STATE
} from '../account/account-state-reducer';
import {
  INITIAL_SYLLABUS_STATE,
  SyllabusState,
  syllabusStateReducer
} from '../core/syllabus/syllabus-state-reducer';
import {
  INITIAL_UI_STATE,
  UiState,
  uiStateReducer
} from '../core/ui/ui-state-reducer';
import {
  INITIAL_USER_STATE,
  UserState,
  userStateReducer
} from '../core/user/user-state-reducer';
import {
  I18NState,
  i18nStateReducer,
  INITIAL_I18N_STATE
} from '../i18n/i18n-state-reducer';
import {
  INITIAL_QUESTION_STATE,
  QuestionState,
  questionStateReducer
} from '../problem-set/question/question-state-reducer';
import {
  INITIAL_REPORTING_STATE,
  ReportingState,
  reportingStateReducer
} from '../reporting/reporting-state-reducer';
import {
  INITIAL_SCHOOL_STATE,
  SchoolState,
  schoolStateReducer
} from '../school/school-state-reducer';
import {
  DictionaryState,
  dictionaryStateReducer,
  INITIAL_DICTIONARY_STATE
} from '../smart-ui/dictionary/dictionary-state-reducer';
import {
  INITIAL_THUMBNAILBAND_STATE,
  ThumbnailBandState,
  thumbnailBandStateReducer
} from '../smart-ui/thumbnail/thumbnail-band-state-reducer';
import {
  INITIAL_SUBSCRIPTION_STATE,
  SubscriptionState,
  subscriptionStateReducer
} from '../subscription/subscription-state-reducer';
import {
  INITIAL_SYSTEM_STATE,
  SystemState,
  systemStateReducer
} from '../system/system-state-reducer';

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  ui: uiStateReducer,
  user: userStateReducer,
  subscription: subscriptionStateReducer,
  dictionary: dictionaryStateReducer,
  thumbnailBand: thumbnailBandStateReducer,
  account: accountStateReducer,
  system: systemStateReducer,
  school: schoolStateReducer,
  syllabus: syllabusStateReducer,
  question: questionStateReducer,
  reporting: reportingStateReducer,
  i18n: i18nStateReducer,
  notification: notificationStateReducer,
  mentee: menteeStateReducer,
  userPreference: userPreferenceStateReducer,
  problemSetSetting: problemSetSettingsStateReducer,
  vocabCore: vocabCoreStateReducer
};

export interface AppState {
  router: RouterReducerState;
  ui: UiState;
  user: UserState;
  subscription: SubscriptionState;
  dictionary: DictionaryState;
  thumbnailBand: ThumbnailBandState;
  account: AccountState;
  system: SystemState;
  school: SchoolState;
  syllabus: SyllabusState;
  question: QuestionState;
  reporting: ReportingState;
  i18n: I18NState;
  notification: NotificationState;
  mentee: MenteeState;
  userPreference: UserPreferenceState;
  problemSetSetting: ProblemSetSettingState;
  vocabCore: VocabCoreState;
}

export const INITIAL_STATE: AppState = {
  router: undefined,
  ui: INITIAL_UI_STATE,
  user: INITIAL_USER_STATE,
  subscription: INITIAL_SUBSCRIPTION_STATE,
  dictionary: INITIAL_DICTIONARY_STATE,
  thumbnailBand: INITIAL_THUMBNAILBAND_STATE,
  account: INITIAL_ACCOUNT_STATE,
  system: INITIAL_SYSTEM_STATE,
  school: INITIAL_SCHOOL_STATE,
  syllabus: INITIAL_SYLLABUS_STATE,
  question: INITIAL_QUESTION_STATE,
  reporting: INITIAL_REPORTING_STATE,
  i18n: INITIAL_I18N_STATE,
  notification: INITIAL_NOTIFICATION_STATE,
  mentee: INITIAL_MENTEE_STATE,
  userPreference: INITIAL_USER_PREFERENCE_STATE,
  problemSetSetting: INITIAL_PROBLEM_SET_SETTING_STATE,
  vocabCore: INITIAL_VOCAB_CORE_STATE
};

export const metaReducers: MetaReducer<AppState>[] = [];
