import { UnsafeAction } from '../../model/unsafe-action';
import { ProblemSetSetting } from '../../problem-set/model/problem-set-setting';
import { type } from '../../utility/type';

export const ProblemSetSettingActionTypes = {
  GET_PROBLEM_SET_SETTING: type(
    '[Problem Set Settings] Get problem set config'
  ),
  GET_PROBLEM_SET_SETTING_COMPLETE: type(
    '[Problem Set Settings] Get problem set config complete'
  ),
  GET_PROBLEM_SET_SETTINGS: type(
    '[Problem Set Settings] Get problem set settings'
  ),
  GET_PROBLEM_SET_SETTINGS_COMPLETE: type(
    '[Problem Set Settings] Get problem set settings complete'
  ),
  UPDATE_PROBLEM_SET_SETTING: type('[Problem Set Settings] Update Problem Set')
};

export function getProblemSetSettings(): UnsafeAction {
  return { type: ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTINGS };
}

export function getProblemSetSettingsComplete(
  problemSetSettings: ProblemSetSetting[]
): UnsafeAction {
  return {
    type: ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTINGS_COMPLETE,
    payload: problemSetSettings
  };
}

export function getProblemSetSetting(syllabusId: string): UnsafeAction {
  return {
    type: ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTING,
    payload: syllabusId
  };
}

export function getProblemSetSettingComplete(
  problemSetSetting: ProblemSetSetting
): UnsafeAction {
  return {
    type: ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTING_COMPLETE,
    payload: problemSetSetting
  };
}

export function updateProblemSetSetting(
  problemSetSetting: ProblemSetSetting,
  schoolId: string
): UnsafeAction {
  return {
    type: ProblemSetSettingActionTypes.UPDATE_PROBLEM_SET_SETTING,
    payload: {problemSetSetting, schoolId}
  };
}
