import { InAppNotification } from '../../model/in-app-notification';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const NotificationActionTypes = {
  FETCH_AND_DISPLAY_NEW_NOTIFICATIONS: type(
    '[Notification] Fetch and Display new Notifications'
  ),
  NEW_NOTIFICATIONS_FETCHED: type('[Notification] New Notifications Fetched'),
  DISPLAY_NEW_NOTIFICATIONS: type('[Notification] Display New Notifications'),
  DISPLAY_NOTIFICATIONS_IN_LOCAL_STORAGE: type(
    '[Notification] Load Notifications in local storage'
  ),
  LOAD_NOTIFICATIONS_IN_LOCAL_STORAGE_COMPLETE: type(
    '[Notification] Load Notifications in local storage complete'
  ),
  DELETE_NOTIFICATION_IN_LOCAL_STORAGE: type(
    '[Notification] Delete Notification in local storage'
  ),
  DISPLAY_NOTIFICATION: type('[Notification] Display Notification'),
  DISMISS_NOTIFICATION: type('[Notification] Dismiss Notification')
};

export function displayNotificationsInLocalStorage(): UnsafeAction {
  return {
    type: NotificationActionTypes.DISPLAY_NOTIFICATIONS_IN_LOCAL_STORAGE
  };
}

export function loadNotificationsInLocalStorageComplete(
  notifications: InAppNotification[]
): UnsafeAction {
  return {
    type: NotificationActionTypes.LOAD_NOTIFICATIONS_IN_LOCAL_STORAGE_COMPLETE,
    payload: notifications
  };
}

export function deleteNotificationInLocalStorage(
  notification: InAppNotification
) {
  return {
    type: NotificationActionTypes.DELETE_NOTIFICATION_IN_LOCAL_STORAGE,
    payload: notification
  };
}

export function fetchAndDisplayNewNotifications(): UnsafeAction {
  return {
    type: NotificationActionTypes.FETCH_AND_DISPLAY_NEW_NOTIFICATIONS
  };
}

export function newNotificationsFetched(notifications: InAppNotification[]) {
  return {
    type: NotificationActionTypes.NEW_NOTIFICATIONS_FETCHED,
    payload: notifications
  };
}

export function displayNewNotifications() {
  return {
    type: NotificationActionTypes.DISPLAY_NEW_NOTIFICATIONS
  };
}

export function displayNotification(notification: InAppNotification) {
  return {
    type: NotificationActionTypes.DISPLAY_NOTIFICATION,
    payload: notification
  };
}

export function dismissNotification(notification: InAppNotification) {
  return {
    type: NotificationActionTypes.DISMISS_NOTIFICATION,
    payload: notification
  };
}
