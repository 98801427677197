/* eslint-disable no-debugger */
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { some } from 'lodash-es';
import { isDebuggingMessage } from './app/configuration';
import { isStringAndContains } from './app/utility/string-utility';
import { environment } from './environments/environment';
import {
  shouldNotLogEventWithExceptionAnyValuesValueContainingSubstrings,
  shouldNotLogExceptionTypes
} from './sentry-constants';

const isProduction = environment.envName === 'production';

function isNonErrorExceptionOrHint(
  event: Sentry.Event,
  hint: Sentry.EventHint
): boolean {
  const result =
    event?.exception?.values[0]?.value?.startsWith(
      'Non-Error exception captured'
    ) ||
    hint?.originalException?.toString()?.startsWith(
      'Non-Error exception captured'
    );
  return result;
}

export function sentryInit() {
  Sentry.init({
    dsn: environment.sentryDsnp1,
    release: environment.version,
    environment: environment.envName,
    beforeSend: (event: Sentry.Event, hint: Sentry.EventHint) => {
      if(isDebuggingMessage){
        debugger;
      }
      const shouldLogEvent = shouldLog(event);
      const isNonError = isNonErrorExceptionOrHint(event, hint);

      if (isProduction && shouldLogEvent && !isNonError) {
        return event;
      } else {
        if (isNonError) {
          console.info('sentryInit', 'IsNonErrorException');
        }
        if (shouldLogEvent) {
          console.info('sentryInit', 'shouldLogEvent');
        }
        console.info('sentryInit', event, hint);
        return null;
      }
    },
    attachStacktrace: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'solidmemory.com',
          'api.solidmemory.com',
          /^\//
        ],
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      new Sentry.Integrations.TryCatch({
        eventTarget: false,
       })
    ],
    sampleRate: environment.sentrySampleRate,
    tracesSampleRate: environment.sentryTracesSampleRate,
  });
}

function shouldLog(event: Sentry.Event): boolean {
  return !containsAnyShouldNotLogExceptionValuesOrTypes(event);
}

function containsAnyShouldNotLogExceptionValuesOrTypes(
  event: Sentry.Event
): boolean {
  if (event && event.exception && event.exception.values) {
    const exceptionValues : any[] = event.exception.values;
    return some(exceptionValues, (value) => {
      return (
        some(
          shouldNotLogEventWithExceptionAnyValuesValueContainingSubstrings,
          (shouldNotLogExceptionValue) =>
            isStringAndContains(value.value, shouldNotLogExceptionValue)
        ) ||
        some(shouldNotLogExceptionTypes, (shouldNotLogExceptionType) =>
          isStringAndContains(value.type, shouldNotLogExceptionType)
        )
      );
    });
  } else {
    return false;
  }
}
