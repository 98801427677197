import { Filter } from '../model/filter';
import { MentorMentee } from '../model/mentor-mentee';
import { PushNotification } from '../model/push-notification';
import { School } from '../model/school';
import { SchoolAdminRegistration } from '../model/school-admin-registration';
import { SchoolMembershipRenewal } from '../model/school-membership-renewal';
import { SchoolUser } from '../model/school-user';
import { UnsafeAction } from '../model/unsafe-action';
import { ExerciseReportSubscription } from '../reporting/exercise-report-subscription';
import { generateUUID } from '../utility/string-utility';
import { type } from '../utility/type';
import { BatchDataExportGroup } from './batch-data-export-group';
import { BatchDataReportSubscription } from './batch-data-report-subscription';
import { InternalExaminationMarksRecord } from './examination-marks-record';
export const SchoolActionTypes = {
  CREATE_SCHOOL_ADMIN_REGISTRATION: type(
    '[School] Create School Admin Registration'
  ),
  CREATE_SCHOOL_ADMIN_REGISTRATION_COMPLETE: type(
    '[School] Create School Admin Registration Complete'
  ),

  GET_SCHOOL_ADMIN_REGISTRATIONS: type(
    '[School] Get School Admin Registrations'
  ),
  GET_SCHOOL_ADMIN_REGISTRATIONS_COMPLETE: type(
    '[School] Get School Admin Registrations Complete'
  ),

  UPDATE_SCHOOL_ADMIN_REGISTRATION: type(
    '[School] Update School Admin Registration'
  ),
  UPDATE_SCHOOL_ADMIN_REGISTRATION_COMPLETE: type(
    '[School] Update School Admin Registration Complete'
  ),

  GET_SCHOOLS_WITH_SCHOOL_ADMIN_RIGHTS: type(
    '[School] Get Schools with School admin rights'
  ),
  GET_SCHOOLS_WITH_SCHOOL_ADMIN_RIGHTS_COMPLETE: type(
    '[School] Get Schools with School admin rights Complete'
  ),

  GET_SCHOOL_USERS: type('[School] Get School Users'),
  GET_SCHOOL_USERS_COMPLETE: type('[School] Get School Users Complete'),

  GET_SCHOOL_RENEWALS: '[School] Get School Renewals',
  GET_SCHOOL_RENEWALS_COMPLETE: '[School] Get School Renewals Complete',

  REVOKE_REGISTRATION_CODE: '[School] Revoke Registration Code',

  GET_MENTOR_MENTEES: '[School] Get Mentor-Mentees',
  GET_MENTOR_MENTEES_COMPLETE: '[School] Get Mentor-Mentees Complete',

  GET_SCHOOL_EXERCISE_REPORT_SUBSCRIPIONS:
    '[School] Get School Exercise Report Subscriptions',
  GET_SCHOOL_EXERCISE_REPORT_SUBSCRIPTIONS_COMPLETE:
    '[School] Get School Exercise Report Subscriptions Complete',

  LINKUP_SCHOOL_ADMIN_REGISTRATION:
    '[School] Link up School Admin Registration',
  LINKUP_SCHOOL_ADMIN_REGISTRATION_COMPLETE:
    '[School] Link up School Admin Registraiton Complete',

  DOWNLOAD_REGISTRATION_CODES_IN_PDF:
    '[School] Download Registration Codes in Pdf',

  PUSH_NOTIFICATION: '[School] Push Notification',

  LINK_UP_SCHOOL_USER_REGISTRATION: '[School] Link up School User Registration',
  LINK_UP_SCHOOL_USER_REGISTRATION_COMPLETE:
    '[School] Link up School User Registration Complete',

  CHANGE_SCHOOL_ID_SELECTED: '[School] Change School Id Selected',

  OVERRIDE_PASSWORD_OF_SCHOOL_USER: '[School] Override password of School User',

  DELETE_SCHOOL_ADMIN_REGISTRATION: '[School] Delete School Admin Registration',
  DELETE_SCHOOL_ADMIN_REGISTRATION_COMPLETE:
    '[School] Delete School Admin Registration Complete',
  GET_BATCH_DATA_EXPORT_GROUPS: '[School] Get Batch Data Export Groups',
  GET_BATCH_DATA_EXPORT_GROUPS_COMPLETE:
    '[School] Get Batch Data Export Groups Complete',
  CREATE_BATCH_DATA_EXPORT_GROUP: '[School] Create Batch Data Export Group',
  CREATE_BATCH_DATA_EXPORT_GROUP_COMPLETE:
    '[School] Create Batch Data Export Group Complete',
  DELETE_BATCH_DATA_EXPORT_GROUP: '[School] Delete Batch Data Export Group',
  DELETE_BATCH_DATA_EXPORT_GROUP_COMPLETE:
    '[School] Delete Batch Data Export Group Complete',
  DELETE_ALL_BATCH_DATA_EXPORT_GROUPS:
    '[School] Delete All Batch Data Export Groups',
  DELETE_ALL_BATCH_DATA_EXPORT_GROUPS_COMPLETE:
    '[School] Delete All Batch Data Export Groups Complete',
  GET_BATCH_DATA_REPORT_SUBSCRIPTIONS:
    '[School] Get Batch Data Report Subscriptions',
  GET_BATCH_DATA_REPORT_SUBSCRIPTIONS_COMPLETE:
    '[School] Get Batch Data Report Subscriptions Complete',
  CREATE_BATCH_DATA_REPORT_SUBSCRIPTION:
    '[School] Create Batch Data Report Subscription',
  CREATE_BATCH_DATA_REPORT_SUBSCRIPTION_COMPLETE:
    '[School] Create Batch Data Report Subscription Complete',
  DELETE_BATCH_DATA_REPORT_SUBSCRIPTION:
    '[School] Delete Batch Data Report Subscription',
  DELETE_BATCH_DATA_REPORT_SUBSCRIPTION_COMPLETE:
    '[School] Delete Batch Data Report Subscription Complete',
  DELETE_ALL_BATCH_DATA_REPORT_SUBSCRIPTIONS_BY_SCHOOLID:
    '[School] Delete All Batch Data Report Subscriptions By School id',
  DELETE_ALL_BATCH_DATA_REPORT_SUBSCRIPTIONS_BY_SCHOOLID_COMPLETE:
    '[School] Delete All Batch Date Report Subscriptions By School id Complete',
  CREATE_EXAMINATION_MARKS_RECORD: '[School] Create Examination Marks Record',
  CREATE_EXAMINATION_MARKS_RECORD_COMPLETE:
    '[School] Create Examination Marks Record Complete',
  GET_EXAMINATION_MARKS_RECORDS: '[School] Get Examination Marks Records',
  GET_EXAMINATION_MARKS_RECORDS_COMPLETE:
    '[School] Get Examination Marks Records Complete',
  DELETE_EXAMINATION_MARKS_RECORD: '[School] Delete Examination Marks Record',
  DELETE_EXAMINATION_MARKS_RECORD_COMPLETE:
    '[School] Delete Examination Marks Record Complete',
  GET_EXAMINATION_MARKS_RECORD: '[School] Get Examination Marks Record',
  GET_EXAMINATION_MARKS_RECORD_COMPLETE:
    '[School] Get Examination Marks Record Complete'
};

export function getExaminationMarksRecord(
  schoolId: string,
  dateOfExam: Date
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_EXAMINATION_MARKS_RECORD,
    payload: { schoolId, dateOfExam }
  };
}

export function getExaminationMarksRecordComplete(
  record: InternalExaminationMarksRecord
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_EXAMINATION_MARKS_RECORD_COMPLETE,
    payload: { record }
  };
}

export function deleteExaminationMarksRecord(
  schoolId: string,
  dateOfExam: Date
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_EXAMINATION_MARKS_RECORD,
    payload: { schoolId, dateOfExam }
  };
}

export function deleteExaminationMarksRecordComplete(
  schoolId: string,
  dateOfExam: Date
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_EXAMINATION_MARKS_RECORD_COMPLETE,
    payload: { schoolId, dateOfExam }
  };
}

export function createExaminationMarksRecord(
  schoolId: string,
  dateOfExam: Date
): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_EXAMINATION_MARKS_RECORD,
    payload: { schoolId, dateOfExam }
  };
}

export function createExaminationMarksRecordComplete(): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_EXAMINATION_MARKS_RECORD_COMPLETE
  };
}

export function getExaminationMarkRecords(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_EXAMINATION_MARKS_RECORDS,
    payload: { schoolId }
  };
}

export function getExaminationMarkRecordsComplete(
  records: InternalExaminationMarksRecord[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_EXAMINATION_MARKS_RECORDS_COMPLETE,
    payload: { records }
  };
}

export function deleteAllBatchDataReportSubscriptionsBySchoolId(
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_ALL_BATCH_DATA_REPORT_SUBSCRIPTIONS_BY_SCHOOLID,
    payload: schoolId
  };
}

export function deleteAllBatchDataReportSubscriptionsBySchoolIdComplete(
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_ALL_BATCH_DATA_REPORT_SUBSCRIPTIONS_BY_SCHOOLID_COMPLETE,
    payload: schoolId
  };
}

export function deleteBatchDataReportSubscription(
  id: string,
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_BATCH_DATA_REPORT_SUBSCRIPTION,
    payload: { id, schoolId }
  };
}

export function deleteBatchDataReportSubscriptionComplete(
  id: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_BATCH_DATA_REPORT_SUBSCRIPTION_COMPLETE,
    payload: id
  };
}

export function createBatchDataReportSubscription(
  reportSubscription: BatchDataReportSubscription
): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_BATCH_DATA_REPORT_SUBSCRIPTION,
    payload: reportSubscription
  };
}

export function createBatchDataReportSubscriptionComplete(
  reportSubscription: BatchDataReportSubscription
): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_BATCH_DATA_REPORT_SUBSCRIPTION_COMPLETE,
    payload: reportSubscription
  };
}

export function deleteAllBatchDataExportGroups(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_ALL_BATCH_DATA_EXPORT_GROUPS,
    payload: { schoolId }
  };
}

export function deleteAllBatchDataExportGroupsComplete(
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_ALL_BATCH_DATA_EXPORT_GROUPS_COMPLETE,
    payload: { schoolId }
  };
}

export function deleteBatchDataExportGroup(id: string): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_BATCH_DATA_EXPORT_GROUP,
    payload: id
  };
}

export function deleteBatchDataExportGroupComplete(id: string): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_BATCH_DATA_EXPORT_GROUP_COMPLETE,
    payload: id
  };
}

export function createBatchDataExportGroup(
  schoolId: string,
  name: string,
  tick: string,
  registrationCodes: string[],
  filters: Filter[]
): UnsafeAction {
  const id = generateUUID();
  const group: BatchDataExportGroup = {
    id,
    schoolId,
    name,
    registrationCodes,
    tick,
    filters
  };
  return {
    type: SchoolActionTypes.CREATE_BATCH_DATA_EXPORT_GROUP,
    payload: group
  };
}

export function createBatchDataExportGroupComplete(
  group: BatchDataExportGroup
): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_BATCH_DATA_EXPORT_GROUP_COMPLETE,
    payload: group
  };
}

export function getBatchDataReportSubscriptions(
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_BATCH_DATA_REPORT_SUBSCRIPTIONS,
    payload: { schoolId }
  };
}

export function getBatchDataReportSubscriptionsComplete(
  subscriptions: BatchDataReportSubscription[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_BATCH_DATA_REPORT_SUBSCRIPTIONS_COMPLETE,
    payload: subscriptions
  };
}

export function getBatchDataExportGroups(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_BATCH_DATA_EXPORT_GROUPS,
    payload: { schoolId }
  };
}

export function getBatchDataExportGroupsComplete(
  groups: BatchDataExportGroup[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_BATCH_DATA_EXPORT_GROUPS_COMPLETE,
    payload: { groups }
  };
}

export function overridePasswordOfSchoolUser(
  newPassword: string,
  email: string,
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.OVERRIDE_PASSWORD_OF_SCHOOL_USER,
    payload: { email, newPassword, schoolId }
  };
}

export function deleteSchoolAdministratorRegistration(
  registrationCode: string,
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_SCHOOL_ADMIN_REGISTRATION,
    payload: { registrationCode, schoolId }
  };
}

export function deleteSchoolAdministratorRegistrationComplete(
  registrationCode: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.DELETE_SCHOOL_ADMIN_REGISTRATION_COMPLETE,
    payload: { registrationCode }
  };
}

export function changeSchoolIdSelected(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.CHANGE_SCHOOL_ID_SELECTED,
    payload: schoolId
  };
}

export function pushNotification(
  notification: PushNotification,
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.PUSH_NOTIFICATION,
    payload: { notification, schoolId }
  };
}

export function downloadRegistrationCodesInPdf(
  schoolId: string,
  uniqueUserIdentifiers: string[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.DOWNLOAD_REGISTRATION_CODES_IN_PDF,
    payload: { schoolId, uniqueUserIdentifiers }
  };
}

export function getMentorMentees(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_MENTOR_MENTEES,
    payload: schoolId
  };
}

export function getMentorMenteesComplete(
  mentorMentees: MentorMentee[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_MENTOR_MENTEES_COMPLETE,
    payload: mentorMentees
  };
}

export function revokeRegistrationCode(registrationCode: string): UnsafeAction {
  return {
    type: SchoolActionTypes.REVOKE_REGISTRATION_CODE,
    payload: registrationCode
  };
}

export function updateSchoolAdminRegistration(
  registration: SchoolAdminRegistration
): UnsafeAction {
  return {
    type: SchoolActionTypes.UPDATE_SCHOOL_ADMIN_REGISTRATION,
    payload: registration
  };
}

export function updateSchoolAdminRegistrationComplete(
  registration: SchoolAdminRegistration
): UnsafeAction {
  return {
    type: SchoolActionTypes.UPDATE_SCHOOL_ADMIN_REGISTRATION_COMPLETE,
    payload: registration
  };
}

export function createAdminRegistrationCode(
  schoolId: string,
  remark: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_SCHOOL_ADMIN_REGISTRATION,
    payload: { schoolId, remark, registrationCode: generateUUID() }
  };
}

export function createSchoolAdminRegistrationComplete(
  schoolAdminRegistration: SchoolAdminRegistration
): UnsafeAction {
  return {
    type: SchoolActionTypes.CREATE_SCHOOL_ADMIN_REGISTRATION_COMPLETE,
    payload: schoolAdminRegistration
  };
}

export function getSchoolAdminRegistrations(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS,
    payload: schoolId
  };
}

export function getSchoolAdminRegistrationsComplete(
  schoolAdminRegistrations: SchoolAdminRegistration[]
) {
  return {
    type: SchoolActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS_COMPLETE,
    payload: schoolAdminRegistrations
  };
}

export function getSchoolsWithSchoolAdminRights(): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOLS_WITH_SCHOOL_ADMIN_RIGHTS
  };
}

export function getSchoolsWithSchoolAdminRightsComplete(
  schools: School[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOLS_WITH_SCHOOL_ADMIN_RIGHTS_COMPLETE,
    payload: schools
  };
}

export function getSchoolUsers(
  schoolId: string,
  mostRecentOnly: boolean = false
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_USERS,
    payload: { schoolId, mostRecentOnly }
  };
}

export function getSchoolUsersComplete(
  schoolUsers: SchoolUser[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_USERS_COMPLETE,
    payload: schoolUsers
  };
}

export function getschoolSubscriptionRenewals(schoolId: string): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_RENEWALS,
    payload: schoolId
  };
}

export function getschoolSubscriptionRenewalsComplete(
  schoolSubscriptionRenewals: SchoolMembershipRenewal[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_RENEWALS_COMPLETE,
    payload: schoolSubscriptionRenewals
  };
}

export function getSchoolExerciseReportSubscriptions(
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_EXERCISE_REPORT_SUBSCRIPIONS,
    payload: { schoolId }
  };
}

export function getSchoolExerciseReportSubscriptionsComplete(
  subscriptions: ExerciseReportSubscription[]
): UnsafeAction {
  return {
    type: SchoolActionTypes.GET_SCHOOL_EXERCISE_REPORT_SUBSCRIPTIONS_COMPLETE,
    payload: subscriptions
  };
}

export function linkUpSchoolAdminRegistration(
  email: string,
  registrationCode: string,
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.LINKUP_SCHOOL_ADMIN_REGISTRATION,
    payload: { email, registrationCode, schoolId }
  };
}

export function linkUpSchoolAdminRegistrationComplete(
  result: any
): UnsafeAction {
  return {
    type: SchoolActionTypes.LINKUP_SCHOOL_ADMIN_REGISTRATION_COMPLETE,
    payload: result
  };
}

export function linkUpSchoolUserRegistration(
  email: string,
  registrationCode: string,
  schoolId: string
): UnsafeAction {
  return {
    type: SchoolActionTypes.LINK_UP_SCHOOL_USER_REGISTRATION,
    payload: { email, registrationCode, schoolId }
  };
}

export function linkUpSchoolUserRegistrationComplete(
  result: any
): UnsafeAction {
  return {
    type: SchoolActionTypes.LINK_UP_SCHOOL_USER_REGISTRATION_COMPLETE,
    payload: result
  };
}
