import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mapTo,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Configuration } from 'src/app/configuration';
import { changeUILanguage } from '../../i18n/i18n-actions';
import { mapIanaCodeToUILanguage } from '../../i18n/i18n-utility';
import { UnsafeAction } from '../../model/unsafe-action';
import { UserPreference } from '../../model/user-preference';
import { AppState } from '../../states/states-reducers';
import { changeSyllabusLanguage } from '../syllabus/syllabus-actions';
import { NullAction } from '../ui/ui-actions';
import { UserAuthenticationService } from '../user-authentication.service';
import { promptForUICulture } from './user-actions';
import {
  UserPreferenceActionTypes,
  changeThemeColor,
  getUserPreferenceComplete,
  saveUserPreference
} from './user-preference-actions';
import { userPreferenceStateSelector } from './user-preference-selectors';
import {
  UserPreferenceState,
} from './user-preference-state-reducer';
import { UserPreferenceService } from './user-preference.service';

@Injectable()
export class UserPreferenceEffects {
  constructor(
    private userPreferenceService: UserPreferenceService,
    private actions$: Actions,
    private store: Store<AppState>,
    private userAuthenticationService: UserAuthenticationService,
    private config: Configuration
  ) {}

  @Effect()
  changeThemeColor = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.CHANGE_THEME_COLOR),
    mapTo(saveUserPreference())
  );

  @Effect()
  changeLandingPreference = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.CHANGE_LANDING_PREFERENCE),
    mapTo(saveUserPreference())
  );

  @Effect()
  getUserPreference = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.GET_USERPREFERENCE),
    exhaustMap((_) =>
      this.userPreferenceService
        .getUserPreference()
        .pipe(catchError((_) => of(null)))
    ),
    map((preference: UserPreference) => {
      if (!preference) {
        return NullAction;
      } else if (!preference.uiLanguageCode) {
        return promptForUICulture();
      } else {
        return getUserPreferenceComplete(preference);
      }
    })
  );

  @Effect({ dispatch: false })
  getUserPreferenceComplete: any = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.GET_USERPREFERENCE_COMPLETE),
    tap((action: UnsafeAction) => {
      const userPreference: UserPreference = action.payload;
      this.store.dispatch(
        changeUILanguage(mapIanaCodeToUILanguage(userPreference.uiLanguageCode))
      );
      this.store.dispatch(changeThemeColor(userPreference.colorTheme));
    })
  );

  @Effect({ dispatch: false })
  SaveUserPreference = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.SAVE_USERPREFERENCE),
    filter(() => !!this.userAuthenticationService.user),
    withLatestFrom(this.store.select(userPreferenceStateSelector)),
    debounceTime(this.config.userPreferenceSaveDeboundeTimeInMs),
    exhaustMap(([_, state]: [UnsafeAction, UserPreferenceState]) => {
      return this.userPreferenceService
        .upsertUserPreference(state.userPreference)
        .toPromise();
    })
  );

  @Effect()
  updateDictionaryLevelPreference = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.CHANGE_DICTIONARYLEVEL_PREFERENCE),
    mapTo(saveUserPreference())
  );

  @Effect()
  updatePronunciationPreference = this.actions$.pipe(
    ofType(
      UserPreferenceActionTypes.CHANGE_VOCABULARY_PRONUNCIATION_PREFERENCE
    ),
    mapTo(saveUserPreference())
  );

  @Effect()
  toggleShowDefinitionOrder = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.TOGGLE_SHOW_DEFINITIONS_ORDER),
    mapTo(saveUserPreference())
  );

  @Effect()
  changeVocabularyLanguage = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.CHANGE_VOCABULARY_LANGUAGE),
    tap((action: UnsafeAction) =>
      this.store.dispatch(changeSyllabusLanguage(action.payload))
    ),
    mapTo(saveUserPreference())
  );

  @Effect()
  toggleDisableThumbnails = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.TOGGLE_DISABLE_THUMBNAILS),
    tap((action: UnsafeAction) =>
      this.store.dispatch(changeSyllabusLanguage(action.payload))
    ),
    mapTo(saveUserPreference())
  );


  @Effect()
  updateUILanguagePreference = this.actions$.pipe(
    ofType(UserPreferenceActionTypes.CHANGE_UI_LANGUAGE_PREFERENCE),
    mapTo(saveUserPreference())
  );
}
