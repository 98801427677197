import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ContactDisplayComponent } from '../erp-ui/contact-display.component';
import { ContactEditComponent } from '../erp-ui/contact-edit.component';
import { ContactHistoryDisplayComponent } from '../erp-ui/contact-history-display.component';
import { ContactHistoryEditComponent } from '../erp-ui/contact-history-edit.component';
import { ContactsDisplayComponent } from '../erp-ui/contacts-display.component';
import { ContactsEditComponent } from '../erp-ui/contacts-edit.component';
import { ErpSchoolBrowserComponent } from '../erp-ui/erp-school-browser.component';
import { ErpSchoolComponent } from '../erp-ui/erp-school.component';
import { SharedModule } from '../shared/shared.module';
import { EdbSchoolDisplayComponent } from '../erp-ui/edb-school-display.component';
import { ErpSchoolDisplayComponent } from '../erp-ui/erp-school-display.component';
import { ErpSchoolEditComponent } from '../erp-ui/erp-school-edit.component';
import { ErpSchoolManagementComponent } from '../erp-ui/erp-school-management.component';
import { SchoolLandSchoolDisplayComponent } from '../erp-ui/schoolland-school-display.component';
import { ErpRoutingModule } from './erp-routing.module';
import { ErpStateReducer, INITIAL_ERP_STATE } from './erp-state-reducer';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ErpRoutingModule,
    StoreModule.forFeature('erp', ErpStateReducer, {
      initialState: INITIAL_ERP_STATE
    })
  ],

  declarations: [
    ContactHistoryDisplayComponent,
    ContactHistoryEditComponent,
    ContactDisplayComponent,
    ContactsDisplayComponent,
    ContactEditComponent,
    ContactsEditComponent,
    ErpSchoolManagementComponent,
    ErpSchoolComponent,
    ErpSchoolBrowserComponent,
    ErpSchoolDisplayComponent,
    ErpSchoolEditComponent,
    EdbSchoolDisplayComponent,
    SchoolLandSchoolDisplayComponent
  ],
  exports: [
    ContactHistoryDisplayComponent,
    ContactHistoryEditComponent,
    ContactsDisplayComponent,
    ContactDisplayComponent,
    ContactsEditComponent,
    ContactEditComponent,
    ErpSchoolManagementComponent,
    ErpSchoolComponent,
    ErpSchoolBrowserComponent,
    ErpSchoolDisplayComponent,
    ErpSchoolEditComponent,
    EdbSchoolDisplayComponent,
    SchoolLandSchoolDisplayComponent
  ]
})
export class ErpModule {}
