<div class="flex-column p-3">

  <div style="cursor: pointer;"
      class="col-12 text-left"
      (click)="onThemeSelected('light')"
    >
     {{'ui.light-color-theme' | translate}}
    </div>
    <div style="cursor: pointer;"
      class="col-12 text-left"
      (click)="onThemeSelected('dark')"
    >
     {{'ui.dark-color-theme' | translate}}
    </div>

</div>
