import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgForage } from 'ngforage';
import { AppState } from '../states/states-reducers';
import { displayLocalStorageWarning } from './ui/ui-actions';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor(private store: Store<AppState>, private ngf: NgForage) {
    this.test()
      .then((supported) => {
        if (!supported) {
          this.displayUnsupportedMessage();
        }
      })
      .catch(this.displayUnsupportedMessage);
  }

  put(key: string, item: any): Promise<void> {
    return this.ngf.setItem(key, item).catch((error) => {
      this.displayUnsupportedMessage();
      throw error;
    });
  }

  get(key: string): Promise<any> {
    return this.ngf.getItem<any>(key).catch((error) => {
      this.displayUnsupportedMessage();
      throw error;
    });
  }

  getAllKeys(): Promise<string[]> {
    return this.ngf.keys().catch((error) => {
      this.displayUnsupportedMessage();
      throw error;
    });
  }

  remove(key: string): Promise<void> {
    return this.ngf.removeItem(key).catch((error) => {
      this.displayUnsupportedMessage();
      throw error;
    });
  }

  test(): Promise<boolean> {
    var mod = 'modernizr';
    return this.put(mod, mod)
      .then((_) => this.get(mod))
      .then((value) => value === mod);
  }

  displayUnsupportedMessage() {
    this.store.dispatch(displayLocalStorageWarning());
  }
}
