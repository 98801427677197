import { HttpErrorResponse } from '@angular/common/http';
import { BadRequestException } from './bad-request-exception';
import { ExceptionType } from './exception-type';
import { FrameWindowTimedOutException } from './frame-window-timed-out-exception';
import { IatIsInTheFutureException } from './iat-is-in-the-future-exception';
import { LoginRequiredException } from './login-required-exception';
import { NetworkException } from './network-exception';
import { NoMatchingStateException } from './no-matching-state-exception';
import { NoStateInReponseException } from './no-state-in-reponse-exception';
import { NotFoundException } from './not-found-exception';
import { OpeningPopupWindowException } from './opening-popup-window-exception';
import { PopupWindowClosedException } from './popup-window-closed-exception';
import { ServerErrorException } from './server-error-exception';
import { TimeoutException } from './timeout-exception';
import { UnauthorizedException } from './unauthorized-exception';
import { UndefinedException } from './undefined-exception';

export class Exception {
  type: ExceptionType;

  static getExeption(errorString: string): Exception {
    if (errorString.startsWith('iat is in the future')) {
      return new IatIsInTheFutureException();
    }

    if (
      errorString.indexOf('404 Not Found') >= 0 ||
      errorString.indexOf('404 Ok') >= 0
    ) {
      return new NotFoundException();
    }

    if (errorString.indexOf('400 Bad Request') >= 0) {
      return new BadRequestException();
    }

    if (errorString.indexOf('401 Unauthorized') > 0) {
      return new UnauthorizedException();
    }

    if (errorString.indexOf('0 Unknown Error') > 0) {
      return new NetworkException();
    }

    if (errorString.indexOf('500 Internal Server Error') > 0) {
      return new NetworkException();
    }

    return null;
  }

  static getHttpException(error: HttpErrorResponse): Exception {
    switch (error.status) {
      case 400:
        return new BadRequestException();
      case 401:
        return new UnauthorizedException();
      case 404:
        return new NotFoundException();
      case 500:
        return new ServerErrorException();
      default:
        return null;
    }
  }

  static create(error: any): Exception {
    if (error) {
      if (error.name === 'HttpErrorResponse') {
        const httpException = this.getHttpException(error);
        if (httpException) {
          return httpException;
        }
      }

      let errorString: string = undefined;

      if (error.error && typeof error.error === 'string')
        errorString = error.error;
      if (error.message && typeof error.message === 'string')
        errorString = error.message;

      if (errorString) {
        switch (errorString) {
          case 'No matching state found in storage':
            return new NoMatchingStateException();
          case 'login_required':
            return new LoginRequiredException();
          case 'Network Error':
            return new NetworkException();
          case 'Popup window closed':
            return new PopupWindowClosedException();
          case 'Frame window timed out':
            return new FrameWindowTimedOutException();
          case 'Error opening popup window':
            return new OpeningPopupWindowException();
          case 'No state in response':
            return new NoStateInReponseException();
          case 'Timeout has occurred':
            return new TimeoutException();
          default:
            const exception = this.getExeption(errorString);
            if (exception) {
              return exception;
            } else {
            }
            break;
        }
      }

      if (error.ok === false) {
        switch (error.status) {
          case 401:
            return new UnauthorizedException(
              error.status,
              error.statusText,
              error.type,
              error.url
            );
          default:
            return new NetworkException(
              error.status,
              error.statusText,
              error.type,
              error.url
            );
        }
      }
    } else {
    }
    return new UndefinedException();
  }
}
