export enum ExceptionType {
  NetWork,
  LoginRequired,
  PopupWindowClosed,
  FrameWindowTimedOut,
  Unauthorized,
  OpeningPopupWindow,
  IatIsInTheFuture,
  NoStateInResponse,
  Timeout,
  NoMatchingState,
  NotFound,
  BadRequest,
  ServerError
}
