import { Injectable } from '@angular/core';
import { Configuration } from '../../configuration';
import { Pronunciation } from '../../model/pronunciation';

const silientSrc = 'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
const beepSrc = '/assets/audio/-----.mp3';


@Injectable({ providedIn: 'root' })
export class AudioHandlingService {
  private audio: HTMLAudioElement;
  private activated: boolean;

  constructor(private config: Configuration) {
  }

  activateAudio() {
    if (!this.activated) {
        this.audio = new Audio();
        this.audio.src = silientSrc;
        this.audio.play().catch();
        this.activated = true;
    }
  }

  playPhrase(phrase: string, pron: Pronunciation): void {
    const url = this.getUrl(phrase, pron);
    this.playSource(url);
  }

  playPronunciationId(pronunciationId: string): void {
    if (!pronunciationId) return;
    const url = `${this.config.audioRootUrl}/pronunciations/${pronunciationId}`;
    this.playSource(url);
  }

  playBeep(): void{
    this.playSource(beepSrc);
  }

  private recoverFromError(){
    this.activated = false;
    this.activateAudio();
  }

  private playSource(source: string): Promise<void> {
    if(!this.audio){
      this.recoverFromError();
    }

    if (source) {
      this.audio.src = source;
      const recoverFromError = this.recoverFromError.bind(this);
      return this.audio.play().catch(recoverFromError);
    } else{
      return Promise.resolve();
    }
  }

  private getUrl(phrase: string, pron: Pronunciation = 0): string {
    if (!phrase) return null;
    const url = `${
      this.config.audioRootUrl
    }/audio/${pron}?phrase=${encodeURIComponent(phrase)}`;
    return url;
  }
}
