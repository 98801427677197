export const environment = {
  isProduction: true,
  envName: 'production',
  enableDebugTools: false,
  originalApiRootUrl: 'https://api.solidmemory.com',
  stsRootUrl: 'https://sts.solidmemory.com',
  apiRootUrl: 'https://www.solidmemory.com/api',
  wsRootUrl: 'https://www.solidmemory.com/ws',
  dictionaryRootUrl: 'https://www.solidmemory.com/api/dictionary',
  imageRootUrl: 'https://www.solidmemory.com/api/image',
  audioRootUrl: 'https://www.solidmemory.com/api/audio',
  erpRootUrl: 'https://www.solidmemory.com/api/erp',
  publicKey:
    'BPfPLCp115puuS6ukgr33J9_3fsXM0GkOUmcbfZNhvJqSucTNk6AN7zhz5re6YXfrmYaTsTKRVuflfa7AiBDWxM',
  enableServiceWorker: true,
  payDollarUrl: 'https://www.paydollar.com/b2c2/eng/payment/payForm.jsp',
  payDollarMerchantId: 88147475,
  payPalScriptSrc:
    'https://www.paypal.com/sdk/js?client-id=AQhi1qYMtzJYS79VjVLnKz6A--5ngRzSyWFgDnkFMh5DRdeLj5PiDN1zpuIfN9X7IVqtB4nHvVT9WvvJ&currency=HKD',
  enableConnectionCheck: true,
  version: 'v2024.11.5.20',
  sentryDsn:
    'https://f9e1176429d84fa9a18887b96dfc51bb@o532726.ingest.sentry.io/5651959',
  sentryDsnp1:
    'https://fdf9736ffb944b5fa0a7109b702ab75b@o535904.ingest.sentry.io/5654380',
  sentrySampleRate: 1.0,
  sentryTracesSampleRate: 0.01,
  showSentryDialog: false,
  updateCheckInterval: 86400000, //1 day
  pubKey : `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAvjGvTpHHm8u9TFSdbGLn
gj8Z8v1dvmUoGlwVLmJnHUHgx6i3pdLMFvSwfPRzAqHEnY0Z+QP/LzqOIW6HHE5b
oAah88wuWzWkyDETnStX/mcQ8F6F4oEmukF3KnDL0CtI+JcmMB4ASKnx7aWMpZwq
EMz2Ax9Xb4l1FFV9VwGb0qMgNS/sLmCqXsdLsoEKPInYA79ZqknrvhXU0t9LPbLa
ujfSlst4KxZ4AiGDEVgaUaiHRC9oTk3ncGtxZHE2NAVnhCNjlhVfzl8eoTZMN/5r
LlYryvNlCgKncm+ueZr3g/LT43eogswhewiK3ajIXErgI1lqmg/mMSciA8buV/RX
uA6DKk1eYo89BZeaPVYypnz4fbYSf3FbY6G6czJr5SYfZZntkokAii1oqemOhCUb
jMM+tEBGFe/EZSIxY5/sWHxtx4iHA9T3A2K21NNbDDc7T8Obh9ROsozfcIhaN0XF
uzXY+NwVr2oeOQsz7VihtgnIocb15jOxjJF9b9ZBy6uA7fHKldmY2ewszyYrqzOb
QESOmY0pYHODuGiGIGCwd2AIw/1+/yvjuRHBn5rIMbJfTeA4UUgbC2AGImK+SZbb
7WPkjc6YdwLF9z4Zr457JgkS0ewtqkX/0Y0opR6mScBBtsUry+Ydyf/ELD2Znrb7
ygH7bQvs0UFHSYUX/MgpB8cCAwEAAQ==
-----END PUBLIC KEY-----`
};
