import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectNextErpSchool,
  selectPreviousErpSchool
} from '../erp/erp-actions';
import { ErpSchool } from '../erp/erp-school';
import { AppState } from '../states/states-reducers';

@Component({
  selector: 'app-erp-school',
  templateUrl: './erp-school.component.html'
})
export class ErpSchoolComponent {
  showEdit: boolean;

  @Input() erpSchool: ErpSchool;
  @Output() managementClick = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  onPrevButtonClick() {
    this.store.dispatch(selectPreviousErpSchool());
  }

  onManagementButtonClick() {
    this.managementClick.emit();
  }

  onModeButtonClick() {
    this.showEdit = !this.showEdit;
  }

  onDisplayButtonClick() {}

  onNextButtonClick() {
    this.store.dispatch(selectNextErpSchool());
  }

  get modeLabel(): string {
    if (this.showEdit) {
      return 'Display';
    } else {
      return 'Edit';
    }
  }
}
