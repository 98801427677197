import { DynamicDialogConfig } from 'primeng/dynamicdialog';

export class DynamicDialogConfigBuilder {
  m = -0.0416;
  c = 120;
  contentHeight = '100%';
  contentWidth = '100%';
  overflow = 'auto';
  overflow_x = 'auto';
  overflow_y = 'auto';
  headerText: string;
  footerText: string;
  backgroundColor: string;
  width: string;
  height: string;
  padding: string = '0';
  margin: string = '0';
  background: string;
  closeOnEscape = true;
  dismissableMask = true;
  closable = true;
  showHeader = false;
  styleClass: string;
  top: string;
  right: string;
  left: string;
  bottom: string;
  position: string;
  data: any;
  textAlign = 'center';
  borderRadius = '4px';
  dialogPadding: string = '0';
  dialogMargin: string = '0';
  dialogBackground: string;
  maxHeight = '90%';

  buildFixed(): DynamicDialogConfig {
    return {
      width: this.width,
      height: this.height,
      contentStyle: {
        height: this.contentHeight,
        width: this.contentWidth,
        overflow: this.overflow,
        'overflow-x': this.overflow_x,
        'overflow-y': this.overflow_y,
        backgroundColor: this.backgroundColor,
        padding: this.padding,
        margin: this.margin,
        textAlign: this.textAlign,
        borderRadius: this.borderRadius,
        background: this.background
      },
      showHeader: this.showHeader,
      header: this.headerText,
      footer: this.footerText,
      closeOnEscape: this.closeOnEscape,
      dismissableMask: this.dismissableMask,
      closable: this.closable,
      styleClass: this.styleClass,
      style: {
        top: this.top,
        right: this.right,
        left: this.left,
        bottom: this.bottom,
        position: this.position,
        background: this.dialogBackground,
        padding: this.dialogPadding,
        margin: this.dialogMargin,
        maxHeight: this.maxHeight
      },
      data: this.data
    };
  }

  build(): DynamicDialogConfig {
    let percentage = this.m * window.innerWidth + this.c;
    if (percentage < 40) {
      percentage = 40;
    }

    if (percentage > 95) {
      percentage = 95;
    }

    this.width = percentage + 'vw';

    return this.buildFixed();
  }
}
