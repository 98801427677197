import { Component, EventEmitter, Input, Output } from '@angular/core';
import { field } from '../shared/field';
import { EdbSchool } from '../erp/edb-school';

@Component({
  selector: 'app-edb-school-display',
  templateUrl: './edb-school-display.component.html'
})
export class EdbSchoolDisplayComponent {
  @Input() edbSchool: EdbSchool;
  @Output() fieldClick = new EventEmitter<field>();

  onFieldClick(field: field) {
    this.fieldClick.emit(field);
  }
}
