export const signUpMenu = {
  label: 'account.sign-up',
  icon: 'pi pi-user',
  items: [
    {
      label: 'account.sign-up',
      icon: 'pi pi-user',
      routerLink: ['signUp/signUpAccount']
    },
    {
      label: 'account.forget-create-password',
      icon: 'pi pi-exclamation-circle',
      routerLink: ['signUp/forgetPassword']
    },
    {
      label: 'account.resend-confirmation-email',
      icon: 'pi pi-envelope',
      routerLink: ['signUp/resendConfirmation']
    }
  ]
};
