<div class="grid ">
  <div class="col-6">
    <p-dropdown
      [options]="districtSelectItems"
      [ngModel]="districtSelected"
      (onChange)="onDistrictChange($event.value)"
      scrollHeight="600px"
    ></p-dropdown>
  </div>

  <div class="col-6">
    <p-dropdown
      [options]="schoolLevelSelectItems"
      [ngModel]="schoolLevelSelected"
      (onChange)="onSchoolLevelChange($event.value)"
      scrollHeight="600px"
    ></p-dropdown>
  </div>

  <div class="col-12">
    <p-table breakpoint="1pt" [value]="erpSchools">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="gridRef">GridRef</th>
          <th pSortableColumn="schoolNameInChinese">Chinese</th>
          <th pSortableColumn="schoolNameInEnglish">English</th>
          <th pSortableColumn="session">Session</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-school>
        <tr class="ui-selectable-row" (click)="onSchoolClick(school)">
          <td>{{ school.gridRef }}</td>
          <td [height]="rowHeight">{{ school.schoolNameInChinese }}</td>
          <td>{{ school.schoolNameInEnglish }}</td>
          <td>{{ school.session }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
