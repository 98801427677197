import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AppState } from 'src/app/states/states-reducers';
import { generateUUID } from 'src/app/utility/string-utility';
import { Exercise } from '../../model/exercise';
import { ExerciseLevel } from '../../model/exercise-level';
import { Level } from '../../model/level';
import { UICulture } from '../../model/ui-culture';
import { UpdateLevelsCommand } from '../../model/update-levels-command';
import {
  DEFAULT_UUID,
  toTimeMinSec,
  toTimeSpanString
} from '../../utility/time-utility';
import { LoggingService } from '../log/logging.service';
import { updateExerciseLevels } from './exercise-actions';
import { ExerciseItemImplementation } from './exercise-item-implemenation';
import { ExerciseItemsServiceBase } from './exercise-items-service-base';
import { ExerciseSubmissionService } from './exercise-submission.service';
import { ExerciseService } from './exercise.service';
import { PreloadingService } from './preloading.service';
@Injectable({ providedIn: 'root' })
export class ExerciseItemsService extends ExerciseItemsServiceBase {
  syllabusId = DEFAULT_UUID;

  private exercise = new BehaviorSubject<Exercise>(null);

  exercise$ = this.exercise.asObservable();

  constructor(
    private exerciseService: ExerciseService,
    private exerciseSubmissionService: ExerciseSubmissionService,
    private store : Store<AppState>,
    preloadingSerivce: PreloadingService,
    loggingService : LoggingService
  ) {
    super(preloadingSerivce, loggingService);
  }

  getExerciseItems({
    syllabusId,
    skipQuestionsRequireCalculator,
    isTest,
    enableQuestionThrottling,
    hasUnlimitedAccess,
    uiCulture,
    dictionaryLevel
  }: {
    syllabusId: string;
    skipQuestionsRequireCalculator: boolean;
    isTest: boolean;
    enableQuestionThrottling: boolean;
    hasUnlimitedAccess: boolean;
    uiCulture: UICulture;
    dictionaryLevel: Level;
  }): Observable<ExerciseItemImplementation[]> {
    this.resetExerciseState();
    this.syllabusId = syllabusId;
    this._uiCulture = uiCulture;
    this._dictionaryLevel = dictionaryLevel;
    this.isTest = isTest;
    this.skipQuestionsRequireCalculator = skipQuestionsRequireCalculator;

    this.exercise.next(undefined);
    return this.exerciseService
      .getExercise({
        syllabusId,
        skipQuestionsRequireCalculator,
        isTest,
        enableQuestionThrottling,
        hasUnlimitedAccess,
        uiCulture
      })
      .pipe(
        map((exercise) => {
          this.exercise.next(exercise);
          if (exercise.items.length > 0) {
            this._syllabusLanguage = exercise.items[0].language;
            const items = this.mapExerciseToExerciseItemImplementations(
              exercise,
              isTest
            );
            this._exerciseItems$.next(items);
            return items;
          } else {
            return null;
          }
        }),
        exhaustMap((items) => {
          if (items !== null) {
            return this.startExercise().then((_) => items);
          } else {
            return Promise.resolve(null);
          }
        }),
        catchError((_) => of(null))
      );
  }

  updateExerciseLevels() : Promise<any> {
    const command = this.getUpdateExerciseLevelsCommand();
    if (this.exerciseSubmissionService.isValidUpdateCommand(command)) {
      this.resetExerciseState();
      return this.updateExerciseLevelsWithCommand(command);
    } else {
      return Promise.resolve();
    }
  }

  protected dispatchExerciseCompletedCommand(): void {
    this.store.dispatch(updateExerciseLevels());
  }

  private getExerciseLevels(): ExerciseLevel[] {
    const exerciseItems = this._exerciseItems$.value;
    return exerciseItems.map((e) => {
      return {
        id: e.question.id,
        level: e.itemLevel
      };
    });
  }

  private getUpdateExerciseLevelsCommand(): UpdateLevelsCommand {
    if (this._canSendLevelsUpdate) {
      const levels = this.getExerciseLevels();
      this.updateExerciseSummaryValues();
      const timeTaken = toTimeSpanString(this._timeTakenInMs);
      return {
        syllabusId: this.syllabusId,
        levels,
        timeTaken,
        id: generateUUID(),
        retentionRate: this.retentionRate,
        isTest: this.isTest,
        date: new Date(),
        minSecTaken: toTimeMinSec(this._timeTakenInMs),
        score: this.score,
        hasOldQuestion: this.hasOldQuestion,
        newQuestionsCount: this.newQuestionsCount,
        totalQuestionsCount: this.totalQuestionsCount
      };
    } else {
      return null;
    }
  }

  private updateExerciseLevelsWithCommand(command: UpdateLevelsCommand) {
    if (command.isTest) {
      return this.exerciseSubmissionService.updateTestLevels(command);
    } else {
      return this.exerciseSubmissionService.updateExerciseLevels(command);
    }
  }

  private mapExerciseToExerciseItemImplementations(
    exercise: Exercise,
    isTest: boolean
  ): ExerciseItemImplementation[] {
    return exercise.items.map(
      (i) =>
        new ExerciseItemImplementation(i, i.achievementLevel, false, isTest, exercise.problemSetSetting.testCompleteOnlyWhenAllCorrect)
    );
  }
}
