import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClientService } from '../../core/http-client.service';
import { WebImage } from './web-image';

@Injectable({ providedIn: 'root' })
export class ThumbnailDataService {
  constructor(
    private httpClient: HttpClientService,
    ) {}

  getThumbnails(url: string): Observable<WebImage[]> {
    return this.httpClient.authGet<any>(url).pipe(map((json) => json.webImages));
  }

  blameImages(url: string, ids: string[]): Observable<any> {
    return this.httpClient.authPost<any>(url, { ids });
  }

  resetImageBlames(url: string, ids: string[]): Observable<any> {
    return this.httpClient.authPut<any>(url, { ids });
  }

  endorseImages(url: string, ids: string[]): Observable<any> {
    return this.httpClient.authPatch<any>(url, { ids });
  }
}
