import * as iassign from 'immutable-assign';
import { find } from 'lodash-es';
import { UnsafeAction } from '../model/unsafe-action';
import { EdbSchool } from './edb-school';
import { ErpActionTypes } from './erp-actions';
import { ErpSchool } from './erp-school';
import { ErpSchoolKey } from './erp-school-key';
import { SchoolLevel } from './school-level';
import { SchoolLandSchool } from './schoolland-school';

export interface ErpState {
  erpSchools: ErpSchool[];
  edbSchool: EdbSchool;
  schoolLandSchool: SchoolLandSchool;
  erpSchoolKeySelected: ErpSchoolKey;
  districtSelected: string;
  schoolLevelSelected: SchoolLevel;
}

export const INITIAL_ERP_STATE: ErpState = {
  erpSchools: [],
  edbSchool: undefined,
  schoolLandSchool: undefined,
  erpSchoolKeySelected: undefined,
  districtSelected: 'CENTRAL AND WESTERN',
  schoolLevelSelected: SchoolLevel.unspecified
};

export function ErpStateReducer(
  state: ErpState = INITIAL_ERP_STATE,
  { type, payload }: UnsafeAction
): ErpState {
  switch (type) {
    case ErpActionTypes.CHANGE_DISTRICT_SELECTED:
      return iassign(state, (s) => {
        s.districtSelected = payload;
        return s;
      });
    case ErpActionTypes.CHANGE_SCHOOL_LEVEL_SELECTED:
      return iassign(state, (s) => {
        s.schoolLevelSelected = payload;
        return s;
      });
    case ErpActionTypes.UPSERT_ERP_SCHOOL_COMPLETE:
      return iassign(state, (s) => {
        const key = createKey(payload.erpSchool);
        const school = findErpSchoolbyKey(s.erpSchools, key);
        const index = s.erpSchools.indexOf(school);
        if (index >= 0) {
          s.erpSchools = iassign(s.erpSchools, (schools) => {
            schools[index] = payload.erpSchool;
            return schools;
          });
        }
        return s;
      });
    case ErpActionTypes.SELECT_PREVIOUS_ERP_SCHOOL:
      return iassign(state, (s) => {
        s.erpSchoolKeySelected = findPreviousKey(state);
        return s;
      });
    case ErpActionTypes.SELECT_NEXT_ERP_SCHOOL:
      return iassign(state, (s) => {
        s.erpSchoolKeySelected = findNextKey(state);
        return s;
      });
    case ErpActionTypes.SELECT_ERP_SCHOOL:
      return iassign(state, (s) => {
        s.erpSchoolKeySelected = payload;
        return s;
      });
    case ErpActionTypes.GET_SCHOOL_LAND_SCHOOL:
      return iassign(state, (s) => {
        s.schoolLandSchool = undefined;
        return s;
      });
    case ErpActionTypes.GET_SCHOOL_LAND_SCHOOL_COMPLETE:
      return iassign(state, (s) => {
        s.schoolLandSchool = payload.schoolLandSchool;
        return s;
      });
    case ErpActionTypes.GET_EDB_SCHOOL:
      return iassign(state, (s) => {
        s.edbSchool = undefined;
        return s;
      });
    case ErpActionTypes.GET_EDB_SCHOOL_COMPLETE:
      return iassign(state, (s) => {
        s.edbSchool = payload.edbSchool;
        return s;
      });
    case ErpActionTypes.GET_ERP_SCHOOLS:
      return iassign(state, (s) => {
        s.erpSchools = [];
        return s;
      });
    case ErpActionTypes.GET_ERP_SCHOOLS_COMPLETE:
      return iassign(state, (s) => {
        s.erpSchools = payload.schools;
        return s;
      });
    default:
      return iassign(state, (s) => {
        return s;
      });
  }
}

export function findErpSchool(erpState: ErpState): ErpSchool {
  const key = erpState.erpSchoolKeySelected;
  return findErpSchoolbyKey(erpState.erpSchools, key);
}

function findErpSchoolbyKey(
  erpSchools: ErpSchool[],
  key: ErpSchoolKey
): ErpSchool {
  return find(
    erpSchools,
    (s) =>
      s.schoolLevel === key.schoolLevel &&
      s.session === key.session &&
      s.schoolNumber === key.schoolNumber &&
      s.locationId === key.locationId
  );
}

function findPreviousKey(erpState: ErpState): ErpSchoolKey {
  const index = findSelectedErpSchoolIndex(erpState);
  if (index > 0) {
    return createKey(erpState.erpSchools[index - 1]);
  }
  return erpState.erpSchoolKeySelected;
}

function findNextKey(erpState: ErpState): ErpSchoolKey {
  const index = findSelectedErpSchoolIndex(erpState);
  if (index < erpState.erpSchools.length - 1) {
    return createKey(erpState.erpSchools[index + 1]);
  }
  return erpState.erpSchoolKeySelected;
}

function findSelectedErpSchoolIndex(erpState: ErpState): number {
  const current = findErpSchool(erpState);
  return erpState.erpSchools.indexOf(current);
}

function createKey(erpSchool: ErpSchool): ErpSchoolKey {
  return {
    schoolLevel: erpSchool.schoolLevel,
    schoolNumber: erpSchool.schoolNumber,
    session: erpSchool.session,
    locationId: erpSchool.locationId
  };
}
