import { find, some } from 'lodash-es';
import { UICulture } from '../model/ui-culture';
import { isNilOrWhiteSpace } from '../utility/string-utility';
import { UILanguage } from './ui-language';

export const supportedUILanguages: UILanguage[] = [
  { ianaCode: 'en', text: 'English', uiCulture: UICulture.AmericanEnglish },
  {
    ianaCode: 'zh-Hans',
    text: '简体中文',
    uiCulture: UICulture.SimplifiedChinese
  },
  {
    ianaCode: 'zh-Hant',
    text: '繁體中文',
    uiCulture: UICulture.TraditionalChinese
  },
  { ianaCode: 'jp', text: '日文', uiCulture: UICulture.Japanese },
  { ianaCode: 'ms', text: 'Melayu', uiCulture: UICulture.Malay },
  { ianaCode: 'id', text: 'Bahasa Indonesia', uiCulture: UICulture.Indonesian },
  { ianaCode: 'ko', text: '한국어', uiCulture: UICulture.Korean },
  { ianaCode: 'es', text: 'Español', uiCulture: UICulture.Spanish },
  { ianaCode: 'ur', text: 'اردو', uiCulture: UICulture.Urdu }
];

export function convertBrowserTagToIanaCode(browserTag: string): string {
  if (!browserTag) {
    return null;
  }

  const ianaCodes = supportedUILanguages
    .map((l) => l.ianaCode)
    .filter((c) => c === browserTag);

  if (ianaCodes && ianaCodes[0]) {
    return ianaCodes[0];
  }

  if (
    browserTag.startsWith('zh-TW') ||
    browserTag.startsWith('zh-HK') ||
    browserTag.startsWith('zh-CHT')
  ) {
    return 'zh-Hant';
  }

  if (browserTag.startsWith('zh')) {
    return 'zh-Hans';
  }

  const principalBrowserTag = getPrincipalBrowserTag(browserTag);

  if (some(ianaCodes, (code) => code === principalBrowserTag)) {
    return principalBrowserTag;
  }

  return 'en';
}

function getPrincipalBrowserTag(browserTag: string): string {
  if (isNilOrWhiteSpace(browserTag)) {
    return '';
  }
  const index = browserTag.indexOf('-');
  if (index === -1) {
    return browserTag;
  } else {
    return browserTag.substring(0, index);
  }
}

export function mapIanaCodeToUILanguage(ianaCode: string): UILanguage {
  const uiLanguage = find(supportedUILanguages, (i) => i.ianaCode === ianaCode);
  if (uiLanguage) {
    return uiLanguage;
  } else {
    return supportedUILanguages[0];
  }
}
