import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { SelectItem } from 'primeng/api';
import { SubSink } from 'subsink';
import { districtSelectItems } from '../erp/districts';
import {
  changeDistrictSelected,
  changeSchoolLevelSelected,
  getErpSchools
} from '../erp/erp-actions';
import { ErpSchool } from '../erp/erp-school';
import {
  districtSelectedSelector,
  erpSchoolsSelector,
  schoolLeveltSelectedSelector
} from '../erp/erp-state-selectors';
import { SchoolLevel } from '../erp/school-level';
import { AppState } from '../states/states-reducers';
import { getSelectItemsFromEnum } from '../utility/enum-utility';
@Component({
  selector: 'app-erp-school-browser',
  templateUrl: 'erp-school-browser.component.html',
  styles: [
    `
      table,
      tr {
        border: 1px solid black;
        border-collapse: collapse;
      }
    `
  ]
})
export class ErpSchoolBrowserComponent implements OnInit, OnDestroy {
  rowHeight = '60px';

  private subs = new SubSink();
  erpSchools: ErpSchool[];

  schoolLevelSelectItems: SelectItem[];
  districtSelectItems: SelectItem[];

  schoolLevelSelected: SchoolLevel;

  districtSelected: string;

  @Output() schoolClick = new EventEmitter<ErpSchool>();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.subs.sink = this.store
      .select(erpSchoolsSelector)
      .subscribe((values) => {
        this.erpSchools = cloneDeep(values);
      });

    this.subs.sink = this.store
      .select(schoolLeveltSelectedSelector)
      .subscribe((value) => (this.schoolLevelSelected = value));

    this.subs.sink = this.store
      .select(districtSelectedSelector)
      .subscribe((value) => (this.districtSelected = value));

    this.schoolLevelSelectItems = getSelectItemsFromEnum(SchoolLevel);
    this.districtSelectItems = districtSelectItems;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getSchools() {
    this.store.dispatch(
      getErpSchools(this.schoolLevelSelected, this.districtSelected)
    );
  }

  onDistrictChange(district: string) {
    this.store.dispatch(changeDistrictSelected(district));
    if (this.schoolLevelSelected !== SchoolLevel.unspecified) {
      this.getSchools();
    }
  }

  onSchoolLevelChange(schoolLevel: SchoolLevel) {
    this.store.dispatch(changeSchoolLevelSelected(schoolLevel));
    if (this.schoolLevelSelected !== SchoolLevel.unspecified) {
      this.getSchools();
    }
  }

  onSchoolClick(school: ErpSchool) {
    this.schoolClick.emit(school);
  }
}
