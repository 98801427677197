import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';

@Injectable()
export class PrincipalForumService{

  url : string;
  constructor(private http: HttpClientService, private config: Configuration){
    this.url = this.config.apiRootUrl + '/subscription/principalForumRegistrations';
  }

  createRegistration(registration: {name : string, positionSelected: number, originalPosition: string, whatsappOrMobileNumber: string, emailAddress: string, schoolName: string }) : Observable<any>{
    return this.http.authPost(this.url, registration);
  }
}

