import * as iassign from 'immutable-assign';
import { orderBy } from 'lodash-es';
import { ExerciseRecord } from '../model/exercise-record';
import { TestRecord } from '../model/test-record';
import { UnsafeAction } from '../model/unsafe-action';
import { ExerciseReportSubscription } from './exercise-report-subscription';
import { PhraseAttempted } from './phase-attempted';
import { ReportingActionTypes } from './reporting-actions';

export interface ReportingState {
  vocabularyByUser: PhraseAttempted[];
  exerciseReportSubscriptions: ExerciseReportSubscription[];
  exerciseRecords: ExerciseRecord[];
  testRecords: TestRecord[];
}

export const INITIAL_REPORTING_STATE: ReportingState = {
  vocabularyByUser: [],
  exerciseReportSubscriptions: [],
  exerciseRecords: null,
  testRecords: null
};

export function reportingStateReducer(
  state: ReportingState = INITIAL_REPORTING_STATE,
  { type, payload }: UnsafeAction
): ReportingState {
  switch (type) {
    case ReportingActionTypes.INSERT_EXERCISE_RECORD_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseRecords = [payload, ...s.exerciseRecords];
        return s;
      });
    case ReportingActionTypes.GET_VOCABULARY_ATTEMPTED_BY_USER:
      return iassign(state, (s) => {
        s.vocabularyByUser = [];
        return s;
      });
    case ReportingActionTypes.DELETE_WORD_FOR_USER_COMPLETE:
      return iassign(state, (s) => {
        s.vocabularyByUser = s.vocabularyByUser.filter(
          (v) => v.phrase !== payload.phrase
        );
        return s;
      });
    case ReportingActionTypes.GET_VOCABULARY_ATTEMPTED_BY_USER_COMPLETE:
      return iassign(state, (s) => {
        if (payload) {
          s.vocabularyByUser = payload;
        } else {
          s.vocabularyByUser = [];
        }
        return s;
      });
    case ReportingActionTypes.GET_EXERCISE_REPORT_SUBSCRIPTIONS_OF_SCHOOL:
      return iassign(state, (s) => {
        s.exerciseReportSubscriptions = [];
        return s;
      });
    case ReportingActionTypes.GET_EXERCISE_RECORD_REPORT_SUBSCRIPTIONS_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseReportSubscriptions = payload;
        return s;
      });
    case ReportingActionTypes.DELETE_EXERCISE_REPORT_SUBSCRIPTION_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseReportSubscriptions = s.exerciseReportSubscriptions.filter(
          (s) => s.id !== payload
        );
        return s;
      });
    case ReportingActionTypes.CLEAR_EXERCISE_RECORDS:
      return iassign(state, (s) => {
        s.exerciseRecords = null;
        return s;
      });
    case ReportingActionTypes.GET_EXERCISE_RECORDS:
      return iassign(state, (s) => {
        s.exerciseRecords = null;
        return s;
      });
    case ReportingActionTypes.GET_EXERCISE_RECORDS_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseRecords = orderBy(payload, (e) => e.completedUtc, ['desc']);
        return s;
      });
    case ReportingActionTypes.GET_USERS_EXERCISE_RECORDS:
      return iassign(state, (s) => {
        s.exerciseRecords = null;
        return s;
      });
    case ReportingActionTypes.GET_USERS_EXERCISE_RECORDS_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseRecords = payload;
        return s;
      });
    case ReportingActionTypes.CLEAR_TEST_RECORDS:
      return iassign(state, (s) => {
        s.testRecords = null;
        return s;
      });
    case ReportingActionTypes.GET_TEST_RECORDS:
      return iassign(state, (s) => {
        s.testRecords = null;
        return s;
      });
    case ReportingActionTypes.GET_TEST_RECORDS_COMPLETE:
      return iassign(state, (s) => {
        s.testRecords = payload;
        return s;
      });

    default:
      return state;
  }
}
