/* eslint-disable no-debugger */
import { Injectable } from '@angular/core';
import { isDebuggingMessage } from 'src/app/configuration';
import { ConsoleLogger } from './console-logger';
import { LogEntry } from './log-entry';
import { LogLevel } from './log-level';
import { Logger } from './logger';
import { SentryLogger } from './sentry-logger';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  loggers: Logger[] = [];

  constructor(consoleLogger: ConsoleLogger, sentryLogger: SentryLogger) {
    this.loggers = [consoleLogger, sentryLogger];
  }

  trace(msg: any, extraInfo: any = null): void {
    if (isDebuggingMessage) {
      debugger;
    } else {
      this.writeToLog(msg, LogLevel.Trace, extraInfo);
    }
  }

  debug(msg: any, extraInfo: any = null) {
    if (isDebuggingMessage) {
      debugger;
    } else {
      this.writeToLog(msg, LogLevel.Debug, extraInfo);
    }
  }

  info(msg: any, extraInfo: any = null) {
    if (isDebuggingMessage) {
      debugger;
    } else {
      this.writeToLog(msg, LogLevel.Info, extraInfo);
    }
  }

  warn(msg: any, extraInfo: any = null) {
    if (isDebuggingMessage) {
      debugger;
    } else {
      this.writeToLog(msg, LogLevel.Warn, extraInfo);
    }
  }

  error(msg: any, extraInfo: any = null) {
    if (isDebuggingMessage) {
      debugger;
    } else {
      this.writeToLog(msg, LogLevel.Error, extraInfo);
    }
  }

  fatal(msg: any, extraInfo: any = null) {
    if (isDebuggingMessage) {
      debugger;
    } else {
      this.writeToLog(msg, LogLevel.Fatal, extraInfo);
    }
  }

  private writeToLog(msg: any, level: LogLevel, extraInfo: any) {
    const entry = new LogEntry(msg, level, extraInfo);
    this.loggers.forEach((logger) => {
      logger.log(entry);
    });
  }
}
