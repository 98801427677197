import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { UnsafeAction } from '../../model/unsafe-action';
import { displayErrorMessage, displaySuccessMessage } from '../ui/ui-actions';
import {
    getProblemSetSettingComplete,
    getProblemSetSettingsComplete,
    ProblemSetSettingActionTypes
} from './problem-set-setting-actions';
import { ProblemSetSettingService } from './problem-set-setting.service';

@Injectable()
export class ProblemSetSettingEffects {
  constructor(
    private service: ProblemSetSettingService,
    private actions$: Actions
  ) {}

  @Effect()
  getProblemSetSetting$ = this.actions$.pipe(
    ofType(ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTING),
    exhaustMap((action: UnsafeAction) =>
      this.service
        .getProblemSetSetting(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((problemSet) => {
      if (problemSet) {
        return getProblemSetSettingComplete(problemSet);
      } else {
        return displayErrorMessage('Fail to get problemSetSetting');
      }
    })
  );

  @Effect()
  updateProblemSetting$ = this.actions$.pipe(
    ofType(ProblemSetSettingActionTypes.UPDATE_PROBLEM_SET_SETTING),
    exhaustMap((action: UnsafeAction) =>
      this.service
        .updateProblemSetSetting(action.payload)
        .pipe(catchError((_) => of(false)))
    ),
    map((succeeded) => {
      if (succeeded) {
        return displaySuccessMessage('Exercise Settings Updated');
      } else {
        return displayErrorMessage('Fail to update Exercise Settings');
      }
    })
  );

  @Effect()
  getProblemSetSettings = this.actions$.pipe(
    ofType(ProblemSetSettingActionTypes.GET_PROBLEM_SET_SETTINGS),
    exhaustMap((_) =>
      this.service.getProblemSetSettings().pipe(catchError((_) => of(null)))
    ),
    map((problemSetSettings) => {
      if (problemSetSettings) {
        return getProblemSetSettingsComplete(problemSetSettings);
      } else {
        return displayErrorMessage('Fail to get problemSetSettings');
      }
    })
  );
}
