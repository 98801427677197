import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';
import { Configuration } from '../configuration';
import { selectedSyllabusLanguageSelector } from '../core/syllabus/syllabus-state-selectors';
import { TranslationService } from '../core/translation/translation.service';
import {
  alwaysPronounceVocabularyQuestionSelector,
  dictionaryLevelSelector,
  enableQuestionThrottingSelector
} from '../core/user/user-preference-selectors';
import { newVocabularyHardLimitSelector } from '../core/user/user-state-selectors';
import { DynamicDialogConfigBuilder } from '../dynamic-dialog-config-builder';
import { uiCultureSelector } from '../i18n/i18n-state-selectors';
import { Language } from '../model/language';
import { Level } from '../model/level';
import { UICulture } from '../model/ui-culture';
import { AppState } from '../states/states-reducers';
import { startVocabExercise } from '../vocab/vocab-exercise/vocab-exercise-actions';
import { newWordCountSelector } from '../vocab/vocab-state-selectors';

export function showExericseReminderDialog(dialogService: DialogService) {
  const builder = new DynamicDialogConfigBuilder();
  builder.maxHeight = '92%';
  builder.overflow_x = 'hidden';
  dialogService.open(
    VocabExerciseReminderDialogComponent,
    builder.build()
  );
}

@Component({
  selector: 'app-vocab-exercise-reminder-dialog',
  templateUrl: './vocab-exercise-reminder-dialog.component.html'
})
export class VocabExerciseReminderDialogComponent implements OnInit, OnDestroy {
  newWordCount: number;
  newVocabularyHardLimit: number;
  newVocabularySoftLimit: number;
  syllabusLanguage: Language;
  private subs = new SubSink();
  enableQuestionThrottling: boolean;
  uiCulture: UICulture;
  dictionaryLevel: Level;
  alwaysPronounceVocabularyQuestion: boolean;
  @Output() closed = new EventEmitter();
  hardLimitReminderTemplate: string;
  softLimitReminderTemplate: string;

  constructor(
    private store: Store<AppState>, config : Configuration, translation: TranslationService,
    private dialogRef: DynamicDialogRef) {
    this.newVocabularySoftLimit = config.newVocabularySoftLimit;
    this.hardLimitReminderTemplate = translation.getTranslationInstant('vocabulary.exercise-hard-limit-reminder-template');
    this.softLimitReminderTemplate = translation.getTranslationInstant('vocabulary.exercise-soft-limit-reminder-template');
  }

  ngOnInit() {
    this.subs.sink = this.store
    .select(newVocabularyHardLimitSelector)
    .subscribe((value => this.newVocabularyHardLimit = value));
    this.subs.sink = this.store
      .select(newWordCountSelector)
      .subscribe((value) => (this.newWordCount = value));
    this.subs.sink = this.store
      .select(alwaysPronounceVocabularyQuestionSelector)
      .subscribe((value) => (this.alwaysPronounceVocabularyQuestion = value));
    this.subs.sink = this.store
      .select(selectedSyllabusLanguageSelector)
      .subscribe((value) => (this.syllabusLanguage = value));
    this.subs.sink = this.store
      .select(enableQuestionThrottingSelector)
      .subscribe((value) => (this.enableQuestionThrottling = value));
    this.subs.sink = this.store
      .select(uiCultureSelector)
      .subscribe((value) => (this.uiCulture = value));
    this.subs.sink = this.store
      .select(dictionaryLevelSelector)
      .subscribe((value) => (this.dictionaryLevel = value));
  }

  get hardLimitReminderMessage() : string {
    return this.hardLimitReminderTemplate.replace('xxxxxxxx', this.newWordCount.toString());
  }

  get softLimitReminderMessage() : string{
    return this.softLimitReminderTemplate.replace('xxxxxxxx', this.newWordCount.toString());;
  }

  get showHardLimitReminderMessage() : boolean {
    return this.newWordCount >= this.newVocabularyHardLimit;
  }

  get showSoftLimitReminderMessage() : boolean {
    return !this.showHardLimitReminderMessage && this.newWordCount >= this.newVocabularySoftLimit;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onDoExerciseButtonClick() {
    this.store.dispatch(
      startVocabExercise(
        this.syllabusLanguage,
        this.enableQuestionThrottling,
        this.uiCulture,
        this.dictionaryLevel,
        this.alwaysPronounceVocabularyQuestion
      )
    );
    this.dialogRef.close();
  }

  cancelDialog() {
    this.dialogRef.close();
  }
}
