import { createSelector } from '@ngrx/store';
import { isNil, orderBy } from 'lodash-es';
import { uiCultureSelector } from '../../i18n/i18n-state-selectors';
import { defaultVocabularyPronunciationsSelected } from '../../model/language-pronunciations-map';
import { UICulture } from '../../model/ui-culture';
import { Syllabus } from '../../problem-set/model/syllabus';
import { appStateSelector } from '../../states/state-selectors';
import { AppState } from '../../states/states-reducers';
import { vocabularyPronunciationsSelector } from '../user/user-preference-selectors';

const syllabusStateSelector = (state: AppState) => state.syllabus;

export const detailedSyllabusSelector = createSelector(
  syllabusStateSelector,
  (state) => state.detailedSyllabus
);

export const syllabiSelector = createSelector(
  syllabusStateSelector,
  (state) => state.syllabi
);

export const syllabusSelector = createSelector(
  syllabiSelector,
  (syllabi: Syllabus[], syllabusId: string) => {
    return syllabi.find((s) => s.id === syllabusId);
  }
);

export const syllabusDescriptionSelector = createSelector(
  appStateSelector,
  (appState: AppState, syllabusId: string | null) => {
    const syllabus = syllabusSelector(appState, syllabusId);
    if (syllabus) {
      const uiCulture = uiCultureSelector(appState);
      let description = syllabus.descriptions.find(
        (d) => d.culture === uiCulture
      );
      if (!description) {
        description = syllabus.descriptions.find(
          (d) => d.culture === UICulture.BritishEnglish
        );
      }
      return description;
    }
    return null;
  }
);

export const syllabusDescriptionTextSelector = createSelector(
  appStateSelector,
  (appState: AppState, syllabusId: string) => {
    const description = syllabusDescriptionSelector(appState, syllabusId);
    if (description) {
      return description.description;
    }
    return null;
  }
);

export const syllabusNameSelector = createSelector(
  appStateSelector,
  (appState: AppState, syllabusId: string | null) => {
    const description = syllabusDescriptionSelector(appState, syllabusId);
    if (description) {
      return description.name;
    }
    return null;
  }
);

export const isVocabularySyllabusSelector = createSelector(
  syllabiSelector,
  (syllabi: Syllabus[], syllabusId: string) => {
    const syllabus = syllabi.find((s) => s.id === syllabusId);
    if (syllabus) {
      return syllabus.examinationBoard === 'Vocabulary';
    } else {
      return false;
    }
  }
);

export const syllabusAdminRegistrationsSelector = createSelector(
  syllabusStateSelector,
  (state) => state.syllabusAdminRegistrations
);

export const syllabusSelectItemsSelector = createSelector(
  appStateSelector,
  (state) =>
    orderBy(
      state.syllabus.syllabi.map((s) => {
        return {
          label: syllabusNameSelector(state, s.id),
          value: s.id
        };
      }),
      (item) => item.label
    )
);

export const selectedSyllabusLanguageSelector = createSelector(
  syllabusStateSelector,
  (state) => state.selectedSyllabusLanguage
);

export const calculatedSyllabusPronunciationSelector = createSelector(
  appStateSelector,
  (appState) => {
    const selectedSyllabusLanguage = selectedSyllabusLanguageSelector(appState);
    const vocabularyPronunciations = vocabularyPronunciationsSelector(appState);
    let pronunciation = vocabularyPronunciations[selectedSyllabusLanguage];
    if (isNil(pronunciation)) {
      pronunciation =
        defaultVocabularyPronunciationsSelected[selectedSyllabusLanguage];
    }
    return pronunciation;
  }
);
