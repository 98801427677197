import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { Observable, filter, from, merge, of } from 'rxjs';
import { Configuration } from '../../configuration';
import { UserPreference } from '../../model/user-preference';
import { HttpClientService } from '../http-client.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserPreferenceService {
  urlRoot: string;

  constructor(private config: Configuration, private http: HttpClientService, private localStorageService: LocalStorageService) {
    this.urlRoot = this.config.apiRootUrl + '/subscription/userPreferences';
  }

  getUserPreference(): Observable<UserPreference> {
    const fromLocalService = from(this.localStorageService.get(this.config.userPreferenceKey));
    const fromUrl = this.http.authGet<UserPreference>(this.urlRoot);
    const merged = merge(fromLocalService, fromUrl);
    return merged.pipe(filter(value => !!value));
  }

  upsertUserPreference(preference: UserPreference): Observable<any> {
    this.localStorageService.put(this.config.userPreferenceKey, preference).catch();
    if (!isNil(preference.uiLanguageCode)) {
      return this.http.authPost(this.urlRoot, preference);
    } else {
      return of(preference);
    }
  }
}
