import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { OperationResult } from '../../model/operation-result';
import { SyllabusAdminRegistration } from '../../model/syllabus-admin-registration';
import { Syllabus } from '../../problem-set/model/syllabus';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class SyllabusService {
  constructor(
    private httpService: HttpClientService,
    private config: Configuration
  ) {}

  createSyllabus(syllabus: Syllabus): Observable<boolean> {
    const url = this.config.apiRootUrl + '/problemSet/syllabi';
    return this.httpService
      .authPost<OperationResult>(url, syllabus)
      .pipe(map((r) => r.success));
  }

  getSyllabiWithSyllabusAdminRights(payload: any): Observable<Syllabus[]> {
    let url = this.config.apiRootUrl + '/problemSet/syllabi?admin=true';
    if (payload['schoolId']) {
      url += '&schoolId=' + payload['schoolId'];
    }
    return this.httpService.authGet<Syllabus[]>(url);
  }

  getDetailedSyllabus(syllabusId: string): Observable<Syllabus> {
    const url =
      this.config.apiRootUrl +
      `/problemSet/syllabi/${syllabusId}?withLines=true`;
    return this.httpService.authGet<Syllabus>(url);
  }

  updateSyllabus(syllabus: Syllabus): Observable<boolean> {
    const url = this.config.apiRootUrl + '/problemSet/syllabi';
    return this.httpService
      .authPut<OperationResult>(url, syllabus)
      .pipe(map((r) => r.success));
  }

  createSyllabusAdminRegistration(
    syllabusAdminRegistration: SyllabusAdminRegistration
  ): Observable<boolean> {
    const url =
      this.config.apiRootUrl + '/problemSet/syllabusAdminRegistrations';
    return this.httpService
      .authPost<OperationResult>(url, syllabusAdminRegistration)
      .pipe(map((r) => r.success));
  }

  getSyllabusAdminRegistrations(
    syllabusId: string
  ): Observable<SyllabusAdminRegistration[]> {
    const url =
      this.config.apiRootUrl +
      `/problemSet/syllabusAdminRegistrations/${syllabusId}`;
    return this.httpService.authGet<SyllabusAdminRegistration[]>(url);
  }

  getSyllabiBySyllabusIds(syllabusIds: string[]): Observable<Syllabus[]> {
    const baseUrl = this.config.apiRootUrl + '/problemSet/syllabi/';
    const observables: Observable<Syllabus>[] = syllabusIds.map((id) =>
      this.httpService.authGet(baseUrl + id)
    );
    return combineLatest<Syllabus[]>(observables);
  }

  getSyllabi(): Observable<Syllabus[]> {
    const url = this.config.apiRootUrl + '/problemSet/syllabi';
    return this.httpService.authGet<Syllabus[]>(url);
  }
}
