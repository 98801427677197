/* eslint-disable no-debugger */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { some } from 'lodash-es';
import { Subscription } from 'rxjs';
import { isDebuggingNavigation, legitimateLandingPreferenceTriggerHrefs } from 'src/app/configuration';
import { AppState } from 'src/app/states/states-reducers';
import { landingPreferenceSelector } from '../user/user-preference-selectors';

const NOT_BACKABLE_URLS: string[] = [
  '/problem-set/menu/exercise?',
  '/vocabulary/exercise'
];

const baseUrlRegex = /^.*(?=\?)/;

const HOME_URL = '/home';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  navigationStack: string[] = [];
  landingPreference: string;
  currentUrl: string;
  sub: Subscription;

  navigatedToLandingPage = false;

  constructor(private router: Router, store: Store<AppState>) {
    this.sub = store.select(landingPreferenceSelector).subscribe((value) => {
      this.landingPreference = value;
      this.navigateToLandingPageIfNever();
    });
  }

  get isLegitimateLandingPreferenceTriggerHref() : boolean{
    const url = window.location.pathname;
    const baseUrl = this.getBaseUrl(url);
    return some(legitimateLandingPreferenceTriggerHrefs, p => p === baseUrl);
  }

  navigateToLandingPageIfNever(){
    if(!!this.landingPreference && !this.navigatedToLandingPage){
      this.navigatedToLandingPage = true;
      if(this.isLegitimateLandingPreferenceTriggerHref){
        this.router.navigateByUrl(this.landingPreference);
      }
    }
  }

  onNavigated(url: string) {
    if (!url){
      return;
    }

    if (isDebuggingNavigation) {
      debugger;
    }

    this.navigateToLandingPageIfNever();

    if (url === HOME_URL) {
      this.navigationStack = [HOME_URL];
    } else if (!this.HasTheSameUrlAsLast(url)) {
      this.navigationStack.push(url);
    }

    this.currentUrl = url;
  }

  clearMeaningLookups() {
    this.navigationStack = this.navigationStack.filter(
      (v) => !v.startsWith('/vocabulary/meaning-lookup/')
    );
  }

  removeMeaningLookup(phrase: string) {
    this.navigationStack = this.navigationStack.filter(
      (v) => !v.startsWith('/vocabulary/meaning-lookup/' + encodeURI(phrase))
    );
  }

  goBack() {
    const last = this.navigationStack.pop();

    while (last === this.navigationStack[this.navigationStack.length - 1]) {
      this.navigationStack.pop();
    }

    this.removeNoBackableUrls();

    if (this.lastUrl) {
      this.router.navigateByUrl(this.lastUrl);
    } else {
      this.router.navigateByUrl(HOME_URL);
    }
  }

  private removeNoBackableUrls() {
    while (
      !this.isBackableUrl(this.lastUrl) &&
      this.navigationStack.length > 0
    ) {
      this.navigationStack.pop();
    }
  }

  private HasTheSameUrlAsLast(url: string): boolean {
    return url === this.lastUrl;
  }

  private getBaseUrl(url: string): string {
    const match = baseUrlRegex.exec(url);
    if (match) {
      return match[0];
    } else {
      return url;
    }
  }

  private get lastUrl(): string {
    const stackLength = this.navigationStack.length;
    if (this.navigationStack.length > 0) {
      return this.navigationStack[stackLength - 1];
    }
    return undefined;
  }

  private isBackableUrl(url: string) {
    if (url) {
      return !some(NOT_BACKABLE_URLS, (nonBackableUrl) =>
        this.hasTheSameBaseUrl(url, nonBackableUrl)
      );
    } else {
      return false;
    }
  }

  private hasTheSameBaseUrl(url1: string, url2: string): boolean {
    const baseUrl1 = this.getBaseUrl(url1);
    const baseUrl2 = this.getBaseUrl(url2);
    return baseUrl1 === baseUrl2;
  }
}
