import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../configuration';
import { Exercise } from '../../model/exercise';
import { UICulture } from '../../model/ui-culture';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class ExerciseService {
  constructor(private http: HttpClientService, private config: Configuration) {}

  getExercise({
    syllabusId,
    skipQuestionsRequireCalculator,
    isTest,
    enableQuestionThrottling,
    hasUnlimitedAccess,
    uiCulture
  }: {
    syllabusId: string;
    skipQuestionsRequireCalculator: boolean;
    isTest: boolean;
    enableQuestionThrottling: boolean;
    hasUnlimitedAccess: boolean;
    uiCulture: UICulture;
  }): Observable<Exercise> {
    const url =
      this.config.apiRootUrl +
      '/problemset/exercises/' +
      uiCulture +
      '?syllabusId=' +
      syllabusId +
      '&skipQuestionsRequireCalculator=' +
      skipQuestionsRequireCalculator +
      '&isTest=' +
      isTest +
      '&enableQuestionThrottling=' +
      enableQuestionThrottling +
      '&hasUnlimitedAccess=' +
      hasUnlimitedAccess;
    return this.http.authGet<Exercise>(url);
  }
}
