import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Contact } from '../erp/contact';
import { JobTitle } from '../erp/job-title';
import { ResponsibilityArea } from '../erp/responsibility-area';

@Component({
  selector: 'app-contacts-edit',
  templateUrl: './contacts-edit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContactsEditComponent)
    }
  ]
})
export class ContactsEditComponent implements ControlValueAccessor {
  contacts: Contact[];

  registerOnChange(fn: (_: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(value: any): void {
    this.contacts = value;
    this.onChange(value);
  }

  onAddButtonClick() {
    const newContact = this.createContact();
    this.contacts = [...this.contacts, newContact];
    this.onChange(this.contacts);
  }

  onContactChange(_: Contact) {
    this.onChange(this.contacts);
  }

  createContact(): Contact {
    return {
      jobTitle: JobTitle.Unspecified,
      responsibilityArea: ResponsibilityArea.Unspecified,
      lastUpdated: new Date(),
      nameInChinese: '',
      nameInEnglish: '',
      emailAddress: '',
      telephoneNumber: '',
      isExpired: false,
      remark: '',
      contactHistory: []
    };
  }
}
