/* eslint-disable no-debugger */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { isDebuggingMessage } from './app/configuration';
import { environment } from './environments/environment';
import { sentryInit } from './sentry-init';

const script = document.createElement('script');
script.setAttribute('src', environment.payPalScriptSrc);
const head = document.head || document.getElementsByTagName('head')[0];
head.appendChild(script);

if (navigator.userAgent.includes('Version/17.0') || navigator.userAgent.includes('Version/17.1')) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

sentryInit();
if (environment.envName === 'production') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    if (isDebuggingMessage) {
      debugger;
    } else {
      console.error('main.ts', err);
    }
  });
