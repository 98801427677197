<div class="grid">
  <div class="col-2">
    <button
      pButton
      type="button"
      label="Prev"
      (click)="onPrevButtonClick()"
    ></button>
  </div>

  <div class="col-2">
    <button
      pButton
      type="button"
      label="Next"
      (click)="onNextButtonClick()"
    ></button>
  </div>

  <div class="col-4">
    <button
      pButton
      type="button"
      label="Management"
      (click)="onManagementButtonClick()"
    ></button>
  </div>

  <div class="col-4">
    <button
      pButton
      type="button"
      [label]="modeLabel"
      (click)="onModeButtonClick()"
    ></button>
  </div>

  <div class="col-12">
    <app-erp-school-display
      [erpSchool]="erpSchool"
      *ngIf="!showEdit"
    ></app-erp-school-display>
    <app-erp-school-edit [ngModel]="erpSchool" *ngIf="showEdit">
    </app-erp-school-edit>
  </div>
</div>
