import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef
} from 'primeng/dynamicdialog';
import { from, Observable } from 'rxjs';
import { VocabUpgradePromptComponent } from '../core/vocabulary/vocab-upgrade-prompt.component';
import { ErpEffects } from '../erp/erp-effects';
import { ErpModule } from '../erp/erp.module';
import { I18nEffects } from '../i18n/i18n-effects';
import { MembershipPurchaseEffects } from '../purchase/membership-purchase-effects';
import { SharedModule } from '../shared/shared.module';
import { DictionaryEffects } from '../smart-ui/dictionary/dictionary-effects';
import { PronunciationEffects } from '../smart-ui/pronunciation/pronunciation-effects';
import { ThumbnailBandEffects } from '../smart-ui/thumbnail/thumbnail-band-effects';
import { SubscriptionEffects } from '../subscription/subscription-effects';
import { ExerciseEffects } from './exercise/exercise-effects';
import { GzipInterceptor } from './gzip-interceptor';
import { NavigationEffects } from './navigation/navigation.effects';
import { NotificationEffects } from './notification/notification-effects';
import { ProblemSetSettingEffects } from './syllabus/problem-set-setting-effects';
import { SyllabusEffects } from './syllabus/syllabus-effects';
import { UiEffects } from './ui/ui-effects';
import { AuthInterceptor } from './user-authentication-intercepter';
import { MenteeEffects } from './user/mentee-effects';
import { UserEffects } from './user/user-effects';
import { UserPreferenceEffects } from './user/user-preference-effects';
import { VocabularyEffects } from './vocabulary/vocab-core-effects';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../../translations/${lang}.json`));
  }
}

@NgModule({
  imports: [
    ErpModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    DynamicDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),

    BrowserTransferStateModule,
    //core effects have to be defined here
    EffectsModule.forRoot([
      MembershipPurchaseEffects,
      UserEffects,
      UiEffects,
      SubscriptionEffects,
      PronunciationEffects,
      DictionaryEffects,
      ThumbnailBandEffects,
      SyllabusEffects,
      ExerciseEffects,
      I18nEffects,
      NotificationEffects,
      NavigationEffects,
      MenteeEffects,
      UserPreferenceEffects,
      ProblemSetSettingEffects,
      ErpEffects,
      VocabularyEffects
    ])
  ],
  declarations: [VocabUpgradePromptComponent],
  providers: [
    ConfirmationService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GzipInterceptor, multi: true },

    DynamicDialogRef,
    DynamicDialogConfig,
    DialogService
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
