import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '../../core/navigation/navigation.service';

@Directive({
  selector: '[appGoBack]'
})
export class GoBackDirective {
  constructor(private navigation: NavigationService) {}

  @HostListener('click')
  onClick() {
    this.navigation.goBack();
  }
}
