<div class="col-12">
  <div class="col-12 tiny-black-border" *ngIf="contact">
    <div class="grid ">
      <div class="col-6">
        Last Updated : {{ contact.lastUpdated | date: "yyyy-MM-dd" }}
      </div>
      <div class="col-6">
        <p-checkbox
          [(ngModel)]="contact.isExpired"
          [binary]="true"
          (ngModelChange)="onContactChange()"
        ></p-checkbox>
        IsExpired
      </div>
      <div class="col-6">
        <p-dropdown
          [options]="jobTitleSelectItems"
          [(ngModel)]="contact.jobTitle"
          (ngModelChange)="onContactChange()"
        ></p-dropdown>
      </div>
      <div class="col-6">
        <p-dropdown
          [options]="responsibilityAreaSelectItems"
          [(ngModel)]="contact.responsibilityArea"
          (ngModelChange)="onContactChange()"
        ></p-dropdown>
      </div>
      <div class="col-6">
        Chinese Name :
        <input
          pInputText
          type="text"
          [(ngModel)]="contact.nameInChinese"
          (ngModelChange)="onContactChange()"
        />
      </div>
      <div class="col-6">
        English Name :
        <input
          pInputText
          type="text"
          [(ngModel)]="contact.nameInEnglish"
          (ngModelChange)="onContactChange()"
        />
      </div>
      <div class="col-6">
        Email :
        <input
          pInputText
          type="text"
          [(ngModel)]="contact.emailAddress"
          (ngModelChange)="onContactChange()"
        />
      </div>
      <div class="col-6">
        Telephone :
        <input
          pInputText
          type="text"
          [(ngModel)]="contact.telephoneNumber"
          (ngModelChange)="onContactChange()"
        />
      </div>
      <div class="col-12">
        Remark :
        <input
          pInputText
          type="text"
          [(ngModel)]="contact.remark"
          (ngModelChange)="onContactChange()"
        />
      </div>
    </div>

    <div class="col-12">
      <app-contact-history-edit
        [(ngModel)]="contact.contactHistory"
        (ngModelChange)="onContactHistoryChange($event)"
      >
      </app-contact-history-edit>
    </div>
  </div>
</div>
