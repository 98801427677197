import { UnsafeAction } from '../model/unsafe-action';
import { type } from '../utility/type';
import { MembershipPurchaseOption } from './membership-purchase-option';

export const MembershipPurchaseActionTypes = {
  GET_MEMBERSHIP_PURCHASE_OPTIONS: type(
    '[Membership Purchase] Get Membership Purchase Options'
  ),
  GET_MEMBERSHIP_PURCHASE_OPTIONS_COMPLETE: type(
    '[Membership Purchase] Get Membership Purchase Options Complete'
  )
};

export function getMembershipPurchaseOptions(): UnsafeAction {
  return {
    type: MembershipPurchaseActionTypes.GET_MEMBERSHIP_PURCHASE_OPTIONS
  };
}

export function getMembershipPurchaseOptionsComplete(
  membershipPurchaseOptions: MembershipPurchaseOption[]
): UnsafeAction {
  return {
    type: MembershipPurchaseActionTypes.GET_MEMBERSHIP_PURCHASE_OPTIONS_COMPLETE,
    payload: membershipPurchaseOptions
  };
}
