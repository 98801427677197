import { MenuItem, PrimeIcons } from 'primeng/api';

export const accountMenu: MenuItem = {
  label: 'text.account',
  icon: PrimeIcons.USER,
  items: [
    {
      label: 'account.account-settings',
      icon: 'fas fa-cog',
      routerLink: ['/account/settings']
    },
    {
      label: 'account.change-password',
      icon: 'pi pi-lock',
      routerLink: ['/account/changePassword']
    },
    {
      label: 'account.change-email-address',
      icon: 'pi pi-envelope',
      routerLink: ['/account/changeEmailAddress']
    },
    {
      label: 'account.forget-create-password',
      icon: 'pi pi-exclamation-circle',
      routerLink: ['/signUp/forgetPassword']
    },
    {
      label: 'account.registration-code',
      icon: 'fas fa-qrcode',
      routerLink: ['/account/registrationCode']
    },
    {
      label: 'account.exercise-completion-notification',
      icon: 'fas fa-bell',
      routerLink: ['/account/exerciseCompletionNotification']
    },
    {
      label: 'account.regular-report-subscription',
      icon: 'pi pi-user-edit',
      routerLink: ['/account/regularReportSubscription']
    },
    {
      label: 'notification.notification',
      icon: 'far fa-comment',
      routerLink: ['/account/notifications']
    },
    {
      label: 'account.service-upgrade',
      icon: 'fas fa-shopping-cart',
      routerLink: ['/purchase/home']
    }
  ]
};
