import { LogEntry } from './log-entry';
import { LogLevel } from './log-level';

export abstract class Logger {
  protected logLevel: LogLevel = LogLevel.Trace;

  public abstract log(entry: LogEntry): void;

  protected shouldLog(entry: LogEntry): boolean {
    if (this.logLevel === LogLevel.Off) return false;
    if (entry.level < this.logLevel) return false;
    if (this.isHttpNotFound(entry.message)) return false;
    if (this.HasNotAllowedErrorMessage(entry.message)) return false;
    return true;
  }

  private isHttpNotFound(message: any) {
    return message.status === 404;
  }

  private HasNotAllowedErrorMessage(msg: any) {
    const message = msg.message as string;
    return message && message.indexOf('NotAllowedError') > -1;
  }
}
