import { UnsafeAction } from '../model/unsafe-action';

export interface SystemState {}

export const INITIAL_SYSTEM_STATE: SystemState = {};

export function systemStateReducer(
  state: SystemState = INITIAL_SYSTEM_STATE,
  { type }: UnsafeAction
): SystemState {
  switch (type) {
    default:
      return state;
  }
}
