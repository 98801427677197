<h1 class="page-title">
  {{ 'principal-forum.thank-you-for-registration' | translate }}
</h1>

<br />

<div style="padding-left: 15px">
  <h1 class="page-subtitle">{{ 'principal-forum.time' | translate }}</h1>
  <h1 class="page-subtitle">{{ 'principal-forum.place' | translate }}</h1>

  <h1 class="page-subtitle">
    {{ 'principal-forum.program-title' | translate }}
  </h1>

  <div style="padding-left: 20px;" innerHTML="{{'principal-forum.message1'| translate}}"></div>

  <div style="padding-left: 20px; margin: 5px;">
    <p>
    <a href="https://www.solidmemory.com/Video/LionsCollegeInterview2023-3min.mp4" target="_blank">{{'principal-forum.3-min-version'| translate}}</a>
  </p>
  </div>

  <div style="padding-left: 20px; margin: 5px;">
    <p>
    <a href="https://www.solidmemory.com/Video/LionsCollegeInterview2023-18min.mp4" target="_blank">{{'principal-forum.18-min-full-version' | translate}}</a>
    </p>
  </div>

  <div style="padding-left: 20px;" innerHTML="{{'principal-forum.message2'| translate}}"></div>

  <br />

  <div style="padding-left: 20px;" >
    <p>
    <a href="https://www.solidmemory.com/Video/SolidMemoryAnimation2023.mp4?ngsw-bypass=true" target="_blank">{{'principal-forum.solidmemory-introduction-animation' | translate}}</a>
    </p>
  </div>

</div>

<br />

<div class="grid col-12">

  <div class="col-12">
    <app-material-button
      buttonClass="p-button-primary"
      [routerLink]="['/principalForumRegistration']"
      icon="fas fa-envelope"
      text="{{
        'principal-forum.principal-symposium-registration' | translate
      }}"
    ></app-material-button>
  </div>

  <div class="col-12">
    <app-faq-button></app-faq-button>
  </div>

  <div class="col-12">
    <app-go-home-button></app-go-home-button>
  </div>

  <div class="col-12">
    <h3 class="page-subtitle">
      {{ 'registration.whatsapp-or-telephone-enquiry' | translate }}
    </h3>
  </div>
</div>
