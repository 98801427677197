import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HtmlEditorService,
  ImageService,
  RichTextEditorAllModule,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import { HtmlDisplayComponent } from '../rich-text-html-editor-ui/html-display.component';
import { HtmlEditorComponent } from '../rich-text-html-editor-ui/html-editor.component';
import { HtmlsEditorComponent } from '../rich-text-html-editor-ui/htmls-editor.component';
import { KatexModule } from 'ng-katex';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    RichTextEditorAllModule,
    CommonModule,
    KatexModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule
  ],
  declarations: [
    HtmlEditorComponent,
    HtmlsEditorComponent,
    HtmlDisplayComponent
  ],
  exports: [HtmlDisplayComponent, HtmlEditorComponent, HtmlsEditorComponent],
  providers: [ToolbarService, ImageService, HtmlEditorService]
})
export class RichTextHtmlEditorModule {}
