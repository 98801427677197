<h1 class="page-title">
  {{ 'principal-forum.principal-symposium-registration' | translate }}
</h1>

<div style="padding-left: 15px;">
  <h1 class="page-subtitle">{{ 'principal-forum.time' | translate }}</h1>
  <h1 class="page-subtitle">{{ 'principal-forum.place' | translate }}</h1>
  <h1 class="page-subtitle">{{ 'principal-forum.program-title' | translate }}</h1>

  <div style="padding-left: 20px;" innerHTML="{{'principal-forum.message1'| translate}}"></div>

  <div style="padding-left: 20px; margin: 5px;">
    <p>
    <a href="https://www.solidmemory.com/Video/LionsCollegeInterview2023-3min.mp4" target="_blank">{{'principal-forum.3-min-version'| translate}}</a>
  </p>
  </div>

  <div style="padding-left: 20px; margin: 5px;">
    <p>
    <a href="https://www.solidmemory.com/Video/LionsCollegeInterview2023-18min.mp4" target="_blank">{{'principal-forum.18-min-full-version' | translate}}</a>
    </p>
  </div>

  <div style="padding-left: 20px;" innerHTML="{{'principal-forum.message2'| translate}}"></div>

  <br />

  <div style="padding-left: 20px;" >
    <p>
    <a href="https://www.solidmemory.com/Video/SolidMemoryAnimation2023.mp4?ngsw-bypass=true" target="_blank">{{'principal-forum.solidmemory-introduction-animation' | translate}}</a>
    </p>
  </div>


  <h1 class="page-subtitle">{{ 'principal-forum.sharing-guests' | translate }} :</h1>
</div>

<div class="grid col-12">
  <table>
    <tr>
      <td height="200px">
        <img
          style="display: block"
          width="100%"
          height="100%"
          src="https://www.solidmemory.com/assets/images/ProfilePhoto-PrincipalKwok.jpg"
        />
      </td>
      <td height="200px">
        <img
          sstyle="display:block;"
          width="100%"
          height="100%"
          src="https://www.solidmemory.com/assets/images/ProfilePhoto-PrincipalCheung.jpg"
        />
      </td>
      <td height="200px">
        <img
          sstyle="display:block;"
          width="100%"
          height="100%"
          src="https://www.solidmemory.com/assets/images/ProfilePhoto-GraceWong.jpg"
        />
      </td>
    </tr>
    <tr>
      <td
        style="text-align: center; vertical-align: middle; padding-bottom: 5px"
      >
        {{ 'principal-forum.speaker1-name' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle; padding-bottom: 5px"
      >
        {{ 'principal-forum.speaker2-name' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle; padding-bottom: 5px"
      >
        {{ 'principal-forum.speaker3-name' | translate }}
      </td>
    </tr>
    <tr>
      <td
        style="text-align: center; vertical-align: middle"
        innerHTML="{{ 'principal-forum.speaker1-title' | translate }}"
      ></td>
      <td
        style="text-align: center; vertical-align: middle"
        innerHTML="{{ 'principal-forum.speaker2-title' | translate }}"
      ></td>
      <td
        style="text-align: center; vertical-align: middle"
        innerHTML="{{ 'principal-forum.speaker3-title' | translate }}"
      ></td>
    </tr>
  </table>
</div>

<br />

<h1 class="page-title">
  <div>{{ 'registration.registration' | translate }}</div>
</h1>
<div class="col-12">
  <div class="grid col-12">

    <div class="col-4">
           {{'registration.position' | translate}} : <p-dropdown [options]="positions" [(ngModel)]="positionSelected"></p-dropdown>
    </div>

    <div class="col-8">
      <span class="ui-float-label" *ngIf="positionSelected === 2">
        <input id="original-position" type="text" size="30" pInputText [(ngModel)]="originalPosition">
        <label for="original-position">{{'registration.original-position' | translate}}</label>
      </span>
    </div>

      <div class="col-6">
          <span class="ui-float-label">
              <input id="name" type="text" size="30" pInputText [(ngModel)]="name">
              <label for="name">{{'registration.name' | translate}}</label>
          </span>
      </div>

      <div class="col-6">
          <span class="ui-float-label">
              <input id="postion" type="text" size="30" pInputText [(ngModel)]="whatsappOrMobileNumber">
              <label for="position">{{'registration.whatsapp-or-mobile-number' | translate}}</label>
          </span>
      </div>

      <div class="col-12">
          <span class="ui-float-label">
              <input id="emailAddress" type="text" size="30" pInputText [(ngModel)]="emailAddress">
              <label for="emailAddress">{{'account.email-address' | translate}}</label>
          </span>
      </div>

      <div class="col-12">
          <span class="ui-float-label">
              <input id="repeatEmailAddress" type="text" size="30" pInputText [(ngModel)]="repeatEmailAddress">
              <label for="repeatEmailAddress">{{'account.repeat-email-address' | translate}}</label>
          </span>
      </div>

      <div class="col-12">
          <span class="ui-float-label">
              <input id="schoolName" type="text" size="30" pInputText [(ngModel)]="schoolName">
              <label for="schoolName">{{'registration.school-name' | translate}}</label>
          </span>
      </div>


  </div>

  <div class="col-12">
      <app-send-button (click)="onSubmitButtonClick()"></app-send-button>
  </div>

  <div class="col-12">
      <app-material-button buttonClass="p-button-info"
      [routerLink]="['/support']"
      icon="fas fa-envelope"
          text="{{'text.enquiry' | translate}}"></app-material-button>
  </div>

  <div class="col-12">
    <h3 class="page-subtitle">
      {{'registration.whatsapp-or-telephone-enquiry' | translate}}
    </h3>
  </div>
</div>



