import { Exception } from './exception';
import { ExceptionType } from './exception-type';

export class NoStateInReponseException implements Exception {
  constructor(
    public status?: number,
    public statusText?: string,
    public responseType?: ResponseType,
    public url?: string
  ) {}

  type = ExceptionType.NoStateInResponse;
}
