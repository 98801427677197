import { User } from 'oidc-client';
import { CustomReportAttributes } from 'src/app/model/custom-report-attributes';
import { SchoolAdminRegistration } from 'src/app/model/school-admin-registration';
import { SchoolPreference } from 'src/app/model/school-preference';
import { SchoolUser } from '../../model/school-user';
import { SyllabusAdminRegistration } from '../../model/syllabus-admin-registration';
import { UnsafeAction } from '../../model/unsafe-action';
import { UserInfo } from '../../model/user-info';
import { type } from '../../utility/type';

export const UserActionTypes = {
  SIGNIN: type('[User] Signin'),
  INIT_SIGNOUT: type('[User] Init Signout'),
  SIGNOUT: type('Signout'),
  CLEAR_USER_STATE: type('[User] Clear User State'),
  UPDATE_USER_STATUS: type('[User] User status updated'),


  GET_SCHOOL_ADMIN_REGISTRATIONS_OF_CURRENT_USER: type(
    '[User] Get School Admin Registrations of Current User'
  ),
  GET_SCHOOL_ADMIN_REGISTRATIONS_OF_CURRENT_USER_COMPLETE: type(
    '[User] Get School Admin Registrations Of Current User Complete'
  ),
  GET_SYLLABUS_ADMIN_REGISTRATIONS_OF_CURRENT_USER: type(
    '[User] Get Syllabus Admin Registrations of Current User'
  ),
  GET_SYLLABUS_ADMIN_REGISTRATION_OF_CURRENT_USER_COMPLETE: type(
    '[User] Get Syllabus Admin Registration of Current User Complete'
  ),

  GET_USER_INFO_BY_SUBJECT: type('[User] Get User Info By Subject'),
  GET_USER_INFO_BY_EMAIL: type('[User] Get User Info By Email'),
  GET_USER_INFO_COMPLETE: type('[User] Get User Info Complete'),

  GET_SCHOOL_USERS_OF_CURRENT_USER: type(
    '[User] Get School Users of Current User'
  ),
  GET_SCHOOL_USERS_OF_CURRENT_USER_COMPLETE: type(
    '[User] Get School Users of Current User Complete'
  ),

  PROMPT_FOR_UI_CULTURE: type('[User] Prompt For UI Code'),

  GET_CUSTOM_REPORT_ATTRIBUTES_OF_CURRENT_USER:
    '[User] Get Custom Report Attributes of Current User',
  GET_CUSTOM_REPORT_ATTRIBUTES_OF_CURRENT_USER_COMPLETE:
    '[User] Get Custom Report Attributes of Current User Complete',
  GET_SCHOOL_PREFERENCES_COMPLETE:
    '[uSER] Get School Preferences Complete'
};

export function getSchoolPreferencesComplete(preferences: SchoolPreference[]) : UnsafeAction{
  return {
    type: UserActionTypes.GET_SCHOOL_PREFERENCES_COMPLETE,
    payload: preferences
  };
}

export function getCustomReportAttributesOfCurrentUser(): UnsafeAction {
  return {
    type: UserActionTypes.GET_CUSTOM_REPORT_ATTRIBUTES_OF_CURRENT_USER
  };
}

export function getCustomReportAttributesOfCurrentUserComplete(
  customReportAttributes: CustomReportAttributes
): UnsafeAction {
  return {
    type: UserActionTypes.GET_CUSTOM_REPORT_ATTRIBUTES_OF_CURRENT_USER_COMPLETE,
    payload: customReportAttributes
  };
}

export function getSchoolUsersOfCurrentUser(): UnsafeAction {
  return {
    type: UserActionTypes.GET_SCHOOL_USERS_OF_CURRENT_USER
  };
}

export function getSchoolUsersOfCurrentUserComplete(
  schoolUsers: SchoolUser[]
): UnsafeAction {
  return {
    type: UserActionTypes.GET_SCHOOL_USERS_OF_CURRENT_USER_COMPLETE,
    payload: schoolUsers
  };
}

export function promptForUICulture(): UnsafeAction {
  return { type: UserActionTypes.PROMPT_FOR_UI_CULTURE };
}

export function getUserInfoBySubject(subject: string): UnsafeAction {
  return {
    type: UserActionTypes.GET_USER_INFO_BY_SUBJECT,
    payload: subject
  };
}

export function getUserInfoByEmail(email: string): UnsafeAction {
  return {
    type: UserActionTypes.GET_USER_INFO_BY_EMAIL,
    payload: email
  };
}

export function getUserInfoComplete(userInfo: UserInfo): UnsafeAction {
  return {
    type: UserActionTypes.GET_USER_INFO_COMPLETE,
    payload: userInfo
  };
}

export function getSyllabusAdminRegistrationsOfCurrentUser(): UnsafeAction {
  return {
    type: UserActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS_OF_CURRENT_USER
  };
}

export function getSyllabusAdminRegistrationsOfCurrentUserComplete(
  registrations: SyllabusAdminRegistration[]
): UnsafeAction {
  return {
    type: UserActionTypes.GET_SYLLABUS_ADMIN_REGISTRATION_OF_CURRENT_USER_COMPLETE,
    payload: registrations
  };
}

export function getSchoolAdminRegistrationsOfCurrentUser(): UnsafeAction {
  return {
    type: UserActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS_OF_CURRENT_USER
  };
}

export function getSchoolAdminRegistrationsOfCurrentUserComplete(
  schoolAdminRegistrations: SchoolAdminRegistration[]
): UnsafeAction {
  return {
    type: UserActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS_OF_CURRENT_USER_COMPLETE,
    payload: schoolAdminRegistrations
  };
}

export function signin(args?: any): UnsafeAction {
  return { type: UserActionTypes.SIGNIN, payload: args };
}

export function initSignout(): UnsafeAction {
  return { type: UserActionTypes.INIT_SIGNOUT };
}

export function signout(): UnsafeAction {
  return { type: UserActionTypes.SIGNOUT };
}

export function clearUserState(): UnsafeAction {
  return { type: UserActionTypes.CLEAR_USER_STATE };
}

export function updateUserStatus(user: User): UnsafeAction {
  return { type: UserActionTypes.UPDATE_USER_STATUS, payload: user };
}
