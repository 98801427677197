import { toPairs } from 'lodash-es';
import { SelectItem } from 'primeng/api';

export const districts = {
  centralAndWestern: 'CENTRAL AND WESTERN', //1
  wanChai: 'WAN CHAI', //3
  hongKongEast: 'EASTERN', //2
  southern: 'SOUTHERN', //4
  islands: 'ISLANDS', //5

  kwunTong: 'KWUN TONG', //8
  wongTaiSin: 'WONG TAI SIN', //7
  kowloonCity: 'KOWLOON CITY', //6
  yauTsimMong: 'YAU TSIM MONG', //9
  shamShuiPo: 'SHAM SHUI PO', //10
  kwaiTsing: 'KWAI TSING', //11
  tsuenWan: 'TSUEN WAN', //12

  shaTin: 'SHA TIN', //13
  saiKung: 'SAI KUNG', //15
  taiPo: 'TAI PO', //14
  north: 'NORTH', //18
  tuenMun: 'TUEN MUN', //16
  yuenLong: 'YUEN LONG' //17
};

export const districtSelectItems: SelectItem[] = toPairs(districts).map((p) => {
  return { label: p[1], value: p[1] };
});
