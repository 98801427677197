import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from 'src/app/states/states-reducers';
import { rejectDelay } from 'src/app/utility/promise-utility';
import { Configuration } from '../../configuration';
import { OperationResult } from '../../model/operation-result';
import {
  ExerciseLevelsUpdatedDTO,
  UpdateLevelsCommand
} from '../../model/update-levels-command';
import { HttpClientService } from '../http-client.service';
import { LocalStorageService } from '../local-storage.service';
import { LoggingService } from '../log/logging.service';
import { MessageDisplayService } from '../ui/message-display.service';
import { hideSpinner } from '../ui/ui-actions';
import { UserAuthenticationService } from '../user-authentication.service';
import { ExerciseSubmissionServiceBase } from './exercise-submission-service-base';

const updateExerciseLevelsCommandPrefix = 'UpdateExerciseLevelsCommand_';
const updateTestLevelsCommandPrefix = 'UpdateTestLevelsCommand_';

@Injectable({ providedIn: 'root' })
export class ExerciseSubmissionService extends ExerciseSubmissionServiceBase {
  private _command = new BehaviorSubject<UpdateLevelsCommand>(undefined);
  command: UpdateLevelsCommand;

  constructor(
    private userAuthenticationService: UserAuthenticationService,
    http: HttpClientService,
    config: Configuration,
    localStorageService: LocalStorageService,
    store: Store<AppState>,
    messageDisplayService: MessageDisplayService,
    loggingService : LoggingService
  ) {
    super(http, config, localStorageService, store, messageDisplayService, loggingService);
    const subject = this.userAuthenticationService.sub;
    this.startSubmissionRetry(updateExerciseLevelsCommandPrefix + subject);
  }

  get command$(): Observable<UpdateLevelsCommand> {
    return this._command.asObservable();
  }

  protected async sendCommandUsingCommandStored(key: string): Promise<any> {
    this._command.next(undefined);

    let url = this.exerciseUrl;
    if (key.startsWith(updateExerciseLevelsCommandPrefix)) {
    } else if (key.startsWith(updateTestLevelsCommandPrefix)) {
      url = this.testUrl;
    } else {
      throw new Error('unknown url + ' + url);
    }

    try {
      const command: UpdateLevelsCommand = await this.localStorageService.get(
        key
      );

      if (this.isValidUpdateCommand(command)) {
        const result = await this.http
          .authPost<OperationResult>(url, command)
          .toPromise();
        try {
          this.updateCommandWithResultAndEmit(result, command);
          await this.localStorageService.remove(key);
        } catch (error) {
         this.loggingService.error(error);
        }
      } else {
        await this.localStorageService.remove(key);
      }
    } catch (error) {
      this.loggingService.error(error);
    }
  }

  async updateExerciseLevels(command: UpdateLevelsCommand): Promise<any> {
    if (this.isValidUpdateCommand(command)) {
      const sub = this.userAuthenticationService.sub;
      const commandSubjectPrefix =
        updateExerciseLevelsCommandPrefix + sub + '_';
      const key = commandSubjectPrefix + command.id;

      try {
        await this.sendCommandAndHandleResultWithRetry(
          command,
          this.exerciseUrl,
          3
        );
        this.store.dispatch(hideSpinner());
      } catch (error) {
        try {
          await this.localStorageService.put(key, command);
          this.startSubmissionRetry(commandSubjectPrefix);
        } catch (error) {
          try {
            await this.sendCommandAndHandleResultWithRetry(
              command,
              this.exerciseUrl,
              1000
            );
          } catch (error) {
            this.loggingService.error(error);
          } finally {
            this.store.dispatch(hideSpinner());
          }
        }
      }
    }
  }

  async updateTestLevels(command: UpdateLevelsCommand): Promise<any> {
    if (this.isValidUpdateCommand(command)) {
      const sub = this.userAuthenticationService.sub;
      const commandSubjectPrefix = updateTestLevelsCommandPrefix + sub + '_';
      const key = commandSubjectPrefix + command.id;

      try {
        await this.sendCommandAndHandleResultWithRetry(
          command,
          this.testUrl,
          3
        );
        this.store.dispatch(hideSpinner());
      } catch (error) {
        try {
          await this.localStorageService.put(key, command);
          this.startSubmissionRetry(commandSubjectPrefix);
        } catch (error) {
          try {
            await this.sendCommandAndHandleResultWithRetry(
              command,
              this.testUrl,
              1000
            );
          } catch (error) {
            this.loggingService.error(error);
          } finally {
            this.store.dispatch(hideSpinner());
          }
        }
      }
    }
  }

  private async sendCommandAndHandleResultWithRetry(
    command: UpdateLevelsCommand,
    url: string,
    retry: number
  ): Promise<any> {
    const result = await this.sendCommandWithRetry(command, url, retry);
    this.updateCommandWithResultAndEmit(result, command);
    this.store.dispatch(hideSpinner());
  }

  private sendCommandWithRetry(
    command: UpdateLevelsCommand,
    url: string,
    retry: number
  ): Promise<any> {
    let p: Promise<any> = Promise.reject();
    for (var i = 0; i < retry; i++) {
      p = p.catch((_) => this.sendCommand(command, url)).catch(rejectDelay);
    }
    return p;
  }

  private sendCommand(command: UpdateLevelsCommand, url: string) {
    return this.http.authPost<OperationResult>(url, command).toPromise();
  }

  private get exerciseUrl(): string {
    return this.config.apiRootUrl + '/problemset/exerciseLevels';
  }

  private get testUrl(): string {
    return this.config.apiRootUrl + '/problemset/testLevels';
  }

  private updateCommandWithResultAndEmit(
    result: OperationResult,
    command: UpdateLevelsCommand
  ): void {
    if (result && result.data && result.success) {
      const exerciseLevelsUpdatedDTO: ExerciseLevelsUpdatedDTO = result.data;
      if (exerciseLevelsUpdatedDTO.id === command.id) {
        command.exerciseLevelsUpdatedDTO = exerciseLevelsUpdatedDTO;
        command.date = new Date(command.date);
        this._command.next(command);
      }
    }
  }
}
