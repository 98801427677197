import { NgModule } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedModule } from '../shared/shared.module';
import { SmartUIModule } from '../smart-ui/smart-ui.module';
import { PrincipalForumRegistrationCompletedComponent } from './principal-forum-registration-completed.component';
import { PrincipalForumRegistrationComponent } from './principal-forum-registration.component';
import { PrincipalForumService } from './principal-forum.service';



@NgModule({
  imports: [
    SharedModule,
    SmartUIModule,
  ],
  declarations: [
    PrincipalForumRegistrationComponent,
    PrincipalForumRegistrationCompletedComponent
  ],
  providers: [PrincipalForumService, DialogService]
})
export class PrincipalForumModule {}
