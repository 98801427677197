export const shouldNotLogEventWithExceptionAnyValuesValueContainingSubstrings =
  [
    'window.webkit.messageHandlers',
    'undefined is not an object (evaluating \'t.style\')', //primeng-dom.js fadeIn
    'Cannot read property \'style\' of undefined', //primeng-dom.js fadeIn
    'Cannot read property \'querySelector\' of null', //ej2-richtexteditor
    'null is not an object (evaluating \'this.parent.element.querySelector\')', //ej2-richtexteditor
    'HttpErrorResponse',
    'Http failure response',
    'www.paypal.com/xoplatform/logger/api/logger',
    'Window closed before response',
    'Cannot read property \'postMessage\' of null',
    'webkitExitFullScreen',
    'undefined.json',
    'vivo_checkMediaPausedReason is not defined',
    'Unexpected EOF',
    'Unexpected end of script',
    //ERRORS
    'SecurityError: Blocked a frame with origin "https://www.solidmemory.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
    'NotFoundError', //Device NotFound
    'ChunkLoadError',
    'TimeoutError',
    'InvalidStateError',
    'NotSupportedError',
    'NotAllowedError',
    'AbortError',
    'TypeError',
    'Type error',
    '/api/school/assessmentMonthDaysCollection',
    'window.bannerNight is not a function',
    'Non-Error exception captured with keys',
    'Gateway Time-out',
    'Network Error',
    'Gateway Timeout',
    'ResizeObserver',
    '"status":503'
  ];

export const shouldNotLogExceptionTypes = ['TimeoutError', 'ChunkLoadError','NotAllowedError'];
