import { Injectable } from '@angular/core';
import * as iassign from 'immutable-assign';
import { combineLatest, Observable, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { containsForbiddenExamplePattern } from 'src/app/utility/string-utility';
import { Configuration } from '../../configuration';
import { CharacterStroke } from '../../model/character-stroke';
import { DictEntry } from '../../model/dict-entry';
import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { Phonetic } from '../../model/phonetic';
import { getDefaultLanguage, UICulture } from '../../model/ui-culture';
import { DictionaryDataService } from './dictionary-data.service';

const phraseSuggestionsPath = '/phraseSuggestions';
const alternativeCharactersPath = '/alternativeCharacters';
const characterStrokesPath = '/characterStrokes';
const phoneticsPath = '/phonetics';

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  constructor(
    private dataService: DictionaryDataService,
    private config: Configuration
  ) {}

  getCharacterStroke(character: string): Observable<CharacterStroke> {
    const url =
      this.config.dictionaryRootUrl +
      characterStrokesPath +
      '?character=' +
      character;
    return this.dataService.getCharacterStroke(url);
  }

  getPhonetics(
    phrase: string,
    syllabusLanguage: Language
  ): Observable<Phonetic[]> {
    const url =
      this.config.dictionaryRootUrl +
      phoneticsPath +
      '/' +
      syllabusLanguage +
      '?phrase=' +
      phrase;
    return this.dataService.getPhonetics(url);
  }

  getAlternativeCharacters(
    lang: Language,
    character: string
  ): Observable<string[]> {
    const url =
      this.config.dictionaryRootUrl +
      alternativeCharactersPath +
      '/' +
      lang +
      '?character=' +
      character;
    return this.dataService.getAlternativeCharacters(url);
  }

  displayExplanationToastr({
    phrase,
    uiCulture,
    syllabusLanguage
  }: {
    phrase: string;
    uiCulture: UICulture;
    syllabusLanguage: Language;
  }): Observable<DictEntry> {
    const uiLanguage = getDefaultLanguage(uiCulture);
    return this.getDictEntry(
      phrase,
      syllabusLanguage,
      uiLanguage,
      Level.Elementary,
      uiCulture,
      false,
      false
    );
  }

  getPhraseSuggestions(
    startsWith: string,
    source: Language,
    uiCulture: UICulture
  ): Observable<string[]> {
    const url =
      this.config.dictionaryRootUrl +
      phraseSuggestionsPath +
      '?startsWith=' +
      startsWith +
      '&sourceLang=' +
      source +
      '&uiCulture=' +
      uiCulture;
    return this.dataService.getPhraseSuggestions(url);
  }

  getDictEntries(
    phrase: string,
    uiCulture: UICulture,
    syllabusLanguage: Language,
    dictionaryLevel: Level,
    newSearch: boolean,
    disableCache: boolean
  ): Observable<DictEntry[]> {
    const queries: Observable<DictEntry>[] = [];
    const uiLanguage: Language = getDefaultLanguage(uiCulture);

    const native$ = this.getDictEntry(
      phrase,
      syllabusLanguage,
      syllabusLanguage,
      dictionaryLevel,
      uiCulture,
      newSearch,
      disableCache
    );
    queries.push(native$);

    if (uiLanguage !== syllabusLanguage) {
      const motherTongueQuery = this.getDictEntry(
        phrase,
        syllabusLanguage,
        uiLanguage,
        dictionaryLevel,
        uiCulture,
        newSearch,
        disableCache
      );
      queries.push(motherTongueQuery);
    } else if (uiLanguage !== Language.English) {
      const englishQuery = this.getDictEntry(
        phrase,
        syllabusLanguage,
        Language.English,
        dictionaryLevel,
        uiCulture,
        newSearch,
        disableCache
      );
      queries.push(englishQuery);
    }

    const dictEntries$ = combineLatest(queries);

    const results$ :Observable<DictEntry[]>= dictEntries$.pipe(
      exhaustMap((entries) => {
        const hasNoNativeDefnition = entries[0].definitions.length === 0;
        if (hasNoNativeDefnition) {
          return of([]);
        }

        const lemmas = entries.map((e) => e.lemma);

        const lemmasTheSameAsPhrase = lemmas.filter(
          (lemma) => lemma === phrase
        );
        const lemmasDifferentFromPhrase = lemmas.filter(
          (lemma) => lemma !== phrase
        );

        if (lemmasTheSameAsPhrase.length > 0) {
          return of(this.changeEntriesLemma(entries, phrase));
        } else if (lemmasDifferentFromPhrase.length > 0) {
          return this.getDictEntries(
            lemmasDifferentFromPhrase[0],
            uiCulture,
            syllabusLanguage,
            dictionaryLevel,
            newSearch,
            disableCache
          );
        } else {
          return of([]);
        }
      })
    );

    return results$;
  }

  private changeEntriesLemma(entries: DictEntry[], lemma: string): DictEntry[] {
    return entries.map((entry) => {
      return iassign(entry, (e) => {
        e.lemma = lemma;
        return e;
      });
    });
  }

  getDictEntry(
    phrase: string,
    source: Language,
    target: Language,
    dictionaryLevel: Level,
    uiCulture: UICulture,
    newSearch: boolean,
    disableCache: boolean
  ): Observable<DictEntry> {
    return this.dataService
      .getDictEntry(
        dictionaryLevel,
        phrase,
        source,
        target,
        uiCulture,
        newSearch,
        disableCache
      )
      .pipe(
        map((entry) => {
          const lemma = this.getLemma(entry);
          if (!lemma) {
            entry = iassign(entry, (e) => {
              e.lemma = phrase;
              return e;
            });
          }
          return this.cleanupDictEntry(entry);
        })
      );
  }

  private cleanupDictEntry(entry : DictEntry): DictEntry{
    entry.lemma = entry.lemma.trim();
    entry.synonyms = entry.synonyms.filter(s => s.toLowerCase().trim() !== entry.lemma.toLowerCase());
    entry.examples = entry.examples.filter(e => !containsForbiddenExamplePattern(e));
    return entry;
  }

  private getLemma(entry: DictEntry): string {
    return entry.lemma;
  }
}
