import { Injectable } from '@angular/core';
import { orderBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { HttpClientService } from '../core/http-client.service';
import { ErpSchool } from './erp-school';
import { getSchoolParameters } from './erp-utility';

@Injectable({ providedIn: 'root' })
export class ErpSchoolService {
  urlRoot: string;
  constructor(private http: HttpClientService, config: Configuration) {
    this.urlRoot = config.erpRootUrl + '/schools';
  }

  getErpSchools({ schoolLevel, district }: any): Observable<ErpSchool[]> {
    const url = `${this.urlRoot}?schoolLevel=${schoolLevel}&district=${district}`;
    return this.http.authGet<ErpSchool[]>(url).pipe(
      map((schools) =>
        schools.map((s) => {
          s.lastUpdated = new Date(s.lastUpdated);
          s.contacts = s.contacts.map((c) => {
            c.contactHistory = c.contactHistory.map((h) => {
              h.date = new Date(h.date);
              return h;
            });
            return c;
          });
          return s;
        })
      ),
      map((schools) => orderBy(schools, (s) => s.gridRef))
    );
  }

  getErpSchool(params: any): Observable<ErpSchool> {
    const url = `${this.urlRoot}/${params.schoolNumber}/${params.locationId}/${params.schoolLevel}?session=${params.session}`;
    return this.http.authGet<ErpSchool>(url);
  }

  getErpSchoolBySchoolCode(schoolCode: string) {
    return this.getErpSchool(getSchoolParameters(schoolCode));
  }

  upsertErpSchool(erpSchool: ErpSchool): Observable<ErpSchool> {
    const url = `${this.urlRoot}`;
    return this.http.authPut(url, erpSchool).pipe(mapTo(erpSchool));
  }
}
