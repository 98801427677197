import {
  lowAchievementLevel,
  newAnswerTrial,
  wrongAnswerTrial
} from '../../configuration';
import { QuestionDTO } from '../../model/questionDTO';

export class ExerciseItemImplementation {
  itemLevel: number;

  itemTrial: number;

  isCorrectInAllAttempts = true;

  isNew: boolean;

  constructor(
    public question: QuestionDTO,
    achievementLevel: number,
    private alwaysPronounceVocabularyQuestion: boolean = false,
    private isTest: boolean = false,
    private testCompleteOnlyWhenAllCorrect: boolean = false
  ) {
    this.itemLevel = achievementLevel;
    if (achievementLevel === 0 && !isTest) {
      this.isNew = true;
      this.itemTrial = newAnswerTrial;
    } else {
      this.itemTrial = 1;
    }
  }

  get isLowAchieving() {
    const isLowAchieving =
      this.alwaysPronounceVocabularyQuestion ||
      this.itemLevel <= lowAchievementLevel;
    return isLowAchieving;
  }

  submitAnswer(isCorrect: boolean) {
    if (this.isTest) {
      if (isCorrect) {
        ++this.itemLevel;
        this.itemTrial = 0;
      } else {
        this.isCorrectInAllAttempts = false;
        if(this.testCompleteOnlyWhenAllCorrect){
          this.itemTrial = 1;
        } else{
          this.itemTrial = 0;
        }
      }
    } else {
      if (isCorrect) {
        if (this.itemTrial > 0) {
          --this.itemTrial;
          if (this.itemTrial === 0) {
            ++this.itemLevel;
          }
        }
      } else {
        this.isCorrectInAllAttempts = false;
        if (this.isTest) {
          this.itemTrial = 1;
        } else {
          this.itemTrial = wrongAnswerTrial;
        }
        if (this.itemLevel > 0) {
          --this.itemLevel;
        }
      }
    }
  }
}
