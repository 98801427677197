import * as iassign from 'immutable-assign';
import { DictEntry } from '../../model/dict-entry';
import { UnsafeAction } from '../../model/unsafe-action';
import { DictionaryActionTypes } from './dictionary-actions';

export interface DictionaryState {
  dictEntries: DictEntry[];
  searchIsInProgress: boolean;
  searchCompleted: boolean;
}

export const INITIAL_DICTIONARY_STATE: DictionaryState = {
  dictEntries: [],
  searchIsInProgress: false,
  searchCompleted: false
};

export function dictionaryStateReducer(
  state: DictionaryState = INITIAL_DICTIONARY_STATE,
  { type, payload }: UnsafeAction
): DictionaryState {
  switch (type) {
    case DictionaryActionTypes.CLEAR_ENTRIES:
      return INITIAL_DICTIONARY_STATE;
    case DictionaryActionTypes.GET_DICT_ENTRIES:
      return iassign(state, (s) => {
        s.dictEntries = [];
        s.searchIsInProgress = true;
        s.searchCompleted = false;
        return s;
      });
    case DictionaryActionTypes.GET_DICT_ENTRIES_COMPLETE:
      return iassign(state, (s) => {
        s.dictEntries = payload;
        s.searchIsInProgress = false;
        s.searchCompleted = true;
        return s;
      });
    default:
      return state;
  }
}
