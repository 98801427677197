import * as iassign from 'immutable-assign';
import { User } from 'oidc-client';
import { CustomReportAttributes } from 'src/app/model/custom-report-attributes';
import { SchoolPreference } from 'src/app/model/school-preference';
import { SchoolAdminRegistration } from '../../model/school-admin-registration';
import { SchoolUser } from '../../model/school-user';
import { SyllabusAdminRegistration } from '../../model/syllabus-admin-registration';
import { UnsafeAction } from '../../model/unsafe-action';
import { UserInfo } from '../../model/user-info';
import { UserActionTypes } from './user-actions';

export const DEFAULT_CUSTOM_REPORT_ATTRIBUTES: CustomReportAttributes = {
  schoolId: '',
  assessmentPeriods: [
    { monthDay: { month: 8, day: 5 }, maxScore: 122 },
    { monthDay: { month: 0, day: 5 }, maxScore: 122 },
    { monthDay: { month: 4, day: 6 }, maxScore: 122 }
  ],
  disableReportScore: false,
  showReportScore: true
};

export interface UserState {
  currentUser: User;
  userInfo: UserInfo;
  schoolAdminRegistrationsOfCurrentUser: SchoolAdminRegistration[];
  syllabusAdminRegistrations: SyllabusAdminRegistration[];
  schoolUsersOfCurrentUser: SchoolUser[];
  customReportAttributes: CustomReportAttributes;
  schoolPreferences: SchoolPreference[];
}

export const INITIAL_USER_STATE: UserState = {
  currentUser: undefined,
  userInfo: undefined,
  schoolAdminRegistrationsOfCurrentUser: [],
  syllabusAdminRegistrations: [],
  schoolUsersOfCurrentUser: [],
  customReportAttributes: DEFAULT_CUSTOM_REPORT_ATTRIBUTES,
  schoolPreferences: []
};

export function userStateReducer(
  state: UserState = INITIAL_USER_STATE,
  { type, payload }: UnsafeAction
): UserState {
  switch (type) {
    case UserActionTypes.GET_SCHOOL_PREFERENCES_COMPLETE:
      return iassign(state, (s) => {
        if (payload) {
          s.schoolPreferences = payload;
        }
        return s;
      });
    case UserActionTypes.GET_CUSTOM_REPORT_ATTRIBUTES_OF_CURRENT_USER_COMPLETE:
      return iassign(state, (s) => {
        if (payload) {
          s.customReportAttributes = payload;
        }
        return s;
      });
    case UserActionTypes.GET_SCHOOL_USERS_OF_CURRENT_USER:
      return iassign(state, (s) => {
        s.schoolUsersOfCurrentUser = [];
        return s;
      });
    case UserActionTypes.GET_SCHOOL_USERS_OF_CURRENT_USER_COMPLETE:
      return iassign(state, (s) => {
        s.schoolUsersOfCurrentUser = payload;
        return s;
      });
    case UserActionTypes.CLEAR_USER_STATE:
      return INITIAL_USER_STATE;

    case UserActionTypes.GET_USER_INFO_COMPLETE:
      return iassign(state, (s) => {
        s.userInfo = payload;
        return s;
      });
    case UserActionTypes.UPDATE_USER_STATUS:
      if (payload) {
        return iassign(state, (s) => {
          s.currentUser = payload;
          return s;
        });
      } else {
        return INITIAL_USER_STATE;
      }
    case UserActionTypes.GET_SCHOOL_ADMIN_REGISTRATIONS_OF_CURRENT_USER_COMPLETE:
      return iassign(state, (s) => {
        s.schoolAdminRegistrationsOfCurrentUser = payload;
        return s;
      });
    case UserActionTypes.GET_SYLLABUS_ADMIN_REGISTRATION_OF_CURRENT_USER_COMPLETE:
      return iassign(state, (s) => {
        s.syllabusAdminRegistrations = payload;
        return s;
      });
    default:
      return state;
  }
}
