<button
  pButton
  [ngStyle]="buttonStyle"
  [class]="buttonClass"
  [disabled]="disabled"
>
  <div *ngIf="!horizontal" class="col-12">
    <div *ngIf="icon" class="mb-1"><i [class]="icon"></i></div>
    <div *ngIf="text">{{ text }}</div>
  </div>
  <div *ngIf="horizontal" class="col-12 flex justify-content-center">
    <div *ngIf="icon" class="mr-2"><i [class]="icon"></i></div>
    <div *ngIf="text">{{ text }}</div>
  </div>
</button>
