import { Component } from '@angular/core';
import { TranslationService } from '../../core/translation/translation.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  templateUrl: './vocab-upgrade-prompt.component.html'
})
export class VocabUpgradePromptComponent {
  constructor(
    private ref: DynamicDialogRef,
    public translationService: TranslationService
  ) {}

  onUpgradeButtonClick() {
    this.ref.close();
  }
}
