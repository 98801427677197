import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { SoftwareUpdateService } from './core/software-update.service';
import { getInitialUILanguage } from './i18n/i18n-actions';
import { AudioHandlingService } from './smart-ui/pronunciation/audio-handling.service';
import { AppState } from './states/states-reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  menuMode: string = 'static';

  isRTL: boolean = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    softwareUpdate: SoftwareUpdateService,
    store: Store<AppState>,
    private audioHandlingService: AudioHandlingService
  ) {
    softwareUpdate.init();
    store.dispatch(getInitialUILanguage());
  }

  ngOnInit() {
    this.primengConfig.ripple = false;
    document.body.addEventListener('click', () =>
    {
      if(this.audioHandlingService && this.audioHandlingService.activateAudio){
        this.audioHandlingService.activateAudio();
      }
    }
    );
  }
}
