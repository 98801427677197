import { Language } from '../../model/language';
import { UnsafeAction } from '../../model/unsafe-action';
import { VocabEntry } from '../../model/vocab-entry';
import { type } from '../../utility/type';

export const VocabEntryActionTypes = {
  GET_VOCAB_ENTRIES: type('[Vocabulary] Get VocabEntries'),
  GET_VOCAB_ENTRIES_COMPLETE: type('[Vocabulary] Get VocabEntries Complete'),
  DELETE_VOCAB_ENTRIES: type('[Vocabulary] Delete VocabEntries'),
  DELETE_VOCAB_ENTRIES_COMPLETE: type(
    '[Vocabulary] Delete VocabEntries Complete'
  ),
  DELETE_VOCABLESSON: type('[Vocabulary] Delete all VocabEntries'),
  DELETE_ALL_UNATTEMPTED_WORDS: type(
    '[Vocabulary] Delete All Unattempted words'
  ),
  DELETE_ALL_UNATTEMPTED_WORDS_COMPLETE: type(
    '[Vocabulary] Delete All Unattempted words Complete'
  ),

  SET_VOCAB_ENTRIES_ORDER: type('[Vocabulary] Set VocabEntries Order'),
  SET_VOCAB_ENTRIES_ORDER_BY: type('[Vocabulary] Set VocabEntries OrderBy'),
  ADD_VOCAB_ENTRY_COMPLETE: type('[Vocabulary] Add VocabEntry Complete')
};

export function setVocabEntriesOrder(order: number): UnsafeAction {
  return {
    type: VocabEntryActionTypes.SET_VOCAB_ENTRIES_ORDER,
    payload: { order }
  };
}

export function AddVocabEntryComplete(vocabEntry: VocabEntry): UnsafeAction {
  return {
    type: VocabEntryActionTypes.ADD_VOCAB_ENTRY_COMPLETE,
    payload: { vocabEntry }
  };
}

export function setVocabEntriesOrderBy(orderBy: string): UnsafeAction {
  return {
    type: VocabEntryActionTypes.SET_VOCAB_ENTRIES_ORDER_BY,
    payload: { orderBy }
  };
}

export function getVocabEntries(
  language: Language,
  orderBy: string,
  order: number
): UnsafeAction {
  return {
    type: VocabEntryActionTypes.GET_VOCAB_ENTRIES,
    payload: { language, orderBy, order }
  };
}

export function getVocabEntriesComplete(entries: VocabEntry[]): UnsafeAction {
  return {
    type: VocabEntryActionTypes.GET_VOCAB_ENTRIES_COMPLETE,
    payload: entries
  };
}

export function deleteVocabEntries(
  phrases: string[],
  language: Language
): UnsafeAction {
  return {
    type: VocabEntryActionTypes.DELETE_VOCAB_ENTRIES,
    payload: { phrases, language }
  };
}

export function deleteVocabEntriesComplete(
  phrases: string[],
  language: Language
): UnsafeAction {
  return {
    type: VocabEntryActionTypes.DELETE_VOCAB_ENTRIES_COMPLETE,
    payload: { phrases, language }
  };
}

export function deleteVocabLesson(language: Language): UnsafeAction {
  return {
    type: VocabEntryActionTypes.DELETE_VOCABLESSON,
    payload: { language }
  };
}

export function deleteAllUnattemptedWords(language: Language): UnsafeAction {
  return {
    type: VocabEntryActionTypes.DELETE_ALL_UNATTEMPTED_WORDS,
    payload: { language }
  };
}

export function deleteAllUnAttemptedWordsComplete(
  language: Language
): UnsafeAction {
  return {
    type: VocabEntryActionTypes.DELETE_ALL_UNATTEMPTED_WORDS_COMPLETE,
    payload: { language }
  };
}
