import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { OperationResult } from '../../model/operation-result';
import { ProblemSetSetting } from '../../problem-set/model/problem-set-setting';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class ProblemSetSettingService {
  urlRoot: string;

  constructor(private http: HttpClientService, private config: Configuration) {
    this.urlRoot = this.config.apiRootUrl + '/problemSet/problemSetSettings';
  }

  getProblemSetSetting(syllabusId: string): Observable<ProblemSetSetting> {
    const url = `${this.urlRoot}/${syllabusId}`;
    return this.http.authGet<ProblemSetSetting>(url);
  }

  getProblemSetSettings(): Observable<ProblemSetSetting[]> {
    return this.http.authGet<ProblemSetSetting[]>(this.urlRoot);
  }

  updateProblemSetSetting(
    {problemSetSetting, schoolId}: {problemSetSetting: ProblemSetSetting, schoolId : string}
  ): Observable<boolean> {
    return this.http
      .authPut<OperationResult>(`${this.urlRoot}?schoolId=${schoolId}`, problemSetSetting)
      .pipe(map((r) => r.success));
  }
}
