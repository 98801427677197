import { Component, Input } from '@angular/core';
import { HistoryItem } from '../erp/history-item';

@Component({
  selector: 'app-contact-history-display',
  templateUrl: './contact-history-display.component.html'
})
export class ContactHistoryDisplayComponent {
  @Input() contactHistory: HistoryItem[];
}
