import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { supportedUILanguages } from '../i18n/i18n-utility';

@Component({
  templateUrl: 'setup-dialog.component.html'
})
export class SetupDialogComponent {
  supportedUILanguages = supportedUILanguages;
  constructor(private dialogRef: DynamicDialogRef) {}

  onThemeSelected(theme: string) {
    this.dialogRef.close(theme);
  }
}
