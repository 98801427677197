import { UILanguage } from '../../i18n/ui-language';
import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { Pronunciation } from '../../model/pronunciation';
import { UnsafeAction } from '../../model/unsafe-action';
import { UserPreference } from '../../model/user-preference';
import { type } from '../../utility/type';

export const UserPreferenceActionTypes = {
  GET_USERPREFERENCE: type('[User Preference] Get UserPreference'),
  GET_USERPREFERENCE_COMPLETE: type(
    '[User Preference] Get UserPreference Complete'
  ),
  SAVE_USERPREFERENCE: type('[User Preference] Save UserPreference'),


  CHANGE_DICTIONARYLEVEL_PREFERENCE: type(
    '[User Preference Change Dictionary Level Preference'
  ),
  CHANGE_UI_LANGUAGE_PREFERENCE: type(
    '[User Preference] Change UI Language Preference'
  ),
  CHANGE_VOCABULARY_PRONUNCIATION_PREFERENCE: type(
    '[User Preference] Change Vocabulary Pronunciation Preference'
  ),
  TOGGLE_SHOW_DEFINITIONS_ORDER: type(
    '[User Preference] Toggle show definitions order'
  ),
  CHANGE_VOCABULARY_LANGUAGE: type(
    '[User Preference] Change Vocabulary Language'
  ),
  CHANGE_THEME_COLOR: type('[User] Change Theme Color'),
  CHANGE_LANDING_PREFERENCE: type('[User] Change Landing Preference'),
  TOGGLE_DISABLE_THUMBNAILS: type('[User] Toggle Disable Thumbnails')
};


export function toggleDisableThumbnails(): UnsafeAction{
  return {
    type: UserPreferenceActionTypes.TOGGLE_DISABLE_THUMBNAILS
  };
}

export function changeThemeColor(themeColor: string) : UnsafeAction {
  return {
    type: UserPreferenceActionTypes.CHANGE_THEME_COLOR,
    payload: themeColor
  };
}

export function changeLandingPrefernce(url :string) : UnsafeAction{
  if(!url || url.startsWith('/home')) {
    url = '/home';
  }
  return {
    type: UserPreferenceActionTypes.CHANGE_LANDING_PREFERENCE,
    payload: url
  };
}

export function changeVocabularyLanguage(language: Language) {
  return {
    type: UserPreferenceActionTypes.CHANGE_VOCABULARY_LANGUAGE,
    payload: +language
  };
}

export function toggleShowDefinitionsOrder() {
  return {
    type: UserPreferenceActionTypes.TOGGLE_SHOW_DEFINITIONS_ORDER
  };
}

export function changeVocabularyPronunciationPreference(
  language: Language,
  pronunciation: Pronunciation
): UnsafeAction {
  return {
    type: UserPreferenceActionTypes.CHANGE_VOCABULARY_PRONUNCIATION_PREFERENCE,
    payload: { language: +language, pronunciation: +pronunciation }
  };
}

export function changeDictionaryLevelPreference(level: Level): UnsafeAction {
  return {
    type: UserPreferenceActionTypes.CHANGE_DICTIONARYLEVEL_PREFERENCE,
    payload: +level
  };
}

export function ChangeUiLanguagePreference(uiLanguage: UILanguage) {
  return {
    type: UserPreferenceActionTypes.CHANGE_UI_LANGUAGE_PREFERENCE,
    payload: uiLanguage
  };
}

export function saveUserPreference(): UnsafeAction {
  return { type: UserPreferenceActionTypes.SAVE_USERPREFERENCE };
}

export function getUserPreference(): UnsafeAction {
  return { type: UserPreferenceActionTypes.GET_USERPREFERENCE };
}

export function getUserPreferenceComplete(
  preference: UserPreference
): UnsafeAction {
  return {
    type: UserPreferenceActionTypes.GET_USERPREFERENCE_COMPLETE,
    payload: preference
  };
}
