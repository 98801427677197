import { Mentee } from '../../model/mentee';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const MenteeActionTypes = {
  GET_MENTEES_OF_CURRENT_USER: type('[Mentee] Get Mentees of Current User'),
  GET_MENTEES_OF_CURRENT_USER_COMPLETE: type(
    '[Mentee] Get Mentees of Current User Complete'
  ),
  CLEAR_MENTEE_STATE: type('[Mentee] Clear Mentee State')
};

export function getMenteesOfCurrentUser(): UnsafeAction {
  return { type: MenteeActionTypes.GET_MENTEES_OF_CURRENT_USER };
}

export function getMenteesOfCurrentUserComplete(
  mentees: Mentee[]
): UnsafeAction {
  return {
    type: MenteeActionTypes.GET_MENTEES_OF_CURRENT_USER_COMPLETE,
    payload: mentees
  };
}
