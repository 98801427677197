import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { isBoolean, isInteger } from 'lodash-es';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime, filter,
  map, switchMap, throttleTime
} from 'rxjs/operators';
import { Configuration } from '../../configuration';
import { displayWarningMessage, NullAction } from '../../core/ui/ui-actions';
import { DictEntry } from '../../model/dict-entry';
import { UnsafeAction } from '../../model/unsafe-action';
import { isNilOrWhiteSpace } from '../../utility/string-utility';
import {
  DictionaryActionTypes,
  getDictEntriesComplete
} from './dictionary-actions';
import { DictionaryService } from './dictionary.service';

@Injectable()
export class DictionaryEffects {
  constructor(
    private actions$: Actions,
    private service: DictionaryService,
    private config: Configuration
  ) {}

  @Effect()
  displayExplanationToastr = this.actions$.pipe(
    ofType(DictionaryActionTypes.DISPLAY_EXPLANATION_TOASTR),
    filter(
      (action: UnsafeAction) =>
        !isNilOrWhiteSpace(action.payload.phrase) &&
        isInteger(action.payload.uiCulture) &&
        isInteger(action.payload.language)
    ),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    switchMap((action) => {
      const command = {
        phrase: action.payload.phrase,
        uiCulture: action.payload.uiCulture,
        syllabusLanguage: action.payload.language
      };
      return this.service.displayExplanationToastr(command).pipe(
        catchError((_) => of(null)),
        map((dictEntry: DictEntry) => {
          return { dictEntry, phrase: command.phrase };
        })
      );
    }),
    map(({ dictEntry, phrase }) => {
      if (dictEntry) {
        if (dictEntry.definitions.length > 0) {
          const definitionString = dictEntry.definitions
            .map((d) => d.explanation)
            .join(' ')
            .trim();
          return displayWarningMessage(
            phrase,
            definitionString,
            this.config.explanationToastrLifeInMs
          );
        }
      }
      return NullAction;
    })
  );

  @Effect()
  getDictEntries$ = this.actions$.pipe(
    ofType(DictionaryActionTypes.GET_DICT_ENTRIES),
    filter(
      (action: UnsafeAction) =>
        !isNilOrWhiteSpace(action.payload.phrase) &&
        isInteger(action.payload.uiCulture) &&
        isInteger(action.payload.language) &&
        isInteger(action.payload.dictionaryLevel) &&
        isBoolean(action.payload.newSearch) &&
        isBoolean(action.payload.disableCache)
    ),
    switchMap((action) => {
      return this.service
        .getDictEntries(
          action.payload.phrase,
          action.payload.uiCulture,
          action.payload.language,
          action.payload.dictionaryLevel,
          action.payload.newSearch,
          action.payload.disableCache
        )
        .pipe(
          catchError((_) => {
            return of([]);
          })
        );
    }),
    map((entries) =>
      getDictEntriesComplete(entries.filter((entry: DictEntry) => !!entry))
    )
  );
}
