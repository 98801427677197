import { UnsafeAction } from '../model/unsafe-action';
import { type } from '../utility/type';
import { Membership } from './membership';
import { Subscription } from './subscription';

export const SubscriptionActionTypes = {
  GET_MEMBERSHIP: type('[Subscription] Get Membership'),
  GET_MEMBERSHIP_COMPLETE: type('[Subscription] Get Membership Complete'),
  GET_PERSONAL_SUBSCRIPTIONS: type('[Subscription] Get Personal Subscriptions'),
  GET_PERSONAL_SUBSCRIPTIONS_COMPLETE: type(
    '[Subscription] Get Personal Subscriptions Complete'
  ),
  GET_SCHOOL_SUBSCRIPTIONS: type('[Subscription] Get School Subscriptions'),
  GET_SCHOOL_SUBSCRIPTIONS_COMPLETE: type(
    '[Subscription] Get School Subscriptions Complete'
  )
};

export function getPersonalSubscriptions(): UnsafeAction {
  return { type: SubscriptionActionTypes.GET_PERSONAL_SUBSCRIPTIONS };
}

export function getMembership(): UnsafeAction {
  return { type: SubscriptionActionTypes.GET_MEMBERSHIP };
}

export function getMembershipComplete(membership: Membership): UnsafeAction {
  return {
    type: SubscriptionActionTypes.GET_MEMBERSHIP_COMPLETE,
    payload: membership
  };
}

export function getPersonalSubscriptionsComplete(
  subscriptions: Subscription[]
): UnsafeAction {
  return {
    type: SubscriptionActionTypes.GET_PERSONAL_SUBSCRIPTIONS_COMPLETE,
    payload: subscriptions
  };
}

export function getSchoolSubscriptions(schoolId: string): UnsafeAction {
  return {
    type: SubscriptionActionTypes.GET_SCHOOL_SUBSCRIPTIONS,
    payload: schoolId
  };
}

export function getSchoolSubscriptionsComplete(
  subscriptions: Subscription[]
): UnsafeAction {
  return {
    type: SubscriptionActionTypes.GET_SCHOOL_SUBSCRIPTIONS_COMPLETE,
    payload: subscriptions
  };
}
