import { SelectItem } from 'primeng/api';
import { splitCapitalized } from './format-string';

export function getEnums(Enum: any): { name: string; value: number }[] {
  return Object.keys(Enum)
    .filter((a) => a.match(/^\D/))
    .map((name) => ({ name, value: Enum[name] as number }));
}

export function getSelectItemsFromEnum(Enum: {}): SelectItem[] {
  return getEnums(Enum).map((e) => {
    return { label: splitCapitalized(e.name), value: e.value };
  });
}
