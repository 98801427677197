import { Injectable } from '@angular/core';
import { some, sortBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from 'src/app/model/language';
import { Configuration, NoImageList } from '../../configuration';
import { ThumbnailDataService } from './thumbnail-data.service';
import { WebImage } from './web-image';

@Injectable({ providedIn: 'root' })
export class ThumbnailService {
  constructor(
    private config: Configuration,
    private dataService: ThumbnailDataService
  ) {}

  getThumbnails({
    phrase,
    size,
    newSearch,
    disableCache,
    language
  }: {
    phrase: string;
    size: number;
    newSearch: boolean;
    disableCache: boolean;
    language: Language;

  }): Observable<WebImage[]> {
    if(this.isForbiddenPhrase(phrase)) return of([]);
    phrase = phrase.toLowerCase();
    let urlSegment = `/images/${language}?newSearch=${newSearch}&phrase=${encodeURIComponent(
      phrase
    )}&size=${size}`;
    if (disableCache) {
      urlSegment = '/nocache' + urlSegment;
    }
    const url = `${this.config.imageRootUrl}${urlSegment}`;
    return this.dataService.getThumbnails(url).pipe(
      map((images) => {
        return sortBy(images, (i) => i.blame);
      })
    );
  }

  isForbiddenPhrase(phrase : string): boolean{
    if(!phrase) return true;
    phrase = phrase.trim().toLowerCase();
    return some(NoImageList, p => p === phrase);
  }

  blameImages(ids: string[]): Observable<any> {
    const url = `${this.config.imageRootUrl}/blames`;
    return this.dataService.blameImages(url, ids);
  }

  resetImages(ids: string[]): Observable<any> {
    const url = `${this.config.imageRootUrl}/blames`;
    return this.dataService.resetImageBlames(url, ids);
  }

  endorseImages(ids: string[]): Observable<any> {
    const url = `${this.config.imageRootUrl}/blames`;
    return this.dataService.endorseImages(url, ids);
  }
}
