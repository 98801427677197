import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserAuthenticationService } from '../user-authentication.service';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private router: Router
  ) {}

  canActivate(_: ActivatedRouteSnapshot) {
    if (!this.userAuthenticationService.user) {
      this.router.navigate(['/home']);
    }
    return !!this.userAuthenticationService.user;
  }
}
