<div class="col-12 tiny-black-border">
  <div class="grid ">
    <div class="col-6">{{ contact.lastUpdated | date: "yyyy-MM-dd" }}</div>
    <div class="col-6">
      <span
        [style.visibility]="contact.isExpired ? 'visible' : 'hidden'"
        class="expired"
        >Expired</span
      >
    </div>
    <div class="col-6">{{ jobTitle }}</div>
    <div class="col-6">{{ responsibilityArea }}</div>
    <div class="col-6">{{ contact.nameInChinese }}</div>
    <div class="col-6">{{ contact.nameInEnglish }}</div>
    <div class="col-6">
      <a href="mailto:{{ contact.emailAddress }}">{{ contact.emailAddress }}</a>
    </div>
    <div class="col-6">{{ contact.telephoneNumber }}</div>
    <div class="col-6">{{ contact.remark }}</div>
    <app-contact-history-display
      [contactHistory]="contact.contactHistory"
    ></app-contact-history-display>
  </div>
</div>
