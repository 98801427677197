import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LogEntry } from './log-entry';
import { LogLevel } from './log-level';
import { Logger } from './logger';

@Injectable({ providedIn: 'root' })
export class ConsoleLogger extends Logger {
  constructor() {
    super();
    if (environment.envName === 'production') {
      this.logLevel = LogLevel.Off;
    }
  }

  log(entry: LogEntry): void {
    if (this.shouldLog(entry)) {
      switch (entry.level) {
        case LogLevel.Info:
          console.info('ConsoleLogger', entry);
          break;
        case LogLevel.Error:
          console.error('ConsoleLogger', entry);
          break;
        default:
          console.log('ConsoleLogger', entry);
          break;
      }
    }
  }
}
