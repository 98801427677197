import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Configuration } from '../../configuration';
import { stringify } from 'flatted';
import { InternetConnectionService } from '../internet-connection.service';
import { LogEntry } from './log-entry';
import { Logger } from './logger';

@Injectable({ providedIn: 'root' })
export class SentryLogger extends Logger {
  url: string;
  constructor(
    config: Configuration,
    private connectionService: InternetConnectionService
  ) {
    super();
    this.logLevel = config.webLoggerLogLevel;
  }

  log(entry: LogEntry): void {
    if (this.shouldLog(entry) && this.connectionService.isOnline) {
      try {
        Sentry.captureMessage(JSON.stringify(entry));
      } catch (error) {
        Sentry.captureMessage(stringify(entry));
      }
    }
  }
}
