import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { UnsafeAction } from '../../model/unsafe-action';
import { UiActionTypes } from './ui-actions';

@Injectable()
export class UiEffects {
  constructor(
    private actions$: Actions,
    private messageService: MessageService
  ) {}

  @Effect({ dispatch: false })
  displayMessage = this.actions$.pipe(
    ofType(UiActionTypes.DISPLAY_MESSAGE),
    map((action: UnsafeAction) => {
      this.messageService.add(action.payload);
    })
  );


}
