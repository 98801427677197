import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../configuration';
import { UserInfo } from '../../model/user-info';
import { HttpClientService } from '../http-client.service';

@Injectable({ providedIn: 'root' })
export class UserInfoService {
  urlRoot: string;

  constructor(private config: Configuration, private http: HttpClientService) {
    this.urlRoot = this.config.stsRootUrl + '/userInfos';
  }

  getUserInfoByEmail(email: string): Observable<UserInfo> {
    const url = `${this.urlRoot}?email=${email}`;
    return this.http.authGet<UserInfo>(url);
  }

  getUserInfoBySubject(subject: string): Observable<UserInfo> {
    const url = `${this.urlRoot}/${subject}`;
    return this.http.authGet<UserInfo>(url);
  }

  publishUserInfoUpdateEventsForAllUsers(): Observable<any>{
    const url = `${this.urlRoot}/publish`;
    return this.http.authPost(url, {});
  }
}
