import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
    displayErrorMessage,
    displaySuccessMessage
} from '../core/ui/ui-actions';
import { UnsafeAction } from '../model/unsafe-action';
import { AppState } from '../states/states-reducers';
import { EdbSchoolService } from './edb-school.service';
import {
    ErpActionTypes,
    getEdbSchoolComplete,
    getErpSchoolsComplete,
    getSchoolLandSchoolComplete,
    upsertErpSchoolComplete
} from './erp-actions';
import { ErpSchoolService } from './erp-school.service';
import { SchoolLandSchoolService } from './schoolland-school.service';

@Injectable()
export class ErpEffects {
  constructor(
    private action$: Actions,
    private erpSchoolService: ErpSchoolService,
    private edbSchoolService: EdbSchoolService,
    private schoolLandSchoolService: SchoolLandSchoolService,
    private store: Store<AppState>
  ) {}

  @Effect()
  upsertErpSchool$ = this.action$.pipe(
    ofType(ErpActionTypes.UPSERT_ERP_SCHOOL),
    exhaustMap((action: UnsafeAction) =>
      this.erpSchoolService
        .upsertErpSchool(action.payload.erpSchool)
        .pipe(catchError((_) => of(null)))
    ),
    map((school) => {
      if (school) {
        this.store.dispatch(displaySuccessMessage('School saved'));
        return upsertErpSchoolComplete(school);
      } else {
        return displayErrorMessage('Fail to save School');
      }
    })
  );

  @Effect()
  getSchoolLandSchool$ = this.action$.pipe(
    ofType(ErpActionTypes.GET_SCHOOL_LAND_SCHOOL),
    exhaustMap((action: UnsafeAction) =>
      this.schoolLandSchoolService
        .getSchoolLandSchool(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((school) => {
      if (school) {
        return getSchoolLandSchoolComplete(school);
      } else {
        return displayErrorMessage('Fail to load SchoolLandSchool');
      }
    })
  );

  @Effect()
  getEdbSchool$ = this.action$.pipe(
    ofType(ErpActionTypes.GET_EDB_SCHOOL),
    exhaustMap((action: UnsafeAction) =>
      this.edbSchoolService
        .getEdbSchool(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((school) => {
      if (school === null) {
        return displayErrorMessage('Fail to load EdbSchool');
      } else {
        return getEdbSchoolComplete(school);
      }
    })
  );

  @Effect()
  getErpSchools$ = this.action$.pipe(
    ofType(ErpActionTypes.GET_ERP_SCHOOLS),
    exhaustMap((action: UnsafeAction) =>
      this.erpSchoolService
        .getErpSchools(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((schools) => {
      if (schools === null) {
        return displayErrorMessage('Fail to load ErpSchools');
      } else {
        return getErpSchoolsComplete(schools);
      }
    })
  );
}
