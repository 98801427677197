import { Injectable } from '@angular/core';
import { Configuration } from '../configuration';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserStorageService {
  constructor(
    private localStorageService: LocalStorageService,
    private config: Configuration
  ) {}

  putIanaCode(ianaCode: string): Promise<any> {
    return this.localStorageService.put(
      this.config.uiCodeSelectedStorageKey,
      ianaCode
    );
  }

  getIanaCode(): Promise<string> {
    return this.localStorageService
      .get(this.config.uiCodeSelectedStorageKey)
      .then((value) => {
        if (value && value.value) {
          return value.value;
        } else {
          return value;
        }
      });
  }

  onboardingOnceShown(): Promise<boolean> {
    return this.localStorageService
      .get(this.config.onBoardingOnceShownStorageKey)
      .then((value) => !!value);
  }

  setOnBoardingShown(): Promise<any> {
    return this.localStorageService.put(
      this.config.onBoardingOnceShownStorageKey,
      true
    );
  }
}
