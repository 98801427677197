import { endsWith, isNil, replace, trim } from 'lodash-es';
import { escapeSpecialRegexCharacters } from './regex-utility';

const alphabetLanguageRegex = /^[!-〞 ]*$/;

export function splitCapitalized(str: string): string {
  const result: string[] = [];
  str.split('').forEach((letter) => {
    if (/[A-Z]/.test(letter)) {
      result.push(' ');
    }
    result.push(letter);
  });
  return trim(result.join(''));
}

export function capitalize(str: string): string {
  if(!str){
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function uncapitalize(str: string): string {
  if(!str){
    return str;
  }
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function appendFullStopIfEnglish(str: string) {
  const validEndings = '.!?)]}%,"'.split('');
  if (!str) return str;

  if (!isAlphabetLanguage(str)) return str;

  const trimmed = str.trim();
  let endingIsValid = false;

  validEndings.forEach((c) => {
    if (!endingIsValid) {
      endingIsValid = endsWith(trimmed, c);
    }
  });

  if (endingIsValid) {
    return trimmed;
  } else {
    return trimmed + '.';
  }
}

export function insertAfterIfNotPresent(
  str: string,
  pattern: string,
  insert: string
): string {
  const matchPattern = pattern + '(?!' + insert + ')';
  const regExp = new RegExp(matchPattern, 'g');
  str = replace(str, regExp, pattern + insert);
  return str;
}

export function isAlphabetLanguage(value: string) {
  if (isNil(value)) return true;
  return alphabetLanguageRegex.test(value);
}

export function maskAlphanumeric(
  str: string,
  mask: string,
  maskStart: number
): string {
  if (!mask) return str;
  for (let length = mask.length; length >= maskStart; length--) {
    const maskApplied = mask.substring(0, length);
    str = replaceIfWholeMaskMatch(str, maskApplied);
  }
  return str;
}

export function maskNonAscii(str: string, mask: string): string {
  if (!mask) return str;
  const maskString = getNonAsciiMaskString(mask);
  const regExp = new RegExp(mask, 'gi');
  return replace(str, regExp, maskString);
}

function getNonAsciiMaskString(mask: string) {
  let result = '';
  for (let index = 0; index < mask.length; index++) {
    result += ' ___';
  }
  result += ' ';
  return result;
}

function replaceIfWholeMaskMatch(str: string, mask: string): string {
  try {
    const escapedMask = escapeSpecialRegexCharacters(mask);
    const replacement = replace(mask, /\w/gi, '_');
    const regExp = new RegExp(escapedMask, 'gi');
    return replace(str, regExp, replacement);
  } catch (error) {
    console.error('replaceIfWholeMaskMatch', error);
    return str;
  }
}

export function alternateWith(str: string, insertion: string): string {
  return str.split('').join(insertion);
}

export function formatRegistrationCode(code: string): string {
  code = code.toUpperCase();
  code = replace(code, /-/g, '');
  const chars = code.split('');

  code = '';
  let start = 0;
  for (let index = 0; index < 4; index++) {
    code = code + chars.slice(start, start + 8).join('') + '-';
    start = start + 8;
  }
  return code.substring(0, code.length - 1);
}

export function unformatRegistrationCode(code: string): string {
  if (!code) {
    return code;
  }
  code = code.toLowerCase();
  code = replace(code, /-/g, '');

  let result = '';

  for (let index = 0; index < code.length; index++) {
    result += code[index];
    if (index === 7 || index === 11 || index === 15 || index === 19) {
      result += '-';
    }
  }
  return result;
}

export function replaceAll(
  str: string,
  regexPattern: string,
  replacement: string
): string {
  const reg = new RegExp(regexPattern, 'g');
  return str.replace(reg, replacement);
}
