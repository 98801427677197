import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserAuthenticationService } from './core/user-authentication.service';
import { clearUserState } from './core/user/user-actions';
import { AppState } from './states/states-reducers';

@Component({
  template: ''
})
export class SignoutComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private authService: UserAuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.signoutRedirectCallback().then((_) => {
      this.router.navigate(['/home']);
      this.store.dispatch(clearUserState());
    });
  }
}
