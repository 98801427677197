<ng-container>
  <div *ngIf="root">
    <span class="layout-menuitem-text">{{ item.label }}</span>
  </div>
  <a
    (click)="itemClick($event)"
    *ngIf="!item.routerLink || item.items"
    (keydown.enter)="itemClick($event)"
    [attr.target]="item.target"
    [attr.tabindex]="0"
    [ngClass]="item.class"
    (mouseenter)="onMouseEnter()"
    pTooltip="{{ item.label | translate }}"
    [tooltipDisabled]="active || !(root && app.isSlim() && !app.isMobile())"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label | translate }}</span>
    <span
      class="p-badge p-component p-badge-no-gutter"
      [ngClass]="item.badgeClass"
      *ngIf="item.badge && !root"
      >{{ item.badge }}</span
    >
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>
  <a
    (click)="itemClick($event)"
    *ngIf="item.routerLink && !item.items"
    [routerLink]="item.routerLink"
    routerLinkActive="active-menuitem-routerlink"
    [routerLinkActiveOptions]="{ exact: true }"
    [attr.target]="item.target"
    [attr.tabindex]="0"
    [ngClass]="item.class"
    (mouseenter)="onMouseEnter()"
    pTooltip="{{ item.label | translate }}"
    [tooltipDisabled]="active || !(root && app.isSlim() && !app.isMobile())"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">{{ item.label | translate }}</span>
    <span
      class="p-badge p-component p-badge-no-gutter"
      [ngClass]="item.badgeClass"
      *ngIf="item.badge && !root"
      >{{ item.badge }}</span
    >
    <i
      class="pi pi-fw pi-angle-down layout-submenu-toggler"
      *ngIf="item.items"
    ></i>
  </a>
  <ul *ngIf="(item.items && root) || (item.items && active)">
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li
        app-menuitem
        [item]="child"
        [index]="i"
        [parentKey]="key"
        [class]="child.badgeClass"
      ></li>
    </ng-template>
  </ul>
</ng-container>
