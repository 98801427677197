import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErpSchoolManagementComponent } from '../erp-ui/erp-school-management.component';

const routes: Routes = [
  { path: 'schools', component: ErpSchoolManagementComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErpRoutingModule {}
