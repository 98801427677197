// angular
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
// ngrx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { UserManager } from 'oidc-client';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
// application
import { AppRoutingModule } from './app-routing.module';
// 3rd party
import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { AppComponent } from './app.component';
import { serviceWorkerSriptFile } from './configuration';
import { CoreModule } from './core/core.module';
import { PrincipalForumModule } from './principal-forum/principal-forum.module';
import { ShellModule } from './shell/shell.module';
import { SigninComponent } from './signin';
import { SignoutComponent } from './signout';
import {
  INITIAL_STATE,
  metaReducers,
  reducers
} from './states/states-reducers';
import { CustomRouterStateSerializer } from './states/store-utiltiy';
import { userManagerFactory } from './user-manager-settings';

Chart.register(annotationPlugin);

@NgModule({
  imports: [
    // angular
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register(serviceWorkerSriptFile, {
      enabled:
      !navigator.userAgent.toUpperCase().includes('LIKE MAC')
        ? true
        : ((navigator.userAgent.includes('Version/17.0') || navigator.userAgent.includes('Version/17.1')) ?
          false : true),
      registrationStrategy: 'registerImmediately'
    }),

    // routing
    AppRoutingModule,

    // ngrx
    StoreModule.forRoot(reducers, {
      initialState: INITIAL_STATE,
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true
      }
    }),
    StoreRouterConnectingModule.forRoot(),
    environment.enableDebugTools
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : [],

    ToastModule,
    // application
    ShellModule,
    CoreModule,
    PrincipalForumModule
  ],
  declarations: [AppComponent, SigninComponent, SignoutComponent],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
    {
      provide: UserManager,
      useFactory: () => userManagerFactory()
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.showSentryDialog
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'SolidMemory Storage',
        driver: [ // defaults to indexedDB -> webSQL -> localStorage
          Driver.INDEXED_DB,
          Driver.WEB_SQL,
          Driver.LOCAL_STORAGE
        ]
      } as NgForageOptions
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
