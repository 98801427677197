import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppMainComponent } from './app.main.component';
import { MenuService } from './app.menu.service';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-menuitem]',
  /* eslint-enable @angular-eslint/component-selector */
  templateUrl: 'app.menuitem.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.layout-root-menuitem]': 'root || active',
    '[class.active-menuitem]': '(active)'
  }
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
  @Input() item: any;

  @Input() index: number;

  @Input() root: boolean;

  @Input() parentKey: string;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string;

  constructor(
    public app: AppMainComponent,
    public router: Router,
    private menuService: MenuService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
      (key) => {
        // deactivate current active menu
        if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
          this.active = false;
        }
      }
    );

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_) => {
        if (this.app.isHorizontal() || this.app.isSlim()) {
          this.active = false;
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          } else {
            this.active = false;
          }
        }
      });
  }

  ngOnInit() {
    if (
      !(this.app.isHorizontal() || this.app.isSlim()) &&
      this.item.routerLink
    ) {
      this.updateActiveStateFromRoute();
    }

    this.key = this.parentKey
      ? this.parentKey + '-' + this.index
      : String(this.index);
  }

  updateActiveStateFromRoute() {
    this.active = this.router.isActive(
      this.item.routerLink[0],
      this.item.items ? false : true
    );
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return true;
    }

    // navigate with hover in horizontal mode
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;

      // reset horizontal and slim menu
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.menuService.reset();
        this.app.menuHoverActive = false;
      }

      if (!this.app.isStatic()) {
        this.app.menuActive = false;
      }

      this.app.mobileMenuActive = false;
    }

    this.removeActiveInk(event);

    return false;
  }

  onMouseEnter() {
    // activate item on hover
    if (
      this.root &&
      (this.app.isHorizontal() || this.app.isSlim()) &&
      this.app.isDesktop()
    ) {
      if (this.app.menuHoverActive) {
        this.menuService.onMenuStateChange(this.key);
        this.active = true;
      }
    }
  }

  removeActiveInk(event: Event) {
    let currentTarget = event.currentTarget as HTMLElement;
    setTimeout(() => {
      if (currentTarget) {
        let activeInk = currentTarget.querySelector('.p-ink-active');
        if (activeInk) {
          if (activeInk.classList) activeInk.classList.remove('p-ink-active');
          else
            activeInk.className = activeInk.className.replace(
              new RegExp(
                '(^|\\b)' + 'p-ink-active'.split(' ').join('|') + '(\\b|$)',
                'gi'
              ),
              ' '
            );
        }
      }
    }, 401);
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
