import { Injectable } from '@angular/core';
import { isInteger, isNil } from 'lodash-es';
import { Configuration } from '../../configuration';
import { Language } from '../../model/language';
import { Level } from '../../model/level';
import { UICulture } from '../../model/ui-culture';
import { DictionaryService } from '../../smart-ui/dictionary/dictionary.service';
import { isNilOrWhiteSpace } from '../../utility/string-utility';

@Injectable({ providedIn: 'root' })
export class PreloadingService {
  constructor(
    private dictionaryService: DictionaryService,
    private config: Configuration
  ) {}

  preloadDictEntriesInParallel(
    phrases: string[],
    uiCulture: UICulture,
    syllabusLanguage: Language,
    dictionaryLevel: Level
  ): Promise<any> {
    const batchSize = Math.min(this.config.preloadBatchSize, phrases.length);
    const promises: Promise<any>[] = [];
    for (let i = 0; i < batchSize; i++) {
      const phrase = phrases[i];
      let promise: Promise<any> = Promise.resolve();
      promise = this.preloadDictEntries(
        promise,
        phrase,
        uiCulture,
        syllabusLanguage,
        dictionaryLevel
      );
      promises.push(promise);
    }
    return Promise.all(promises);
  }

  preloadDictEntries(
    promise: Promise<any>,
    phrase: string,
    uiCulture: UICulture,
    syllabusLanguage: Language,
    dictionaryLevel: Level
  ): Promise<any> {
    return promise.then((_) => {
      if (
        !isNilOrWhiteSpace(phrase) &&
        isInteger(uiCulture) &&
        isInteger(syllabusLanguage) &&
        isInteger(dictionaryLevel)
      ) {
        return this.dictionaryService
          .getDictEntries(
            phrase,
            uiCulture,
            syllabusLanguage,
            dictionaryLevel,
            false,
            false
          )
          .toPromise();
      } else {
        return Promise.resolve([]);
      }
    });
  }

  preloadFirstBatchVocabularyData(
    phrases: string[],
    dictionaryLevel: Level,
    syllabusLanguage: Language,
    uiCulture: UICulture
  ): Promise<any> {
    if (
      !isInteger(dictionaryLevel) ||
      !isInteger(syllabusLanguage) ||
      !isInteger(uiCulture) ||
      isNil(phrases) ||
      phrases.length === 0
    ) {
      return Promise.resolve();
    }

    return this.preloadDictEntriesInParallel(
      phrases,
      uiCulture,
      syllabusLanguage,
      dictionaryLevel
    ).then((_) => {
      this.preloadDictEntriesInSeries(
        phrases,
        dictionaryLevel,
        syllabusLanguage,
        uiCulture
      );
    });
  }

  preloadDictEntriesInSeries(
    phrases: string[],
    dictionaryLevel: Level,
    syllabusLanguage: Language,
    uiCulture: UICulture
  ): Promise<any> {
    let promise: Promise<any> = Promise.resolve();
    for (let i = this.config.preloadBatchSize; i < phrases.length; i++) {
      const phrase = phrases[i];
      promise = this.preloadDictEntries(
        promise,
        phrase,
        uiCulture,
        syllabusLanguage,
        dictionaryLevel
      );
    }
    return promise;
  }

  preloadVocabularyData(
    phrases: string[],
    dictionaryLevel: Level,
    syllabusLanguage: Language,
    uiCulture: UICulture
  ): Promise<any> {
    return this.preloadFirstBatchVocabularyData(
      phrases,
      dictionaryLevel,
      syllabusLanguage,
      uiCulture
    );
  }
}
