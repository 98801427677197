import { OperationResult } from '../model/operation-result';
import { UnsafeAction } from '../model/unsafe-action';
import { type } from '../utility/type';
import { ExerciseCompletionNotificationSubscription } from './exercise-completion-notification-subscription';

export const AccountActionTypes = {
  GET_REGISTRATION_CODES: type('[Account] Get Registration Codes'),
  GET_REGISTRATION_CODES_COMPLETE: type(
    '[Account] Get Registration Codes Complete'
  ),

  ADD_REGISTRATION_CODE: type('[Account] Add Registration Code'),
  ADD_REGISTRATION_CODE_COMPLETE: type(
    '[Account] Add Registration Code Complete'
  ),

  CHANGE_PASSWORD: type('[Account] Change Password'),
  CHANGE_PASSWORD_SUCCESS: type('[Account] Change Password Success'),
  CHANGE_PASSWORD_FAIL: type('[Account] Change Password Fail'),

  CHANGE_EMAIL_ADDRESS: type('[Account] Change Email Address'),
  CHANGE_EMAIL_ADDRESS_SUCCESS: type('[Account] Change Email Address Success'),
  CHANGE_EMAIL_ADDRESS_FAIL: type('[Account] Change Email Address Fail'),

  ADD_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION: type(
    '[Account] Add Exercise Completion Notification Subscription'
  ),
  ADD_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION_COMPLETE: type(
    '[Account] Add Exercise Completion Notification Subscription Complete'
  ),

  GET_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTIONS: type(
    '[Account] Get Exercise Completion Notification Subscriptions'
  ),
  GET_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTIONS_COMPLETE: type(
    '[Account] Get Exercise Completion Notification Subscriptions Complete'
  ),

  DELETE_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION: type(
    '[Account] Delete Exercise Completion Notification subscription'
  ),
  DELETE_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION_COMPLETE: type(
    '[Account] Delete Exercise Completion Notification subscription Complete'
  )
};

export function deleteExerciseCompletionNotificationSubscription(id: string) {
  return {
    type: AccountActionTypes.DELETE_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION,
    payload: id
  };
}

export function deleteExerciseCompletionNotificationSubscriptionComplete(
  id: string
) {
  return {
    type: AccountActionTypes.DELETE_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION_COMPLETE,
    payload: id
  };
}

export function getExerciseCompletionNotificationSubscriptions() {
  return {
    type: AccountActionTypes.GET_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTIONS
  };
}

export function getExeciseCompletionNotificationSubscriptionsComplete(
  subscriptions: ExerciseCompletionNotificationSubscription[]
) {
  return {
    type: AccountActionTypes.GET_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTIONS_COMPLETE,
    payload: subscriptions
  };
}

export function addExerciseCompletionNotificationSubscription(
  subscription: ExerciseCompletionNotificationSubscription
) {
  return {
    type: AccountActionTypes.ADD_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION,
    payload: subscription
  };
}

export function addExerciseCompletionNotificationSubscriptionComplete(
  subscription: ExerciseCompletionNotificationSubscription
) {
  return {
    type: AccountActionTypes.ADD_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION_COMPLETE,
    payload: subscription
  };
}

export function getRegistrationCodesComplete(codes: string[]): UnsafeAction {
  return {
    type: AccountActionTypes.GET_REGISTRATION_CODES_COMPLETE,
    payload: codes
  };
}

export function getRegistrationCodes(): UnsafeAction {
  return { type: AccountActionTypes.GET_REGISTRATION_CODES };
}

export function addRegistrationCode(code: string): UnsafeAction {
  return {
    type: AccountActionTypes.ADD_REGISTRATION_CODE,
    payload: code
  };
}

export function addRegistrationCodeComplete(code: string): UnsafeAction {
  return {
    type: AccountActionTypes.ADD_REGISTRATION_CODE_COMPLETE,
    payload: code
  };
}

export function changePassword(
  currentPassword: string,
  newPassword: string
): UnsafeAction {
  return {
    type: AccountActionTypes.CHANGE_PASSWORD,
    payload: { currentPassword, newPassword }
  };
}

export function changePasswordSuccess(): UnsafeAction {
  return { type: AccountActionTypes.CHANGE_PASSWORD_SUCCESS };
}

export function changePasswordFail(): UnsafeAction {
  return { type: AccountActionTypes.CHANGE_PASSWORD_FAIL };
}

export function changeEmailAddress(
  newEmailAddress: string,
  ianaCode: string
): UnsafeAction {
  return {
    type: AccountActionTypes.CHANGE_EMAIL_ADDRESS,
    payload: { newEmailAddress, ianaCode }
  };
}

export function changeEmailAddressSuccess(): UnsafeAction {
  return { type: AccountActionTypes.CHANGE_EMAIL_ADDRESS_SUCCESS };
}

export function changeEmailAddressFail(
  operationResult: OperationResult
): UnsafeAction {
  return {
    type: AccountActionTypes.CHANGE_EMAIL_ADDRESS_FAIL,
    payload: { operationResult }
  };
}
