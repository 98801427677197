import * as iassign from 'immutable-assign';
import { uniqBy } from 'lodash-es';
import { UnsafeAction } from '../model/unsafe-action';
import { AccountActionTypes } from './account-actions';
import { ExerciseCompletionNotificationSubscription } from './exercise-completion-notification-subscription';

export interface AccountState {
  registrationCodes: string[];
  exerciseCompletionNotificationSubscriptions: ExerciseCompletionNotificationSubscription[];
}

export const INITIAL_ACCOUNT_STATE: AccountState = {
  registrationCodes: [],
  exerciseCompletionNotificationSubscriptions: []
};

export function accountStateReducer(
  state: AccountState = INITIAL_ACCOUNT_STATE,
  { type, payload }: UnsafeAction
): AccountState {
  switch (type) {
    case AccountActionTypes.DELETE_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseCompletionNotificationSubscriptions =
          s.exerciseCompletionNotificationSubscriptions.filter(
            (sub) => sub.id !== payload
          );
        return s;
      });
    case AccountActionTypes.ADD_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTION_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseCompletionNotificationSubscriptions = uniqBy(
          [...s.exerciseCompletionNotificationSubscriptions, payload],
          (sub) => sub.id
        );
        return s;
      });
    case AccountActionTypes.GET_EXERCISE_COMPLETION_NOTIFICATION_SUBSCRIPTIONS_COMPLETE:
      return iassign(state, (s) => {
        s.exerciseCompletionNotificationSubscriptions = payload;
        return s;
      });
    case AccountActionTypes.GET_REGISTRATION_CODES_COMPLETE:
      return iassign(state, (s) => {
        s.registrationCodes = payload;
        return s;
      });
    case AccountActionTypes.ADD_REGISTRATION_CODE_COMPLETE:
      return iassign(state, (s) => {
        if (payload) {
          s.registrationCodes = [...s.registrationCodes, payload];
        }
        return s;
      });
    default:
      return state;
  }
}
