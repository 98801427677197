import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectErpSchool } from '../erp/erp-actions';
import { ErpSchool } from '../erp/erp-school';
import { erpSchoolSelector } from '../erp/erp-state-selectors';
import { AppState } from '../states/states-reducers';

@Component({
  selector: 'app-erp-school-management',
  templateUrl: './erp-school-management.component.html'
})
export class ErpSchoolManagementComponent implements OnInit {
  showItem: boolean;
  erpSchool$: Observable<ErpSchool>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.erpSchool$ = this.store.select(erpSchoolSelector);
  }

  onSchoolClick(school: ErpSchool) {
    this.showItem = true;
    this.store.dispatch(
      selectErpSchool(
        school.schoolLevel,
        school.schoolNumber,
        school.session,
        school.locationId
      )
    );
  }
}
