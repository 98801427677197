import { ErpSchool } from './erp-school';

const regex = /(\d{6})-(\d{1})-(\d{1})-(\d{1})/;

export function createSchoolCode(erpSchool: ErpSchool): string {
  if (!erpSchool) return null;
  const locationId = Number.parseInt(erpSchool.locationId, null);
  const sessionId = getSessionId(erpSchool.session);
  return `${erpSchool.schoolNumber}-${erpSchool.schoolLevel}-${locationId}-${sessionId}`;
}

function getSessionId(session: string): number {
  switch (session) {
    case 'WHOLE DAY':
      return 1;
    case 'AM':
      return 2;
    case 'PM':
      return 3;
    default:
      return 0;
  }
}

function getSessionById(id: string) {
  switch (id) {
    case '1':
      return 'WHOLE DAY';
    case '2':
      return 'AM';
    case '3':
      return 'PM';
    default:
      return '';
  }
}

export function getSchoolParameters(schoolCode: string): any {
  const result = regex.exec(schoolCode);
  if (!result) {
    return null;
  }
  return {
    schoolNumber: result[1],
    schoolLevel: +result[2],
    locationId: result[3],
    session: getSessionById(result[4])
  };
}
