import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Configuration } from '../../configuration';
import { isNilOrWhiteSpace } from '../../utility/string-utility';
import { debounceTime, filter, tap, throttleTime } from 'rxjs/operators';
import { UnsafeAction } from '../../model/unsafe-action';
import { PronunciationActionTypes } from './pronunciation-actions';
import { PronunciationService } from './pronunciation.service';

@Injectable()
export class PronunciationEffects {
  constructor(
    private actions$: Actions,
    private pronunciationService: PronunciationService,
    private config: Configuration
  ) {}

  @Effect({ dispatch: false })
  pronouncePronunciationId: any = this.actions$.pipe(
    ofType(PronunciationActionTypes.PRONOUNCE_PRONUNCIATION_ID),
    filter(
      (action: UnsafeAction) =>
        !isNilOrWhiteSpace(action.payload.pronunciationId)
    ),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    tap((action: UnsafeAction) => {
      this.pronunciationService.playPronunciationId(
        action.payload.pronunciationId
      );
    })
  );

  @Effect({ dispatch: false })
  pronounce: any = this.actions$.pipe(
    ofType(PronunciationActionTypes.PRONOUNCE),
    filter((action: UnsafeAction) => !isNilOrWhiteSpace(action.payload.phrase)),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    tap((action: UnsafeAction) => {
      this.pronunciationService.play(
        action.payload.phrase,
        action.payload.pronunciation
      );
    })
  );
}
