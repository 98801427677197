import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError, exhaustMap, map, tap,
  withLatestFrom
} from 'rxjs/operators';
import { UnsafeAction } from '../../model/unsafe-action';
import { Syllabus } from '../../problem-set/model/syllabus';
import { AppState } from '../../states/states-reducers';
import { displayErrorMessage, displaySuccessMessage } from '../ui/ui-actions';
import { getProblemSetSetting } from './problem-set-setting-actions';
import {
  changeSyllabusLanguage,
  createSyllabusAdminRegistrationComplete,
  getDetailedSyllabus,
  getDetailedSyllabusComplete,
  getSyllabiComplete,
  getSyllabiWithSyllabusAdminRight,
  getSyllabusAdminRegistrations,
  getSyllabusAdminRegistrationsComplete,
  SyllabusActionTypes
} from './syllabus-actions';
import { detailedSyllabusSelector } from './syllabus-state-selectors';
import { SyllabusService } from './syllabus.service';

@Injectable()
export class SyllabusEffects {
  constructor(
    private service: SyllabusService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}

  @Effect({ dispatch: false })
  editSyllabus = this.actions$.pipe(
    ofType(SyllabusActionTypes.EDIT_SYLLABUS),
    tap((action: UnsafeAction) => {
      const syllabusId = action.payload;
      this.store.dispatch(getDetailedSyllabus(syllabusId));
      this.store.dispatch(getProblemSetSetting(syllabusId));
      this.store.dispatch(getSyllabusAdminRegistrations(syllabusId));
    })
  );

  @Effect()
  getSyllabiWithSyllabusAdminRights = this.actions$.pipe(
    ofType(SyllabusActionTypes.GET_SYLLABI_WITH_SYLLABUS_ADMIN_RIGHTS),
    exhaustMap((action: UnsafeAction) =>
      this.service
        .getSyllabiWithSyllabusAdminRights(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((syllabi) => {
      if (syllabi) {
        return getSyllabiComplete(syllabi);
      } else {
        return displayErrorMessage('Fail to get Syllabi');
      }
    })
  );

  @Effect()
  getSyllabi = this.actions$.pipe(
    ofType(SyllabusActionTypes.GET_SYLLABI),
    exhaustMap((_: UnsafeAction) =>
      this.service.getSyllabi().pipe(catchError((_) => of(null)))
    ),
    map((syllabi) => {
      if (syllabi) {
        return getSyllabiComplete(syllabi);
      } else {
        return displayErrorMessage('Fail to get Syllabi');
      }
    })
  );

  @Effect()
  getSyllabiBySyllabusIds = this.actions$.pipe(
    ofType(SyllabusActionTypes.GET_SYLLABI_BY_SYLLABUSIDS),
    exhaustMap((action: UnsafeAction) =>
      this.service
        .getSyllabiBySyllabusIds(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((syllabi) => {
      if (syllabi) {
        return getSyllabiComplete(syllabi);
      } else {
        return displayErrorMessage('Fail to get Syllabi');
      }
    })
  );

  @Effect()
  createSyllabus$ = this.actions$.pipe(
    ofType(SyllabusActionTypes.CREATE_SYLLABUS),
    exhaustMap((action: UnsafeAction) =>
      this.service.createSyllabus(action.payload).pipe(
        map((success) => [success, action.payload.schoolId]),
        catchError((_) => of([false, null]))
      )
    ),
    map(([succeeded, schoolId]) => {
      if (succeeded) {
        this.store.dispatch(getSyllabiWithSyllabusAdminRight(schoolId));
        return displaySuccessMessage('Syllabus Created');
      } else {
        return displayErrorMessage('Create Syllabus Failed');
      }
    })
  );

  @Effect()
  getDetailedSyllabus = this.actions$.pipe(
    ofType(SyllabusActionTypes.GET_DETAILED_SYLLABUS),
    exhaustMap((action: UnsafeAction) =>
      this.service
        .getDetailedSyllabus(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((syllabus) => {
      if (syllabus) {
        return getDetailedSyllabusComplete(syllabus);
      } else {
        return displayErrorMessage('Fail to get syllabus');
      }
    })
  );

  @Effect()
  getDetailedSyllabusComplete = this.actions$.pipe(
    ofType(SyllabusActionTypes.GET_DETAILED_SYLLABUS_COMPLETE),
    map((action: UnsafeAction) => {
      const syllabus: Syllabus = action.payload;
      return changeSyllabusLanguage(syllabus.language);
    })
  );

  @Effect()
  saveDetailedSyllabus = this.actions$.pipe(
    ofType(SyllabusActionTypes.SAVE_DETAILED_SYLLABUS),
    withLatestFrom(this.store.select(detailedSyllabusSelector)),
    exhaustMap(([_, detailedSyllabus]: [UnsafeAction, Syllabus]) => {
      return this.service
        .updateSyllabus(detailedSyllabus)
        .pipe(catchError((_) => of(false)));
    }),
    map((succeeded) => {
      if (succeeded) {
        return displaySuccessMessage('Syllabus Updated');
      } else {
        return displayErrorMessage('Fail to update syllabus');
      }
    })
  );

  @Effect()
  createSyllabusAdminRegistration = this.actions$.pipe(
    ofType(SyllabusActionTypes.CREATE_SYLLABUS_ADMIN_REGISTRATION),
    exhaustMap((action: UnsafeAction) =>
      this.service.createSyllabusAdminRegistration(action.payload).pipe(
        catchError((_) => of(false)),
        map((success) => [success, action.payload])
      )
    ),
    map(([success, code]) => {
      if (success) {
        return createSyllabusAdminRegistrationComplete(code);
      } else {
        return displayErrorMessage('Fail to create registration Code');
      }
    })
  );

  @Effect()
  getSyllabusAdminRegistrations = this.actions$.pipe(
    ofType(SyllabusActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS),
    exhaustMap((action: UnsafeAction) =>
      this.service
        .getSyllabusAdminRegistrations(action.payload)
        .pipe(catchError((_) => of(null)))
    ),
    map((registrations) => {
      if (registrations) {
        return getSyllabusAdminRegistrationsComplete(registrations);
      } else {
        return displayErrorMessage(
          'Fail to get syllabus Admin Registration Codes'
        );
      }
    })
  );
}
