import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoBackDirective } from './go-back.directive';
import { LandingPreferenceComponent } from './landing-preference.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LandingPreferenceComponent, GoBackDirective],
  exports: [LandingPreferenceComponent, GoBackDirective]
})
export class NavigationModule {}
