import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { TranslationService } from '../../core/translation/translation.service';
import {
  displayErrorMessage,
  displayInfoMessage
} from '../../core/ui/ui-actions';
import { UnsafeAction } from '../../model/unsafe-action';
import { AppState } from '../../states/states-reducers';
import {
  blameImagesComplete,
  endorseImagesComplete,
  getThumbnailsComplete,
  resetImagesComplete,
  ThumbnailBandActionTypes
} from './thumbnail-band-actions';
import { ThumbnailService } from './thumbnail.service';

@Injectable()
export class ThumbnailBandEffects {
  constructor(
    private actions$: Actions,
    private service: ThumbnailService,
    private translationService: TranslationService,
    private store: Store<AppState>
  ) {}

  @Effect() getThumbnails = this.actions$.pipe(
    ofType(ThumbnailBandActionTypes.GET_THUMBNAILS),
    filter((action: UnsafeAction) => !!action.payload),
    switchMap((action: UnsafeAction) => {
      return this.service
        .getThumbnails(action.payload)
        .pipe(catchError((_) => of([])));
    }),
    map((values) => {
      return getThumbnailsComplete(values);
    })
  );

  @Effect() endorseImages = this.actions$.pipe(
    ofType(ThumbnailBandActionTypes.ENDORSE_IMAGES),
    mergeMap((action: UnsafeAction) => {
      const ids: string[] = action.payload;
      return this.service.endorseImages(ids).pipe(
        mapTo(ids),
        catchError((_) => of(null))
      );
    }),
    map((ids) => {
      if (ids) {
        this.store.dispatch(displayInfoMessage('Images endorsed'));
        return endorseImagesComplete(ids);
      } else {
        return displayErrorMessage('Endorse Images failed');
      }
    })
  );

  @Effect() blameImages = this.actions$.pipe(
    ofType(ThumbnailBandActionTypes.BLAME_IMAGES),
    mergeMap((action: UnsafeAction) => {
      const ids: string[] = action.payload;
      return this.service.blameImages(ids).pipe(
        mapTo(ids),
        catchError((_) => of(null))
      );
    }),
    map((ids) => {
      if (ids) {
        this.store.dispatch(
          displayInfoMessage(
            this.translationService.getTranslationInstant(
              'image.thank-you-for-the-report'
            )
          )
        );
        return blameImagesComplete(ids);
      } else {
        return displayErrorMessage('Blame Images failed');
      }
    })
  );

  @Effect() resetImages = this.actions$.pipe(
    ofType(ThumbnailBandActionTypes.RESET_IMAGES),
    mergeMap((action: UnsafeAction) => {
      const ids: string[] = action.payload;
      return this.service.resetImages(ids).pipe(
        mapTo(ids),
        catchError((_) => of(null))
      );
    }),
    map((ids) => {
      if (ids) {
        this.store.dispatch(displayInfoMessage('Images reset'));
        return resetImagesComplete(ids);
      } else {
        return displayErrorMessage('Reset Images failed');
      }
    })
  );
}
