import {
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { User } from 'oidc-client';
import { SubSink } from 'subsink';
import { UserAuthenticationService } from '../../core/user-authentication.service';

@Directive({
  selector: '[appShowIfLoggedIn]'
})
export class ShowIfLoggedInDirective implements OnDestroy {
  private subs = new SubSink();
  hasView = false;

  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.subs.sink = this.userAuthenticationService.user$.subscribe((user) => {
      this.toggleView(user);
    });
  }

  toggleView(user: User) {
    if (this.viewContainer) {
      if (!user && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      } else if (user && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
