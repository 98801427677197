import { some } from 'lodash-es';
import { QuestionDTO } from '../model/questionDTO';
import {
  isCapital,
  isNilOrWhiteSpace,
  normalizeApostrophes
} from './string-utility';

// export function containsNumeral(input: string): boolean {
//     var regex = /[.0-9+-]+/;
//     return regex.test(input);
// }

const spacialRegexCharacters = '[^$.|?*+()'.split('');
const LEGAL_ALPHABETS =
  /[-0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' ]/;

const izeReg = /(.*)(i[s|z]e)(.?)$/i;

export function isAlternativeSpelling(
  loweredInput: string,
  question: QuestionDTO
) {
  for (let index = 0; index < question.answers.length; index++) {
    const loweredAnswerText = question.answers[index].text.toLowerCase();
    const loweredInputMatch = izeReg.exec(loweredInput);
    const loweredAnswerTextMatch = izeReg.exec(loweredAnswerText);
    if (loweredInputMatch && loweredAnswerTextMatch) {
      const result =  loweredInputMatch[1] === loweredAnswerTextMatch[1] && loweredInputMatch[3] === loweredAnswerTextMatch[3];
      return result;
    }
  }
  return false;
}

export function maskIllegalAlphabets(
  input: string,
  replacement: string = '_'
): string {
  const chars = input.split('');
  for (let index = 0; index < chars.length; index++) {
    const element = chars[index];
    if (!LEGAL_ALPHABETS.test(element)) {
      chars[index] = replacement;
    }
  }
  return chars.join('');
}

export function verbalAttemptIsCorrect(
  acceptableAnswer: string,
  attempt: string,
  isCaseSensitive?: boolean
) {
  if (isNilOrWhiteSpace(attempt) || isNilOrWhiteSpace(acceptableAnswer)) {
    return false;
  }
  acceptableAnswer = normalizeApostrophes(acceptableAnswer.trim());
  attempt = normalizeApostrophes(attempt).substring(0, acceptableAnswer.length);

  const matches = izeReg.exec(acceptableAnswer);

  if (matches) {
    let suffix = matches[2];
    const prefix = matches[1];
    const suffixArray = suffix.split('');

    if (isCapital(suffixArray[1])) {
      suffixArray[1] = '[S|Z]';
    } else {
      suffixArray[1] = '[s|z]';
    }

    suffix = suffixArray.join('');

    let answerPattern = new RegExp(prefix + suffix);

    if (!isCaseSensitive) {
      answerPattern = new RegExp(prefix + suffix, 'i');
    }

    const result =  answerPattern.test(attempt);
    return result;

  } else {
    if (isCaseSensitive) {
      return acceptableAnswer === attempt;
    } else {
      return acceptableAnswer.toLowerCase() === attempt.toLowerCase();
    }
  }
}

export function numericalAttemptIsCorrect(
  acceptableAnswer: string,
  attempt: string
): boolean {
  if (isNilOrWhiteSpace(acceptableAnswer) || isNilOrWhiteSpace(attempt))
    return false;

  const loweredAcceptableAnswer = acceptableAnswer.toLowerCase();
  const loweredAttempt = attempt.toLowerCase();

  if (loweredAcceptableAnswer === loweredAttempt) return true;

  const acceptableAnswerValue = Number.parseFloat(loweredAcceptableAnswer);
  const attemptValue = Number.parseFloat(loweredAttempt);

  if (acceptableAnswerValue === attemptValue) return true;

  return false;
}

export function escapeSpecialRegexCharacters(input: string): string {
  return input
    .split('')
    .map((c) => escapeIfSpecialRegexCharacter(c))
    .join('');
}

function escapeIfSpecialRegexCharacter(character: string): string {
  if (some(spacialRegexCharacters, (c) => c === character)) {
    return '\\' + character;
  } else {
    return character;
  }
}
