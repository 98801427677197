import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DynamicDialogConfigBuilder } from 'src/app/dynamic-dialog-config-builder';
import { VocabEntryUpdateDTO } from 'src/app/model/vocab-entry-update-dto';
import { VocabExerciseReminderDialogComponent } from 'src/app/vocab-ui/vocab-exercise-reminder-dialog.component';
import { Configuration } from '../../configuration';
import { Language } from '../../model/language';
import { VocabEntriesStatus } from '../../model/vocab-entries.status';
import { VocabEntryUpdatedResult } from '../../model/vocab-entry-upserted-result';
import { AppState } from '../../states/states-reducers';
import { HttpClientService } from '../http-client.service';
import { VocabEntriesStatusReceived } from './vocab-core-actions';

@Injectable({ providedIn: 'root' })
export class VocabularyService {
  ref: DynamicDialogRef;

  constructor(
    private httpService: HttpClientService,
    private config: Configuration,
    private store: Store<AppState>,
    private dialogService: DialogService
  ) {}

  upsertVocabEntry(
    updateDTO: VocabEntryUpdateDTO
  ): Observable<VocabEntryUpdatedResult> {
    const url = this.config.apiRootUrl + '/vocabulary/vocabEntries';

    return this.httpService
      .authPost<VocabEntryUpdatedResult>(url, updateDTO)
      .pipe(
        tap((result) => {
          result.vocabEntry = updateDTO;
          this.store.dispatch(VocabEntriesStatusReceived(result));
          if (result.newCount >= this.config.newVocabularySoftLimit) {
            const builder = new DynamicDialogConfigBuilder();
            builder.maxHeight = '92%';
            builder.overflow_x = 'hidden';
            this.ref = this.dialogService.open(
              VocabExerciseReminderDialogComponent,
              builder.build()
            );
          }
        })
      );
  }

  prioritizeVocabEntry(language: Language, phrase: string) {
    const url = `${
      this.config.apiRootUrl
    }/vocabulary/vocabEntries/${language}?phrase=${encodeURI(phrase)}`;
    return this.httpService.authPut(url, null);
  }

  getVocabEntriesStatusOfUser(language: Language): Observable<VocabEntriesStatus> {
    const url =
      this.config.apiRootUrl + '/vocabulary/vocabEntriesStatuses/' + language;
    return this.httpService
      .authGet<VocabEntriesStatus>(url)
      .pipe(
        tap((result) => this.store.dispatch(VocabEntriesStatusReceived(result)))
      );
  }

  getVocabEntriesStatusBySubject(language: Language, subject: string): Observable<VocabEntriesStatus> {
    const url =
      `${this.config.apiRootUrl}/vocabulary/vocabEntriesStatuses/${language}/${subject}`;
    return this.httpService.authGet<VocabEntriesStatus>(url);
  }
}
