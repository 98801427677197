import { Message } from 'primeng/api';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const UiActionTypes = {
  DISPLAY_MESSAGE: type('[UI] Display Message'),
  SHOW_TOPBAR: type('[UI] Show topbar'),
  HIDE_TOPBAR: type('[UI] Hide topbar'),
  SHOW_SPINNER: type('[UI] Show Spinner'),
  HIDE_SPINNER: type('[UI] Hide Spinner'),
  NULL_ACTION: type('[UI] Null Action'),
  UPDATE_PUSH_SUBSCRIPTION: type('[UI] Update Push Subscription'),
  FLAG_REQUEST_SUBSCRIPTION_ERROR: type('[UI] Flag Rquest Subscription Error'),
  SHOW_LOCALSTORAGE_WARNING: '[UI] Show localstorage warning',
  HIDE_LOCALSTORAGE_WARNING: '[UI] Hide localstorage warning'
};

export function flagRequestSubscriptionError(): UnsafeAction {
  return {
    type: UiActionTypes.FLAG_REQUEST_SUBSCRIPTION_ERROR
  };
}

export function updatePushSubscription(
  subscription: PushSubscription
): UnsafeAction {
  return {
    type: UiActionTypes.UPDATE_PUSH_SUBSCRIPTION,
    payload: subscription
  };
}

export const NullAction: UnsafeAction = {
  type: UiActionTypes.NULL_ACTION
};

export function showSpinner(identifier? : string): UnsafeAction {
  return {
    type: UiActionTypes.SHOW_SPINNER,
    payload: identifier
  };
}

export function hideSpinner(identifier? : string): UnsafeAction {
  return {
    type: UiActionTypes.HIDE_SPINNER,
    payload: identifier
  };
}

export function showTopBar(): UnsafeAction {
  return { type: UiActionTypes.SHOW_TOPBAR };
}

export function hideTopBar(): UnsafeAction {
  return { type: UiActionTypes.HIDE_TOPBAR };
}



export function displayErrorMessage(
  summary: string,
  detail?: string,
  life?: number
): UnsafeAction {
  const message: Message = {
    severity: 'error',
    summary,
    detail,
    life,
    closable: false
  };
  return {
    type: UiActionTypes.DISPLAY_MESSAGE,
    payload: message
  };
}

export function displayInfoMessage(
  summary: string,
  detail?: string,
  life?: number
): UnsafeAction {
  const message: Message = {
    severity: 'info',
    summary,
    detail,
    life,
    closable: false
  };
  return {
    type: UiActionTypes.DISPLAY_MESSAGE,
    payload: message
  };
}

export function displaySuccessMessage(
  summary: string,
  detail?: string,
  life?: number
): UnsafeAction {
  const message: Message = {
    severity: 'success',
    summary,
    detail,
    life,
    closable: false
  };
  return {
    type: UiActionTypes.DISPLAY_MESSAGE,
    payload: message
  };
}

export function displayWarningMessage(
  summary: string,
  detail?: string,
  life?: number
): UnsafeAction {
  const message: Message = {
    severity: 'warn',
    summary,
    detail,
    life,
    closable: false
  };
  return {
    type: UiActionTypes.DISPLAY_MESSAGE,
    payload: message
  };
}

export function displayLocalStorageWarning(){
  return {type : UiActionTypes.SHOW_LOCALSTORAGE_WARNING};
}

export function hideLocalStorageWarning(){
  return {type : UiActionTypes.HIDE_LOCALSTORAGE_WARNING};
}
