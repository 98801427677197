<div [ngClass]="{ 'hide-topbar': !showTopBar }">
  <p-progressSpinner class="centered" *ngIf="showSpinner"></p-progressSpinner>
  <p-blockUI [blocked]="true" [hidden]="!showSpinner"></p-blockUI>
  <p-confirmDialog
    [breakpoints]="confirmationDialogBreakpoints"
    [style]="{ width: '40vw' }"
    #cd
  >
    <p-footer>
      <button
        type="button"
        pButton
        icon="fas fa-times"
        label="{{ 'text.no' | translate }}"
        (click)="cd.reject()"
      ></button>
      <button
        type="button"
        pButton
        icon="fas fa-check"
        label="{{ 'text.yes' | translate }}"
        (click)="cd.accept()"
      ></button>
    </p-footer>


  </p-confirmDialog>

  <p-dialog
    [visible]="showEmergencyMaintenace"
    [modal]="true"
    [draggable]="false"
    [blockScroll]="true"
    [closeOnEscape]="false"
    [closable]="false"
    closeIcon=""
  >
    <p-header>
      {{ 'maintenance.notice' | translate }}
    </p-header>
    <div class="col-12">
      {{ 'maintenance.emergency-message' | translate }}
    </div>
    <p-footer>
      <h3>
        <a href="mailto:support@solidmemory.com">support@solidmemory.com</a>
      </h3>
    </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="displayNotification" (onHide)="onNotificationHide()">
    <p-header>
      {{ 'notification.notification' | translate }} -
      {{ notificationToDisplay?.notification?.title }}
    </p-header>
    <div class="col-12">
      {{ notificationToDisplay?.notification?.body }}
    </div>
    <p-footer>
      <h3>
        <a href="mailto:{{ notificationToDisplay?.senderEmail }}">{{
          notificationToDisplay?.senderEmail
        }}</a>
      </h3>
    </p-footer>
  </p-dialog>

  <p-dialog
    [(visible)]="displayLocalStorageWarning"
    (onHide)="onLocalStorageWarningHide()"
  >
    <p-header>
      <div>
        {{ 'ui.local-storage-notification' | translate }}
      </div>
    </p-header>
    <div class="col-12">
      {{ 'ui.local-storage-notification-message' | translate }}
    </div>
    <div class="col-12">
      {{ 'ui.local-storage-notification-message-2' | translate }}
    </div>
    <div class="col-12"></div>
    <div class="col-12" (click)="onLocalStorageWarningHide()">
      <app-support-button></app-support-button>
    </div>
  </p-dialog>

  <div
    class="layout-wrapper p-input-filled"
    [ngClass]="{
      'layout-menu-static': isStatic(),
      'layout-menu-overlay': isOverlay(),
      'layout-menu-horizontal': isHorizontal(),
      'layout-menu-slim': isSlim(),
      'layout-menu-active': menuActive,
      'layout-menu-mobile-active': mobileMenuActive,
      'layout-rtl': app.isRTL
    }"
    [class]="
      'layout-menu-' + ui.colorScheme + ' layout-topbar-' + ui.topbarTheme
    "
  >
    <app-topbar *ngIf="showTopBar"></app-topbar>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
      <div class="layout-menu-container">
        <app-menu></app-menu>
      </div>
    </div>

    <div class="layout-main">
      <div class="layout-content">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
  </div>
</div>
