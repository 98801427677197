import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from '../../states/states-reducers';
import { isSysAdminSelector } from '../user/user-state-selectors';

@Injectable({ providedIn: 'root' })
export class SysAdminGuard implements CanActivate {
  isSysAdmin: boolean;

  constructor(private store: Store<AppState>) {}

  canActivate(_: ActivatedRouteSnapshot): boolean {
    this.store
      .select(isSysAdminSelector)
      .pipe(take(1))
      .subscribe((isSysAdmin) => (this.isSysAdmin = isSysAdmin));

    return this.isSysAdmin;
  }
}
