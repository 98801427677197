import * as iassign from 'immutable-assign';
import { find } from 'lodash-es';
import { Language } from '../../model/language';
import { SyllabusAdminRegistration } from '../../model/syllabus-admin-registration';
import { UnsafeAction } from '../../model/unsafe-action';
import { Syllabus } from '../../problem-set/model/syllabus';
import { SyllabusActionTypes } from './syllabus-actions';

export interface SyllabusState {
  syllabi: Syllabus[];
  detailedSyllabus: Syllabus;
  syllabusAdminRegistrations: SyllabusAdminRegistration[];
  selectedSyllabusLanguage: Language;
}

export const INITIAL_SYLLABUS_STATE: SyllabusState = {
  syllabi: [],
  detailedSyllabus: undefined,
  syllabusAdminRegistrations: [],
  selectedSyllabusLanguage: Language.Unspecified
};

export function syllabusStateReducer(
  state: SyllabusState = INITIAL_SYLLABUS_STATE,
  { type, payload }: UnsafeAction
): SyllabusState {
  switch (type) {
    case SyllabusActionTypes.CHANGE_SELECTED_SYLLABUS_LANGUAGE:
      return iassign(state, (s) => {
        s.selectedSyllabusLanguage = payload;
        return s;
      });
    case SyllabusActionTypes.CHANGE_SELECTED_SYLLABUS_LANGUAGE_BY_SYLLABUS_ID:
      return iassign(state, (s) => {
        const syllabus = find(s.syllabi, (syl) => syl.id === payload);
        if (syllabus) {
          s.selectedSyllabusLanguage = syllabus.language;
        } else {
          s.selectedSyllabusLanguage = Language.Unspecified;
        }
        return s;
      });
    case SyllabusActionTypes.GET_SYLLABI_WITH_SYLLABUS_ADMIN_RIGHTS:
      return iassign(state, (s) => {
        s.syllabi = [];
        return s;
      });
    case SyllabusActionTypes.GET_SYLLABI_COMPLETE:
      return iassign(state, (s) => {
        const syllabi: Syllabus[] = payload;
        const newSyllabi: Syllabus[] = [];

        syllabi.forEach((p) => {
          const existing = find(s.syllabi, (o) => p.id === o.id);
          if (existing) {
            newSyllabi.push(existing);
          } else {
            newSyllabi.push(p);
          }
        });
        s.syllabi = newSyllabi;
        return s;
      });
    case SyllabusActionTypes.UPDATE_DETAILED_SYLLABUS_IN_STORE:
      return iassign(state, (s) => {
        s.detailedSyllabus = payload;
        return s;
      });
    case SyllabusActionTypes.GET_DETAILED_SYLLABUS:
      return iassign(state, (s) => {
        s.detailedSyllabus = undefined;
        return s;
      });
    case SyllabusActionTypes.GET_DETAILED_SYLLABUS_COMPLETE:
      return iassign(state, (s) => {
        s.detailedSyllabus = payload;
        return s;
      });
    case SyllabusActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS:
      return iassign(state, (s) => {
        s.syllabusAdminRegistrations = [];
        return s;
      });
    case SyllabusActionTypes.GET_SYLLABUS_ADMIN_REGISTRATIONS_COMPLETE:
      return iassign(state, (s) => {
        s.syllabusAdminRegistrations = payload;
        return s;
      });
    case SyllabusActionTypes.CREATE_SYLLABUS_ADMIN_REGISTRATION_COMPLETE:
      return iassign(state, (s) => {
        s.syllabusAdminRegistrations = [
          ...s.syllabusAdminRegistrations,
          payload
        ];
        return s;
      });
    default:
      return state;
  }
}
