import { Component } from '@angular/core';

@Component({
  selector: 'app-send-button',
  template: `
    <app-material-button
      icon="fas fa-share"
      text="{{ 'support.send' | translate }}"
    >
    </app-material-button>
  `
})
export class SendButtonComponent {}
