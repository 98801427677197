import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { some } from 'lodash-es';
import { User } from 'oidc-client';
import { Observable } from 'rxjs';
import { UserAuthenticationService } from './user-authentication.service';

const skipAuthUrls = [
  '/api/dictionary/dictEntries/',
  '/api/dictionary/phraseSuggestions',
  '/api/dictionary/alternativeCharacters',
  '/api/dictionary/characterStrokes',
  '/api/dictionary/phonetics',
  '/api/image/images/',
  '/api/audio/',
  '/api/image/media/',
  '/api/translation/'
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userAuthenticationService: UserAuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !this.userAuthenticationService.user ||
      this.userAuthenticationService.user.expired
    ) {
      return next.handle(req);
    }

    const isSkipAuthUrl = some(skipAuthUrls, (url) => req.url.indexOf(url) > -1);
    if (isSkipAuthUrl) {
      return next.handle(req);
    }

    const authHeader = this.getAuthHeaders(this.userAuthenticationService.user);
    const headers = req.headers.append('Authorization', authHeader);
    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }

  private getAuthHeaders(user: User): string {
    return user.token_type + ' ' + user.access_token;
  }
}
