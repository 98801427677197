import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../states/states-reducers';
import { TranslationService } from '../translation/translation.service';
import {
  displayErrorMessage,
  displayInfoMessage,
  displaySuccessMessage
} from './ui-actions';

@Injectable({ providedIn: 'root' })
export class MessageDisplayService {
  constructor(
    private store: Store<AppState>,
    private translation: TranslationService
  ) {}

  displayNetworkDisconnectedMessage() {
    this.store.dispatch(
      displayErrorMessage(this.translation.disconnectedMessage)
    );
  }

  displayDataSynchronizedMessage() {
    this.store.dispatch(
      displayInfoMessage(this.translation.dataSynchronizedMessage)
    );
  }

  displayNetworkConnectedMessage() {
    this.store.dispatch(displayInfoMessage(this.translation.connectedMessage));
  }

  displaySynonymMessage() {
    this.store.dispatch(
      displaySuccessMessage(this.translation.synonym, undefined, 1000)
    );
  }
}
