import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  tap,
  throttleTime,
  withLatestFrom
} from 'rxjs/operators';
import { DynamicDialogConfigBuilder } from 'src/app/dynamic-dialog-config-builder';
import { uiCultureSelector } from 'src/app/i18n/i18n-state-selectors';
import { UICulture } from 'src/app/model/ui-culture';
import { VocabEntryUpdateDTO } from 'src/app/model/vocab-entry-update-dto';
import { AppState } from 'src/app/states/states-reducers';
import { AddVocabEntryComplete } from 'src/app/vocab/vocab-shared/vocab-entry-actions';
import { Configuration } from '../../configuration';
import { UnsafeAction } from '../../model/unsafe-action';
import { VocabEntryUpdatedResult } from '../../model/vocab-entry-upserted-result';
import { isNilOrWhiteSpace } from '../../utility/string-utility';
import { TranslationService } from '../translation/translation.service';
import {
  displayErrorMessage,
  displayInfoMessage,
  displayWarningMessage
} from '../ui/ui-actions';
import {
  freeQuotaReachedEventRaised,
  VocabCoreActionTypes
} from './vocab-core-actions';
import { VocabularyService } from './vocab-core.service';
import { VocabUpgradePromptComponent } from './vocab-upgrade-prompt.component';

@Injectable()
export class VocabularyEffects {
  constructor(
    private actions$: Actions,
    private vocabularyService: VocabularyService,
    private config: Configuration,
    private translation: TranslationService,
    private dialogService: DialogService,
    private store: Store<AppState>
  ) {}

  @Effect() prioritizeVocabulary = this.actions$.pipe(
    ofType(VocabCoreActionTypes.PRIORITIZE_VOCABULARY),
    mergeMap((action: UnsafeAction) =>
      this.vocabularyService
        .prioritizeVocabEntry(action.payload.language, action.payload.phrase)
        .pipe(
          catchError((_) => of('error')),
          map((response) => {
            return [response, action.payload.phrase];
          })
        )
    ),
    map(([response, phrase]) => {
      if (!response) {
        return displayInfoMessage(
          phrase,
          this.translation.getTranslationInstant('vocabulary.priority-raised')
        );
      } else {
        return displayWarningMessage(this.translation.disconnectedMessage);
      }
    })
  );

  @Effect() addVocabulary = this.actions$.pipe(
    ofType(VocabCoreActionTypes.ADD_VOCABULARY),
    filter((action: UnsafeAction) => {
      return (
        !isNilOrWhiteSpace(action.payload.phrase) &&
        !isNil(action.payload.language)
      );
    }),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    withLatestFrom(this.store.select(uiCultureSelector)),
    mergeMap(([action, uiCulture]: [UnsafeAction, UICulture]) => {
      const updateDTO: VocabEntryUpdateDTO = {
        language: action.payload.language,
        phrase: action.payload.phrase,
        personalRemark: action.payload.personalRemark,
        uiCulture,
        createdUtc: new Date()
      };
      return this.upsertVocabEntry(updateDTO);
    }),
    map((result: VocabEntryUpdatedResult) => {
      if (result) {
        switch (result.status) {
          case 'inserted':
            const insertedMessage = this.translation.getTranslationInstant(
              'vocabulary.word-inserted'
            );
            this.store.dispatch(AddVocabEntryComplete(result.vocabEntry));
            return displayInfoMessage(result.phrase, insertedMessage);
          case 'freeQuotaReached':
            return freeQuotaReachedEventRaised();
          case 'updated':
            const alreadyExistMessage = this.translation.getTranslationInstant(
              'vocabulary.word-already-exists'
            );
            return displayWarningMessage(result.phrase, alreadyExistMessage);
          default:
            break;
        }
      }
      const errorMessage = this.translation.getTranslationInstant(
        'vocabulary.fail-to-insert-word'
      );
      return displayErrorMessage(errorMessage);
    })
  );

  @Effect({ dispatch: false }) freeQuotaReached = this.actions$.pipe(
    ofType(VocabCoreActionTypes.FREE_QUOTA_REACHED),
    tap(() => {
      this.showServiceUpgradePrompt();
    })
  );

  showServiceUpgradePrompt() {
    const builder = new DynamicDialogConfigBuilder();
    builder.overflow_x = 'hidden';
    this.dialogService.open(VocabUpgradePromptComponent, builder.build());
  }

  upsertVocabEntry(
    updateDTO: VocabEntryUpdateDTO
  ): Observable<VocabEntryUpdatedResult> {
    return this.vocabularyService
      .upsertVocabEntry(updateDTO)
      .pipe(catchError((_) => of(null)));
  }
}
