import * as iassign from 'immutable-assign';
import { UnsafeAction } from '../model/unsafe-action';
import { Membership } from './membership';
import { Subscription } from './subscription';
import { SubscriptionActionTypes } from './subscription-actions';

export interface SubscriptionState {
  personalSubscriptions: Subscription[];
  schoolSubscriptions: Subscription[];
  membership: Membership;
}

export const INITIAL_SUBSCRIPTION_STATE: SubscriptionState = {
  personalSubscriptions: [],
  schoolSubscriptions: [],
  membership: undefined
};

export function subscriptionStateReducer(
  state: SubscriptionState = INITIAL_SUBSCRIPTION_STATE,
  { type, payload }: UnsafeAction
): SubscriptionState {
  switch (type) {
    case SubscriptionActionTypes.GET_MEMBERSHIP_COMPLETE:
      return iassign(state, (s) => {
        s.membership = payload;
        return s;
      });
    case SubscriptionActionTypes.GET_PERSONAL_SUBSCRIPTIONS_COMPLETE:
      return iassign(state, (s) => {
        s.personalSubscriptions = payload;
        return s;
      });

    case SubscriptionActionTypes.GET_SCHOOL_SUBSCRIPTIONS:
      return iassign(state, (s) => {
        s.schoolSubscriptions = [];
        return s;
      });

    case SubscriptionActionTypes.GET_SCHOOL_SUBSCRIPTIONS_COMPLETE:
      return iassign(state, (s) => {
        s.schoolSubscriptions = payload;
        return s;
      });
    default:
      return state;
  }
}
