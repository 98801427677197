import { Mentee } from '../../model/mentee';
import { UnsafeAction } from '../../model/unsafe-action';
import * as iassign from 'immutable-assign';
import { MenteeActionTypes } from './mentee-actions';

export interface MenteeState {
  mentees: Mentee[];
}

export const INITIAL_MENTEE_STATE: MenteeState = {
  mentees: []
};

export function menteeStateReducer(
  state: MenteeState = INITIAL_MENTEE_STATE,
  { type, payload }: UnsafeAction
): MenteeState {
  switch (type) {
    case MenteeActionTypes.CLEAR_MENTEE_STATE:
      return iassign(state, (s) => {
        s.mentees = [];
        return s;
      });
    case MenteeActionTypes.GET_MENTEES_OF_CURRENT_USER_COMPLETE:
      return iassign(state, (s) => {
        s.mentees = payload;
        return s;
      });
    default:
      return state;
  }
}
