import * as iassign from 'immutable-assign';
import { some } from 'lodash-es';
import { UnsafeAction } from '../../model/unsafe-action';
import { ThumbnailBandActionTypes } from './thumbnail-band-actions';
import { WebImage } from './web-image';

export interface ThumbnailBandState {
  thumbnails: WebImage[];
}

export const INITIAL_THUMBNAILBAND_STATE: ThumbnailBandState = {
  thumbnails: []
};

export function thumbnailBandStateReducer(
  state: ThumbnailBandState = INITIAL_THUMBNAILBAND_STATE,
  { type, payload }: UnsafeAction
): ThumbnailBandState {
  switch (type) {
    case ThumbnailBandActionTypes.GET_THUMBNAILS:
      return INITIAL_THUMBNAILBAND_STATE;
    case ThumbnailBandActionTypes.GET_THUMBNAILS_FAIL:
      return INITIAL_THUMBNAILBAND_STATE;
    case ThumbnailBandActionTypes.GET_THUMBNAILS_COMPLETE:
      return iassign(state, (s) => {
        s.thumbnails = payload;
        return s;
      });
    case ThumbnailBandActionTypes.CLEAR_THUMBNAILS:
      return iassign(state, (s) => {
        s.thumbnails = [];
        return s;
      });
    case ThumbnailBandActionTypes.ENDORSE_IMAGES_COMPLETE:
      return iassign(state, (s) => {
        s.thumbnails = s.thumbnails.map((t) => {
          if (some(payload, (id) => id === t.id)) {
            return { ...t, blame: -100 };
          }
          return t;
        });
        return s;
      });
    case ThumbnailBandActionTypes.RESET_IMAGES_COMPLETE:
      return iassign(state, (s) => {
        s.thumbnails = s.thumbnails.map((t) => {
          if (some(payload, (id) => id === t.id)) {
            return { ...t, blame: 0 };
          } else {
            return { ...t };
          }
        });
        return s;
      });
    case ThumbnailBandActionTypes.BLAME_IMAGES_COMPLETE:
      return iassign(state, (s) => {
        s.thumbnails = s.thumbnails.map((t) => {
          if (some(payload, (id) => id === t.id)) {
            return { ...t, blame: 100 };
          }
          return t;
        });
        return s;
      });

    default:
      return state;
  }
}
