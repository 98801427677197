import { UnsafeAction } from '../model/unsafe-action';
import { type } from '../utility/type';
import { EdbSchool } from './edb-school';
import { ErpSchool } from './erp-school';
import { SchoolLevel } from './school-level';
import { SchoolLandSchool } from './schoolland-school';

export const ErpActionTypes = {
  GET_ERP_SCHOOLS: type('[ERP] Get Schools'),
  GET_ERP_SCHOOLS_COMPLETE: type('[ERP] Get Schools Complete'),
  GET_EDB_SCHOOL: type('[ERP] Get EDB School'),
  GET_EDB_SCHOOL_COMPLETE: type('[ERP] Get EDB School Complete'),
  GET_SCHOOL_LAND_SCHOOL: type('ERP] Get SchoolLandSchool'),
  GET_SCHOOL_LAND_SCHOOL_COMPLETE: type('ERP] Get SchoolLandSchool Complete'),
  SELECT_ERP_SCHOOL: type('[ERP] Select ERP School'),
  SELECT_PREVIOUS_ERP_SCHOOL: type('[ERP] Select previous ERP School'),
  SELECT_NEXT_ERP_SCHOOL: type('[ERP] Select next ERP School'),
  UPSERT_ERP_SCHOOL: type('[ERP] Upsert ErpSchool'),
  UPSERT_ERP_SCHOOL_COMPLETE: type('[ERP] Upsert ErpSchool Complete'),
  CHANGE_DISTRICT_SELECTED: type('[ERP] Change District Selected'),
  CHANGE_SCHOOL_LEVEL_SELECTED: type('[ERP] Change School Level Selected')
};

export function changeDistrictSelected(district: string): UnsafeAction {
  return {
    type: ErpActionTypes.CHANGE_DISTRICT_SELECTED,
    payload: district
  };
}

export function changeSchoolLevelSelected(
  schoolLevel: SchoolLevel
): UnsafeAction {
  return {
    type: ErpActionTypes.CHANGE_SCHOOL_LEVEL_SELECTED,
    payload: schoolLevel
  };
}

export function upsertErpSchool(erpSchool: ErpSchool): UnsafeAction {
  return {
    type: ErpActionTypes.UPSERT_ERP_SCHOOL,
    payload: { erpSchool }
  };
}

export function upsertErpSchoolComplete(erpSchool: ErpSchool): UnsafeAction {
  return {
    type: ErpActionTypes.UPSERT_ERP_SCHOOL_COMPLETE,
    payload: { erpSchool }
  };
}

export function selectPreviousErpSchool() {
  return {
    type: ErpActionTypes.SELECT_PREVIOUS_ERP_SCHOOL
  };
}

export function selectNextErpSchool() {
  return {
    type: ErpActionTypes.SELECT_NEXT_ERP_SCHOOL
  };
}

export function selectErpSchool(
  schoolLevel: SchoolLevel,
  schoolNumber: string,
  session: string,
  locationId: string
): UnsafeAction {
  return {
    type: ErpActionTypes.SELECT_ERP_SCHOOL,
    payload: { schoolLevel, schoolNumber, session, locationId }
  };
}

export function getSchoolLandSchool(schoolLandSchoolUrl: string): UnsafeAction {
  return {
    type: ErpActionTypes.GET_SCHOOL_LAND_SCHOOL,
    payload: { schoolLandSchoolUrl }
  };
}

export function getSchoolLandSchoolComplete(
  schoolLandSchool: SchoolLandSchool
): UnsafeAction {
  return {
    type: ErpActionTypes.GET_SCHOOL_LAND_SCHOOL_COMPLETE,
    payload: { schoolLandSchool }
  };
}

export function getErpSchools(
  schoolLevel: SchoolLevel,
  district: string
): UnsafeAction {
  return {
    type: ErpActionTypes.GET_ERP_SCHOOLS,
    payload: { schoolLevel, district }
  };
}

export function getErpSchoolsComplete(schools: ErpSchool[]): UnsafeAction {
  return {
    type: ErpActionTypes.GET_ERP_SCHOOLS_COMPLETE,
    payload: { schools }
  };
}

export function getEdbSchool(edbUrl: string): UnsafeAction {
  return {
    type: ErpActionTypes.GET_EDB_SCHOOL,
    payload: { edbUrl }
  };
}

export function getEdbSchoolComplete(edbSchool: EdbSchool): UnsafeAction {
  return {
    type: ErpActionTypes.GET_EDB_SCHOOL_COMPLETE,
    payload: { edbSchool }
  };
}
