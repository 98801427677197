import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  map, throttleTime
} from 'rxjs/operators';
import { Configuration } from '../configuration';
import {
  clearSyllabi,
  getSyllabiWithSyllabusIds
} from '../core/syllabus/syllabus-actions';
import { displayErrorMessage } from '../core/ui/ui-actions';
import { UnsafeAction } from '../model/unsafe-action';
import { AppState } from '../states/states-reducers';
import { MembershipService } from './membership.service';
import { Subscription } from './subscription';
import {
  getMembershipComplete,
  getPersonalSubscriptionsComplete,
  getSchoolSubscriptionsComplete,
  SubscriptionActionTypes
} from './subscription-actions';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class SubscriptionEffects {
  constructor(
    private store: Store<AppState>,
    private subscriptionService: SubscriptionService,
    private membershipService: MembershipService,
    private actions$: Actions,
    private config: Configuration
  ) {}

  @Effect()
  getMembership = this.actions$.pipe(
    ofType(SubscriptionActionTypes.GET_MEMBERSHIP),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    exhaustMap((_: UnsafeAction) => {
      this.store.dispatch(clearSyllabi());
      return this.membershipService
        .getMembership()
        .pipe(catchError((_) => of(null)));
    }),
    map((membership) => {
      if (membership) {
        return getMembershipComplete(membership);
      } else {
        return displayErrorMessage('Fail to get Membership');
      }
    })
  );

  @Effect()
  getPersonalSubscriptions = this.actions$.pipe(
    ofType(SubscriptionActionTypes.GET_PERSONAL_SUBSCRIPTIONS),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    exhaustMap((_: UnsafeAction) => {
      this.store.dispatch(clearSyllabi());
      return this.subscriptionService
        .getPersonalSubscriptions()
        .pipe(catchError((_) => of(null)));
    }),
    map((subscriptions) => {
      if (subscriptions) {
        return getPersonalSubscriptionsComplete(subscriptions);
      } else {
        return displayErrorMessage('Fail to get personal subscriptions');
      }
    })
  );

  @Effect()
  getPersonalSubscriptionsComplete = this.actions$.pipe(
    ofType(SubscriptionActionTypes.GET_PERSONAL_SUBSCRIPTIONS_COMPLETE),
    map((action: UnsafeAction) =>
      getSyllabiWithSyllabusIds(
        (action.payload as Subscription[]).map((s) => s.syllabusId)
      )
    )
  );

  @Effect()
  getSchoolSubscriptions = this.actions$.pipe(
    ofType(SubscriptionActionTypes.GET_SCHOOL_SUBSCRIPTIONS),
    debounceTime(this.config.effectDebounceTimeInMs),
    throttleTime(this.config.effectThrottleTimeInMs),
    exhaustMap((action: UnsafeAction) => {
      return this.subscriptionService
        .getSchoolSubscriptions(action.payload)
        .pipe(catchError((_) => of(null)));
    }),
    map((subscriptions) => {
      if (subscriptions) {
        return getSchoolSubscriptionsComplete(subscriptions);
      } else {
        return displayErrorMessage('Fail to get school subscriptions');
      }
    })
  );

  @Effect()
  getSchoolSubscriptionsComplete = this.actions$.pipe(
    ofType(SubscriptionActionTypes.GET_SCHOOL_SUBSCRIPTIONS_COMPLETE),
    map((action: UnsafeAction) =>
      getSyllabiWithSyllabusIds(
        (action.payload as Subscription[]).map((s) => s.syllabusId)
      )
    )
  );
}
