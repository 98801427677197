import { Component, EventEmitter, Input, Output } from '@angular/core';
import { sortBy, toPairs } from 'lodash-es';
import { field } from './field';

@Component({
  selector: 'app-fields-display',
  templateUrl: './fields-display.component.html'
})
export class FieldsDisplayComponent {
  @Input() object: any;
  @Output() fieldClick = new EventEmitter<field>();

  get fields(): field[] {
    if (this.object) {
      const pairs = toPairs(this.object);
      const values = pairs.map((p) => {
        const fieldname = p[0];
        const fieldvalue = p[1];
        return {
          fieldname,
          fieldvalue
        };
      });
      return sortBy(values, (f) => f.fieldname);
    }
    return [];
  }

  onFieldClick(field: field) {
    this.fieldClick.emit(field);
  }

  indexTracker(index: any, _: any) {
    return index;
  }
}
