import { Language } from '../../model/language';
import { UnsafeAction } from '../../model/unsafe-action';
import { VocabEntriesStatus } from '../../model/vocab-entries.status';
import { type } from '../../utility/type';

export const VocabCoreActionTypes = {
  ADD_VOCABULARY: type('[VocabCore] Add vocabulary'),
  PRIORITIZE_VOCABULARY: type('[VocabCore] Prioritize vocabulary'),
  FREE_QUOTA_REACHED: type('[VocabCore] Free Quota Reached Event Raised'),
  VOCABENTRIESSTATUS_RECEIVED: type('[VocabCore] VocabEntriesStatus Received')
};

export function addVocabulary(
  phrase: string,
  personalRemark: string,
  language: Language
): UnsafeAction {
  return {
    type: VocabCoreActionTypes.ADD_VOCABULARY,
    payload: { phrase, personalRemark, language }
  };
}

export function prioritizedVocabulary(
  phrase: string,
  language: Language
): UnsafeAction {
  return {
    type: VocabCoreActionTypes.PRIORITIZE_VOCABULARY,
    payload: { phrase, language }
  };
}

export function freeQuotaReachedEventRaised(): UnsafeAction {
  return { type: VocabCoreActionTypes.FREE_QUOTA_REACHED };
}

export function VocabEntriesStatusReceived(
  vocabEntriesStatus: VocabEntriesStatus
): UnsafeAction {
  return {
    type: VocabCoreActionTypes.VOCABENTRIESSTATUS_RECEIVED,
    payload: vocabEntriesStatus
  };
}
