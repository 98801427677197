import { Injectable } from '@angular/core';
import {
  SwPush,
  SwUpdate,
  UpdateAvailableEvent
} from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { interval } from 'rxjs';
import { SubSink } from 'subsink';
import { environment } from '../../environments/environment';
import { serviceWorkerSriptFile } from '../configuration';
import { DynamicDialogConfigBuilder } from '../dynamic-dialog-config-builder';
import { AddToHomeScreeniOSInstructionComponent } from '../home-ui/add-to-home-screen-iOS-instruction.component';
import { AppState } from '../states/states-reducers';
import { isIOS } from '../utility/window-utility';
import { updatePushSubscription } from './ui/ui-actions';

@Injectable({
  providedIn: 'root'
})
export class SoftwareUpdateService {
  private updateCheckInterval$ = interval(environment.updateCheckInterval);
  private subSink: SubSink = new SubSink();
  private beforeInstallPromptEvent: any;

  showUpdateButton: boolean;

  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private store: Store<AppState>,
    private dialogService: DialogService
  ) {}

  init() {
    this.addEventListenerToBeforeInstallPrompt();

    if (navigator.serviceWorker) {
      this.updateServiceWorkerRegistrations().then((_) => {
        if (this.swUpdate.isEnabled) {
          this.subscribeSwUpdateAvailableEvent();
          this.checkForUpdatePeriodically();
        }

        if (window.PushManager && this.swPush.isEnabled) {
          this.subscribeSwPushSubscriptionChange();
        }
      });
    }
  }

  get showAddToHomeScreenButton(): boolean {
    return this.isIOSBrowserWindow || this.beforeInstallPromptEvent;
  }

  addToHomeScreen(): void {
    if (this.isIOSBrowserWindow) {
      const builder = new DynamicDialogConfigBuilder();
      this.dialogService.open(
        AddToHomeScreeniOSInstructionComponent,
        builder.build()
      );
    } else {
      this.beforeInstallPromptEvent.prompt();
      this.beforeInstallPromptEvent.userChoice.then((choice: any) => {
        if (choice.outcome === 'accepted') {
          this.beforeInstallPromptEvent = undefined;
        }
      });
    }
  }

  onUpdateButtonClick() {
    this.swUpdate.activateUpdate().then(() => {
      window.location.reload();
    });
  }

  checkForUpdate() {
    this.swUpdate.checkForUpdate();
  }

  private subscribeSwUpdateAvailableEvent() {
    this.subSink.sink = this.swUpdate.available.subscribe(
      (_: UpdateAvailableEvent) => {
        this.showUpdateButton = true;
      }
    );
  }

  private get isIOSBrowserWindow(): boolean {
    if (isIOS()) {
      const isInStandaloneMode = navigator['standalone'];
      return !isInStandaloneMode;
    } else {
      return false;
    }
  }

  private checkForUpdatePeriodically() {
    this.subSink.sink = this.updateCheckInterval$.subscribe((_) => {
      this.checkForUpdate();
    });
  }

  private subscribeSwPushSubscriptionChange() {
    this.subSink.sink = this.swPush.subscription.subscribe(
      (sub: PushSubscription) => {
        this.store.dispatch(updatePushSubscription(sub));
      }
    );
  }

  private addEventListenerToBeforeInstallPrompt() {
    window.addEventListener(
      'beforeinstallprompt',
      (beforeInstallPromptEvent: any) => {
        beforeInstallPromptEvent.preventDefault();
        this.beforeInstallPromptEvent = beforeInstallPromptEvent;
      }
    );
  }

  private async updateServiceWorkerRegistrations(): Promise<void> {
    await this.removeObsoleteRegistrations();
    await this.registerRegistrationIfNotRegistered();
  }

  private async registerRegistrationIfNotRegistered(): Promise<void> {
    await navigator.serviceWorker.ready;
    const registration = await navigator.serviceWorker.getRegistration(
      serviceWorkerSriptFile
    );
    if (!registration) {
      await navigator.serviceWorker.register(serviceWorkerSriptFile);
    }
  }

  private async removeObsoleteRegistrations(): Promise<void> {
    await navigator.serviceWorker.ready;
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations && registrations.length > 0) {
      for (let index = 0; index < registrations.length; index++) {
        const registration: ServiceWorkerRegistration = registrations[index];
        if (registration) {
          const serviceWorkers: ServiceWorker[] = [
            registration.active,
            registration.waiting,
            registration.installing
          ];
          for (let index = 0; index < serviceWorkers.length; index++) {
            const serviceWorker = serviceWorkers[index];
            if (serviceWorker && serviceWorker.scriptURL) {
              if (serviceWorker.scriptURL.indexOf(serviceWorkerSriptFile) < 0) {
                await registration.unregister();
                break;
              }
            }
          }
        }
      }
    }
  }
}
