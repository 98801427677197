import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VocabState } from './vocab-state-reducer';

export const vocabularyStateSelector =
  createFeatureSelector<VocabState>('vocab');

export const vocabSyllabiSelector = createSelector(
  vocabularyStateSelector,
  (state) => state.vocabSyllabusState.vocabSyllabi
);

export const newWordCountSelector = createSelector(
  vocabularyStateSelector,
  state => {
    return state.vocabEntryState.vocabEntries.filter(e => !e.lastModifiedUtc).length;
  }
);
