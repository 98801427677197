import { UnsafeAction } from '../../model/unsafe-action';
import { VocabEntriesStatus } from '../../model/vocab-entries.status';
import * as iassign from 'immutable-assign';
import { VocabCoreActionTypes } from './vocab-core-actions';

export interface VocabCoreState {
  vocabEntriesStatuses: { [language: number]: VocabEntriesStatus };
}

export const INITIAL_VOCAB_CORE_STATE: VocabCoreState = {
  vocabEntriesStatuses: {}
};

export function vocabCoreStateReducer(
  state: VocabCoreState = INITIAL_VOCAB_CORE_STATE,
  { type, payload }: UnsafeAction
): VocabCoreState {
  switch (type) {
    case VocabCoreActionTypes.VOCABENTRIESSTATUS_RECEIVED:
      return iassign(state, (s) => {
        s.vocabEntriesStatuses = iassign(s.vocabEntriesStatuses, (status) => {
          status[payload.language] = payload;
          return status;
        });
        return s;
      });
    default:
      return state;
  }
}
