import { Pronunciation } from '../../model/pronunciation';
import { UnsafeAction } from '../../model/unsafe-action';
import { type } from '../../utility/type';

export const PronunciationActionTypes = {
  PRONOUNCE: type('[Pronunciation] Pronounce'),
  PRONOUNCE_PRONUNCIATION_ID: type('[Pronunciation] Pronounce Pronunciation Id')
};

export function pronounce(
  phrase: string,
  pronunciation: Pronunciation
): UnsafeAction {
  return {
    type: PronunciationActionTypes.PRONOUNCE,
    payload: { phrase, pronunciation }
  };
}

export function PronouncePronunciationId(
  pronunciationId: string
): UnsafeAction {
  return {
    type: PronunciationActionTypes.PRONOUNCE_PRONUNCIATION_ID,
    payload: { pronunciationId }
  };
}
